/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { bool, number, string, shape as shapeType } from 'prop-types';
import {
  arrayOf,
  customType,
  fragment,
  number as numberType,
  typename,
  params,
  shape,
  namedFragment,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  Button,
  Typography,
  Card,
  CardBody,
  CardMedia,
  CardTitle,
  CardActions,
  CardContent,
} from '@one-thd/sui-atomic-components';
import { SpotlightB2b } from './SpotlightB2b';
import { publish } from './utils/publisher';
import VideoPlayerWrap from './subcomponents/VideoPlayerWrap';
import { RichText } from './subcomponents/RichText';
import HrefLink from './subcomponents/HrefLink';
import styles from './spotlight.scss';
import { akamaiImageResize } from './utils/akamaiImageOptimizer';

const Spotlight = ({
  componentId,
  componentClass,
  componentPosition,
  showDescription,
  useB2bStyles,
  proAnalyticsData,
  lazyLoad,
  orientation,
  livePreviewData
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('spotlight.ready');
  }, []);

  const cx = classNames.bind(styles);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: Spotlight.displayName,
      component: Spotlight.displayName,
      position: componentPosition,
      type: 'content',
    },
  });

  let dataComponent = data?.component;
  if (livePreviewData) {
    dataComponent = livePreviewData;
  }

  let {
    cta, description, richTextContent, link, linkList, title, altText, proAnalyticsCampaign, proAnalyticsComponent,
    videoUrl, linkListCollection
  } = dataComponent || {};

  let myLinkList;
  let richText;

  if (!livePreviewData) {
    myLinkList = linkList;
    try {
      richText = JSON.parse(richTextContent) || {};
    } catch (error) {
      // error
    }
  } else {
    myLinkList = linkListCollection.items;
    richText = richTextContent.json;
  }
  const renderLinkList = myLinkList?.length > 0 && myLinkList[0].label;

  const {
    previewImage: dataComponentPreviewImage,
  } = dataComponent || {};

  let imageUrl;
  const damDownloadedContentImage = dataComponentPreviewImage?.damDownloadedContent;

  if (damDownloadedContentImage?.url) {
    imageUrl = damDownloadedContentImage?.url;
  } else {
    imageUrl = dataComponentPreviewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl;
  }

  imageUrl = akamaiImageResize(imageUrl);
  const hasMedia = !!(videoUrl || imageUrl);

  const handleContentClick = () => {
    if (proAnalyticsData.isB2B) {
      // Input Data for widget should be collected from component data
      publish({
        widgetId: componentId,
        widgetTemplates: proAnalyticsData.templates,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  };
  if (useB2bStyles) {
    return (
      <SpotlightB2b
        componentId={componentId}
        componentClass={componentClass}
        componentPosition={componentPosition}
        proAnalyticsData={proAnalyticsData}
      />
    );
  }

  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component="Spotlight"
      data-component-name="Spotlight"
      data-component-position={componentPosition}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="spotlight--grid sui-grid sui-h-full sui-w-full"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Card orientation={orientation} className={cx('spotlight-card__link--hover-no-underline spotlight-card')}>
        {hasMedia && (
          <CardContent disablePadding initialSize={orientation === 'horizontal' ? '2/4' : null}>
            {videoUrl ? (
              <div data-contentful-entry-id={componentId} data-contentful-field-id="videoUrl">
                <VideoPlayerWrap videoUrl={videoUrl} />
              </div>
            ) : (
              <CardMedia
                src={imageUrl}
                aspect="wide"
                alt={altText || title}
                title={altText || title}
                loading={lazyLoad ? 'lazy' : ''}
                data-contentful-entry-id={componentId}
                data-contentful-field-id="image"
              />
            )}
          </CardContent>
        )}
        <CardContent disablePadding grow initialSize={(orientation === 'horizontal' && hasMedia) ? '2/4' : null}>
          <CardTitle header={title} data-contentful-entry-id={componentId} data-contentful-field-id="title" />
          <CardBody>
            {richText ? (
              <div data-contentful-entry-id={componentId} data-contentful-field-id="richTextContent">
                <RichText content={richText} />
              </div>
            ) : showDescription && description && (
              <Typography
                height="normal"
                weight="regular"
                variant="body-base"
                color="primary"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="description"
              >
                {description}
              </Typography>
            )}
          </CardBody>
          <CardActions className="sui-flex-col">
            {renderLinkList && (
              <div
                className="sui-flex sui-gap-2 sui-flex-col sui-mb-6 sui-mt-4 sui-w-full"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="linkList"
              >
                {myLinkList?.map((linkItem, key) => {
                  return <HrefLink linkItem={linkItem} key={key} />;
                }
                )}
              </div>
            )}
            {link && cta && (
              <Button
                data-contentful-entry-id={componentId}
                data-contentful-field-id="cta"
                href={link}
                variant="secondary"
                onClick={handleContentClick}
                fullWidth={orientation === 'vertical' || !hasMedia}
              >
                {cta}
              </Button>
            )}
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

Spotlight.displayName = 'Spotlight';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

Spotlight.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  showDescription: bool,
  useB2bStyles: bool,
  proAnalyticsData: shapeType({
    isB2B: bool,
    templates: string,
  }),
  lazyLoad: bool,
  orientation: string,
  livePreviewData: shapeType({
    id: string,
    title: string,
    altText: string,
    cta: string,
    link: string,
    componentName: string,
    description: string,
    richTextContent: shapeType({
      json: shapeType({
        value: string
      }),
    }),
    proAnalyticsCampaign: string,
    proAnalyticsComponent: string,
    linkList: arrayOf(shapeType({
      label: string,
      href: string,
    })),
    videoUrl: string,
    linkListCollection: arrayOf(shapeType({
      href: string,
      internalName: string,
      label: string,
    })),
    previewImage: DamMediaFragment,
  }),
};

Spotlight.defaultProps = {
  componentPosition: 1,
  componentClass: '',
  showDescription: false,
  useB2bStyles: false,
  proAnalyticsData: {},
  lazyLoad: false,
  orientation: 'vertical',
  livePreviewData: null,
};

Spotlight.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Spotlight']).isRequired(),
  }).shape({
    Spotlight: namedFragment({ inline: true, fragmentType: 'Spotlight' }).shape({
      id: stringType(),
      title: stringType(),
      altText: stringType(),
      cta: stringType(),
      link: stringType(),
      componentName: stringType(),
      description: stringType(),
      richTextContent: stringType(),
      proAnalyticsCampaign: stringType(),
      proAnalyticsComponent: stringType(),
      linkList: arrayOf(shape({
        label: stringType(),
        href: stringType(),
      })),
      videoUrl: stringType(),
      previewImage: DamMediaFragment,
    }),
  }),
};

export { Spotlight };
