import React, { useContext } from 'react';
import { string, bool, func, number } from 'prop-types';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { FulfillmentContext } from '../../FulfillmentContext';

const OffshoreSthUnavailable = ({
  stateName,
  setShowCheckAvailability
}) => {

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(true);
  };

  return (
    <>
      <div className="u__center">
        <TileHead
          title="Ship to Home"
          icon="pick-up-false"
        />
        <div className="fulfillment-msg warning_text">Not available in {stateName}</div>
      </div>
      <TileFooter>
        <button type="button" className="u__default-link" onClick={handleOnClick}>
          Change Zip
        </button>
      </TileFooter>
    </>
  );
};
OffshoreSthUnavailable.propTypes = {
  stateName: string,
  setShowCheckAvailability: func
};
OffshoreSthUnavailable.defaultProps = {
  stateName: 'your state',
  setShowCheckAvailability: () => {}
};

export { OffshoreSthUnavailable };
