import React from 'react';
import PropTypes from 'prop-types';
import './ExpressDelivery.scss';

const CAR_DLVRY_CHARGE = '8.99';
const VAN_DLVRY_CHARGE = '35.00';
const TRUCK_DLVRY_CHARGE = '79.00';
const ExpressDeliveryHowItWorks = () => (
  <div className="buybox_delivery_tooltip">
    <h3 className="u--paddingBottom">How It Works</h3>
    <p className="u--paddingBottom">
      <span>{'We\'ll bring products to your home or jobsite at a scheduled time. '}</span>
      <span>
        This is a flat rate service, meaning all the qualifying items in your order can be delivered for one fee.
      </span>
    </p>
    <p className="u--paddingBottom">Price and delivery time can be impacted by order size.</p>
    <div className="delivery_tooltip_wrapper">
      <p>
        <span className="delivery_tooltip_car">Car</span>
        <span className="delivery_tooltip_item_name">Car</span>
        <span className="u__bold">{CAR_DLVRY_CHARGE}</span>
      </p>
      <p>
        <span className="delivery_tooltip_van">Van</span>
        <span className="delivery_tooltip_item_name">Van</span>
        <span className="u__bold">{VAN_DLVRY_CHARGE}</span>
      </p>
      <p>
        <span className="delivery_tooltip_truck">Box Truck / Flatbed</span>
        <span className="delivery_tooltip_item_name">Box Truck / Flatbed</span>
        <span className="u__bold">{TRUCK_DLVRY_CHARGE}</span>
      </p>
    </div>
    <p className="delivery_tooltip_legal"> * Additional fees may apply for same-day scheduled delivery.</p>
    <p className="delivery_tooltip_legal"> ** Vehicle availability and pricing may vary based on location.</p>
  </div>
);

ExpressDeliveryHowItWorks.propTypes = {};

export { ExpressDeliveryHowItWorks };
