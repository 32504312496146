import React from 'react';
import PropTypes from 'prop-types';

const ShippingThreshold = ({ fulfillment }) => (
  <div>
    {fulfillment.hasFreeShipping && (
      <span className="u__text--success">FREE</span>
    )}
    {!fulfillment.hasFreeShipping
      && fulfillment.freeDeliveryThreshold && (
      <div>
        <span className="u__text--success u__bold">
          {`Free with $${parseInt(fulfillment.freeDeliveryThreshold, 10)} order`}
        </span>
      </div>
    )}
    {!fulfillment.hasFreeShipping
      && !fulfillment.freeDeliveryThreshold && (
      <span className="shipping__normal--threshold">Standard Delivery</span>
    )}
  </div>
);

ShippingThreshold.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  fulfillment: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export { ShippingThreshold };
