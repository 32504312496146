import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  getNearbyStoreWithLimitedStock, formatDistance, productShape
} from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { LimitedStockIcon } from '../../helpers/icon-utils';
import { isMobile } from '../../../components/helper/utils';

export const LimitedAvailabilityNearby = ({
  product,
  onCheckNearbyStoresClick
}) => {
  const { channel } = useContext(ExperienceContext);
  const nearbyStore = getNearbyStoreWithLimitedStock(product);

  if (!(product && nearbyStore)) {
    return <div className="store__message" />;
  }

  const { storeName, distance } = nearbyStore;

  if (!(storeName && distance)) {
    return <div className="store__message" />;
  }

  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
        nearbyStoreName={storeName}
      />
    );

  return (
    <div className="store__message">
      <div className="store__icon">{LimitedStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Buy in Store</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__warning">Limited stock</span>
          <span className="store__primary">{' at '}</span>
          {storeNameLink}
          <span className="store__primary">{` | ${formatDistance(distance)} mi away`}</span>
        </div>
      </div>
    </div>
  );
};

LimitedAvailabilityNearby.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
};

LimitedAvailabilityNearby.defaultProps = {
  onCheckNearbyStoresClick: () => {},
};
