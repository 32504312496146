import { useState, useEffect, useCallback } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { getApplianceFulfillmentModels } from '../components/helper/FulfillmentHelper';
import { FULFILLMENT_METHODS } from '../components/constants';

export const useMajorApplianceFulfillment = ({
  itemId,
  configurableApplianceItemIds,
  quantity,
  zipCode,
  storeId,
  storeName,
  channel,
  onChange,
  covidAlert,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bopisHolidayTiming
}) => {
  // there is some default state to consider for initial rendering
  const [selectedFulfillment, setFulfillmentSelection] = useState(FULFILLMENT_METHODS.APPLIANCE);
  const [currentZipCode, setZipCode] = useState(zipCode || '');
  const [checkAvailabilityModel, setCheckAvailabilityModel] = useState(null);
  const productOptions = {
    variables: {
      itemId,
      storeId
    },
    ssr: false
  };

  const price = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const someSkusHaveZeroPrice = price?.configuredPrice?.pricing?.someSkusHaveZeroPrice;

  if (currentZipCode) {
    productOptions.variables.zipCode = currentZipCode;
  }

  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptions);

  const {
    fulfillment = {},
    availabilityType = {},
    info = {},
    pricing = {},
    identifiers = {}
  } = data?.product || {};
  const type = availabilityType?.type;
  const { productType } = identifiers;
  const isMinimumFreeShipPrice = pricing?.value > 396;
  const onApplianceCheckAvailability = (output) => {
    const { processedData, zipCode: applianceZip, rawResponse, refetch } = output;
    setZipCode(applianceZip || '');
    setCheckAvailabilityModel(processedData);
  };

  const fulfillmentModels = getApplianceFulfillmentModels({
    fulfillment,
    type,
    productType,
    quantity,
    covidAlert,
    zipCode: currentZipCode,
    itemId,
    configurableApplianceItemIds,
    storeName,
    channel,
    pricing,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    checkAvailabilityModel,
    isMinimumFreeShipPrice,
    onApplianceCheckAvailability,
    bopisHolidayTiming
  });

  const currentFulfillment = fulfillmentModels.find((option) => option.method === selectedFulfillment);

  const response = {
    fulfillment,
    info,
    availabilityType,
    fulfillmentModels,
    currentFulfillment,
    someSkusHaveZeroPrice,
    loading,
    error
  };

  const changeFulfillment = useCallback((fulfillmentModel) => {
    setFulfillmentSelection(fulfillmentModel.method);
  }, []);

  useEffect(() => {
    const [directDeliveryTemplate = {}, storeTemplate = {}] = fulfillmentModels;
    if (directDeliveryTemplate?.enabled) {
      setFulfillmentSelection(directDeliveryTemplate.method);
    } else if (storeTemplate?.enabled) {
      setFulfillmentSelection(storeTemplate.method);
    }
  }, [checkAvailabilityModel]);

  useEffect(() => {
    if (onChange && data) {
      const [directDeliveryTemplate = {}, storeTemplate = {}] = fulfillmentModels;
      const method = selectedFulfillment;
      const isShowATC = !availabilityType?.discontinued && (directDeliveryTemplate.enabled || storeTemplate.enabled)
        && !someSkusHaveZeroPrice;
      const isOutOfStockOnline = !!directDeliveryTemplate.oos;
      const isUnavailable = !!directDeliveryTemplate.unavailable;
      const disableATC = (isOutOfStockOnline || isUnavailable) && method !== FULFILLMENT_METHODS.BOPIS;
      onChange({
        method,
        isShowATC,
        zipCode,
        disableATC
      });
    }
  }, [data, itemId, zipCode, checkAvailabilityModel, selectedFulfillment, someSkusHaveZeroPrice]);

  return [response, changeFulfillment];
};
