import React, { useEffect, useContext } from 'react';
import {
  node,
  bool,
  string,
} from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';

const CheckNearByStore = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { itemId, children, isShipToHomeEligible } = props;
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? 'https://www.homedepot.com'
      : window.location.origin;
  }
  const { storeId } = useStore();
  const cartReqParams = {
    itemId,
    host,
    keyword: storeId,
    channel,
    isShipToHomeEligible,
    alterBrowserHistory: true
  };

  return (
    <span>
      <button
        type="button"
        className="u__default-link check__nearstore--wrapper"
        onClick={(event) => {
          window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
            cartReqParams
          });
        }}
      >
        <span>{children}</span>
      </button>
    </span>
  );
};

CheckNearByStore.propTypes = {
  itemId: string.isRequired,
  children: node.isRequired,
  isShipToHomeEligible: bool,
};

CheckNearByStore.defaultProps = {
  isShipToHomeEligible: false
};

export { CheckNearByStore };
