import React, { useContext } from 'react';
import { shape } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import {
  isShowBuyInStoreNearBy,
  getSellableQuantityYourStore,
  productShape,
} from '../../helpers/pod-fulfillment-utils';
import { BopisOffIcon } from '../../helpers/icon-utils';
import { isMobile } from '../../../components/helper/utils';

export const BopisOffMessage = ({ product }) => {
  const { channel } = useContext(ExperienceContext);
  const { storeName } = useStore();

  if (!(product && storeName)) {
    return <div className="store__message" />;
  }

  const sellableQuantity = getSellableQuantityYourStore(product);
  const formattedQty = sellableQuantity > 999 ? '999+' : sellableQuantity;

  /** *  BopisOnOffMessage Subcomponents  ** */
  const storeNameLink = (isMobile(channel) || !isShowBuyInStoreNearBy(product))
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : <CheckNearByStore product={product} />;

  const secondLineMessage = sellableQuantity > 0
    ? (
      <>
        <span className="store__success">{`${formattedQty} in stock`}</span>
        <span className="store__primary">{' at '}</span>
        {storeNameLink}
      </>
    )
    : (
      <>
        <span className="store__primary">{'Visit '}</span>
        {storeNameLink}
        <span className="store__primary">{' to check availability'}</span>
      </>
    );

  return (
    <div className="store__message">
      <div className="store__icon">{BopisOffIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Buy in Store</div>
        <div id="check-nearby-store" className="store__second-line">
          {secondLineMessage}
        </div>
      </div>
    </div>
  );
};

BopisOffMessage.propTypes = {
  product: shape({ productShape }).isRequired
};
