import React from 'react';
import { string, bool, shape } from 'prop-types';

const ApplianceError = ({ directDelivery }) => {
  return (
    <div className="fulfillment__unavailable">
      <fieldset className="fulfillment__unavailable--fieldset">
        <legend className="fulfillment__unavailable--title">How To Get it</legend>
        <div className="fulfillment__unavailable__wrapper">
          {directDelivery.subTemplate}
        </div>
      </fieldset>
    </div>
  );
};

ApplianceError.propTypes = {
  directDelivery: shape({
    method: string,
    enabled: bool,
  })
};
ApplianceError.defaultProps = {
  directDelivery: null
};

export { ApplianceError };
