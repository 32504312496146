import React from 'react';
import { Alert } from '@thd-olt-component-react/core-ui';

const Discontinued = () => {
  return (
    <div className="fulfillment__unavailable">
      <fieldset className="fulfillment__unavailable--fieldset">
        <legend className="fulfillment__unavailable--title">How To Get it</legend>
        <div className="discontinued__wrapper">
          <Alert
            title="Discontinued!"
            type="danger"
            message="We are no longer carrying this particular product."
            inline
          />
        </div>
      </fieldset>
    </div>
  );
};

Discontinued.propTypes = {};

export { Discontinued };
