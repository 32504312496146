import React from 'react';
import { string, bool } from 'prop-types';
import { InStockIcon, UnavailableIcon } from '../../helpers/icon-utils';

const LiveGoodsPod = ({ storeName, seasonStatusEligible }) => {

  const iconSelector = seasonStatusEligible ? InStockIcon : UnavailableIcon;
  const pickupType = seasonStatusEligible ? 'Buy in Store' : 'Pickup';
  const seasonMessage = seasonStatusEligible ? 'In Season' : 'Out of Season';

  return (
    <div className="store__message">
      <div className="store__icon">{iconSelector}</div>
      <div className="store__text-box">
        <div>{pickupType}</div>
        <div className="store__second-line">
          <span className="store__primary">{seasonMessage}</span>
          {storeName && (
            <div className="u__inline">
              <span className="store__primary"> at </span>
              <span className="u__bold">{storeName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LiveGoodsPod.propTypes = {
  storeName: string,
  seasonStatusEligible: bool,
};
LiveGoodsPod.defaultProps = {
  storeName: null,
  seasonStatusEligible: false
};

export { LiveGoodsPod };
