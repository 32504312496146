/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './BackToTop.style.scss';

class BackToTop extends Component {

  state = {
    backToTop: false
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.onScrollEvent);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScrollEvent);
  };

  onScrollEvent = () => {
    const { scrollBreakpoint } = this.props;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const backToTop = scroll > scrollBreakpoint;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.backToTop !== backToTop) {
      this.setState({ backToTop });
    }
  };

  scrollToTop = () => {
    const { smoothnessValue } = this.props;
    if (typeof window !== 'undefined') {
      const sTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (sTop > 0) {
        // dont think this is necessary to suppor to old safari for smooth scroll anymore
        // window.requestAnimationFrame(this.scrollToTop);
        // window.scrollTo(0, sTop - (Math.ceil(sTop / smoothnessValue)));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  render() {
    const { backToTop } = this.state;
    const classNameBackTop = classNames(
      'back-to-top',
      { 'back-to-top--hide': !backToTop },
      { 'back-to-top--visible': backToTop }
    );

    return (
      // eslint-disable-next-line
      <button type="button" onClick={this.scrollToTop} data-component="BackToTop">
        <i className={classNameBackTop} />
      </button>
    );
  }
}

BackToTop.defaultProps = {
  scrollBreakpoint: 500,
  smoothnessValue: 5
};

BackToTop.propTypes = {
  scrollBreakpoint: PropTypes.number,
  smoothnessValue: PropTypes.number
};

BackToTop.dataModel = {};

export default BackToTop;
