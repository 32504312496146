import React, { useState, useEffect, createRef, useContext } from 'react';
import { Row, Col } from '@thd-olt-component-react/grid';
import { Button } from '@thd-olt-component-react/button';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getDomPath } from '@thd-olt-functional/utils';
import { string } from 'prop-types';
import './track-out-of-stock.style.scss';

const testEmail = (text) => {
  const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
  return emailRE.test(String(text).toLowerCase());
};

const MESSAGE_STATES = {
  email_missing: {
    type: 'error',
    alertClass: 'danger',
    message: 'Please provide your email address, formatted as you@domain.com.',
    form: { enabled: true }
  },
  failure: {
    type: 'error',
    alertClass: 'danger',
    title: 'An error has occurred.',
    message: 'Please resubmit your email address.',
    form: { enabled: true }
  },
  duplicate: {
    type: 'success',
    alertClass: 'success',
    title: 'Thank you!',
    message: 'You are already signed up to receive a notification if this product is back in stock.',
    form: { enabled: false }
  },
  success: {
    type: 'success',
    alertClass: 'success',
    title: 'Thank you!',
    message: 'We\'ll send you an email if this product is back in stock.',
    form: { enabled: false }
  },
  init: {
    type: '',
    alertClass: '',
    title: '',
    message: '',
    form: { enabled: true }
  }
};

const INHOUSE_STATUS_MAP = {
  Success: 'success',
  OPS_ERROR_200: 'email_missing',
  OPS_ERROR_201: 'duplicate',
  OPS_ERROR_203: 'failure',
  OPS_ERROR_204: 'failure',
  OPS_ERROR_205: 'failure',
  OPS_DECRYPT_ERROR: 'failure',
};

const TrackOutOfStock = ({ itemId }) => {
  const ref = createRef();
  const [state, setState] = useState({
    email: (typeof window !== 'undefined' && window?.THDCustomer?.default?.email) || '',
    status: 'init',
    loader: false
  });
  const { hosts, path } = useContext(ExperienceContext);
  const { status, email } = state;

  const trackError = (domPath) => {
    const message = MESSAGE_STATES[status]?.message;
    window.LIFE_CYCLE_EVENT_BUS.trigger('track-out-of-stock.click', { message, domPath });
  };

  useEffect(() => {
    if (['init', 'success', 'duplicate'].includes(status)) return;
    const _path = getDomPath(ref);
    trackError(_path);
  }, [status]);

  const updateEmailId = (event) => {
    const { value } = event.target;
    setState({ ...state, email: value });
  };

  const SubmitForm = async (event) => {
    event.preventDefault();

    setState({ ...state, loader: true });
    if (!testEmail(email)) {
      setState({
        ...state,
        loader: false,
        status: 'email_missing'
      });
    } else {
      let notifyUrl = '';
      let notifyOptions = {};
      if (itemId) {
        notifyUrl = '/discovery/opt/in';
        const payload = {
          emailId: email,
          omsId: itemId
        };
        notifyOptions = {
          method: 'POST',
          crossdomain: false,
          headers: {
            'Content-Type': 'application/json',
          },
          dataType: 'json',
          body: JSON.stringify(payload)
        };
      } else if (path) {
        const { trackif = '' } = hosts || {};
        notifyUrl = `${trackif}/tools/auto`;
        const { origin, pathname } = (typeof window !== 'undefined' && window?.location) || {};
        const itemURL = `${origin}${pathname}`;
        let formData = new FormData();
        formData.append('email', email);
        formData.append('url', typeof window !== 'undefined' ? itemURL : path);
        formData.append('preset', 'out-of-stock');
        notifyOptions = {
          method: 'POST',
          crossdomain: true,
          dataType: 'json',
          body: formData
        };
      }
      if (notifyUrl && notifyOptions) {
        try {
          const { message, status: responseStatus } = await fetch(notifyUrl, notifyOptions)
            .then((resp) => resp.json()) || {};

          const newStatus = itemId ? INHOUSE_STATUS_MAP[message] : responseStatus;
          setState({
            ...state,
            loader: false,
            status: newStatus || 'failure'
          });
        } catch (error) {
          setState({
            ...state,
            loader: false,
            status: 'failure'
          });
        }
      }
    }
  };

  return (
    <Row className="track-out-of-stock">
      <Col lg="12">
        <p>Receive an email when this item is back in stock.</p>
      </Col>
      <Col lg="12">
        {status && (
          <div>
            <form
              className="u__clearfix track-out-of-stock__form"
              onSubmit={SubmitForm}
            >
              <input
                type="email"
                placeholder="Email"
                className="form-input__field track-out-of-stock__email"
                onChange={updateEmailId}
                value={email}
                data-qm-mask=""
              />
              <Button
                ref={ref}
                className="track-out-of-stock__notifyme"
              >
                <span>Notify Me</span>
              </Button>
            </form>
          </div>
        )}
        {status
        && (
          <div className="track-out-of-stock__status">
            <div className={`alert-inline alert-inline--${MESSAGE_STATES[status].alertClass}`}>
              <span className="alert-inline__message">
                <span className="alert-inline__title">{MESSAGE_STATES[status].title} </span>
                {MESSAGE_STATES[status].message}
              </span>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

TrackOutOfStock.displayName = 'TrackOutOfStock';

TrackOutOfStock.dataModel = {};

TrackOutOfStock.propTypes = {
  itemId: string
};

TrackOutOfStock.defaultProps = {
  itemId: null
};

export { TrackOutOfStock, testEmail };
