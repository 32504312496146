import React, { Fragment } from 'react';
import {
  string,
  shape,
  bool,
  number
} from 'prop-types';
import { useDomPath } from '@thd-olt-functional/utils';
import { CheckNearByStore } from './CheckNearByStore';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { track } from '../../../analytics';
import { CHECK_NEARBY_STORES } from '../../constants';

const LimitedStockByStore = ({
  itemId,
  location,
  isShipToHomeEligible,
  isAppliance
}) => {

  const [path, ref] = useDomPath();
  const message = `Limited stock at ${location?.storeName}`;

  if (path?.length > 0) {
    track('advisory', {
      path,
      message
    });
  }

  return (
    <>
      <div className="u__center">
        <TileHead
          title="Buy in Store"
          icon="pick-up-false"
        />
        {location?.inventory?.isLimitedQuantity && (
          <div className="warning_text" ref={ref}>
            <span>Limited stock at </span>
            <div className="fulfillment__store-name">{location?.storeName}</div>
          </div>
        )}
      </div>
      {!isAppliance && (
        <TileFooter>
          <CheckNearByStore
            itemId={itemId}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            { CHECK_NEARBY_STORES }
          </CheckNearByStore>
        </TileFooter>
      )}
    </>
  );
};

LimitedStockByStore.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  location: shape({
    storeName: string,
    distance: number
  }).isRequired,
};

LimitedStockByStore.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false
};

export { LimitedStockByStore };
