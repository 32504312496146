import React, { Fragment } from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { CheckNearByStore } from './CheckNearByStore';
import { InventoryWarning } from './InventoryWarning';
import { FULFILLMENT_METHODS } from '../../constants';
import { formatDate, formatInventory } from '../../helper/utils';

const DefaultBoSSDetails = ({
  fulfillment,
  itemId,
  backordered,
  isShipToHomeEligible,
  bopisLocation,
  bossLocation,
  bosssDominant,
  quantity
}) => {
  const formattedStartDate = formatDate(fulfillment?.deliveryDates?.startDate);
  const formattedEndDate = formatDate(fulfillment?.deliveryDates?.endDate);
  const bossInventory = bossLocation?.inventory?.quantity || 0;
  const bopisInventory = bopisLocation?.inventory?.quantity || 0;
  const bossInventoryQuantity = formatInventory(bossInventory);
  const bopisInventoryQuantity = formatInventory(bopisInventory);

  return (
    <>
      {backordered && !formattedStartDate && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <div>
            <span>This item is</span>
            <span className="u__bold">&nbsp;backordered,&nbsp;</span>
            <span>but will be restocked soon.&nbsp;</span>
            <span>{'We won\'t charge you until it ships.'}</span>
          </div>
        </div>
      )}
      {backordered && formattedStartDate && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <div>
            <span>This product is currently</span>
            <span className="u__bold">&nbsp;backordered.&nbsp;</span>
            <span>{'We won\'t charge you until it ships.'}</span>
          </div>
        </div>
      )}
      {bosssDominant && bopisLocation && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <div>
            <span className="u__bold">{bopisLocation?.storeName}&nbsp;</span>
            <span>only has</span>
            <span className="u__bold">&nbsp;{bopisInventoryQuantity}&nbsp;</span>
            <span>in stock. </span>
          </div>
        </div>
      )}
      {bosssDominant && !bossLocation?.inventory?.quantity && (
        <div>
          <span>{'We\'ll ship the quantity you need to '}</span>
          <span className="u__bold">{bossLocation?.storeName}</span>
          <span> for</span>
          <span> free pickup </span>
          <span className="u__bold">{formattedStartDate}</span>
          {formattedEndDate && (
            <span className="u__bold">{` - ${formattedEndDate}`}</span>
          )}
        </div>
      )}
      {!bosssDominant && !bossLocation?.inventory?.quantity && (
        <div>
          <span>{'We\'ll send it to '}</span>
          <span className="u__bold">{bossLocation?.storeName}</span>
          <span> for</span>
          <span className="u__text--success"> free pickup </span>
        </div>
      )}
      {(!backordered || bosssDominant) && !!bossLocation?.inventory?.quantity && (
        <div>
          <span>{'We\'ll send up to '}</span>
          <span className="u__bold u__text--success">{bossInventoryQuantity}</span>
          <span> to&nbsp;</span>
          <span className="u__bold">{bossLocation?.storeName}</span>
          <span> for</span>
          <span className="u__text--success"> free pickup </span>
        </div>
      )}
      <div className="fulfillment__sub-title">
        <CheckNearByStore
          itemId={itemId}
          isShipToHomeEligible={isShipToHomeEligible}
        >
          Change Store
        </CheckNearByStore>
      </div>
      {!!bossLocation?.inventory?.quantity && bossLocation?.inventory?.quantity < quantity && (
        <InventoryWarning method={FULFILLMENT_METHODS.STORE} location={bossLocation} />
      )}
    </>
  );
};

DefaultBoSSDetails.propTypes = {
  itemId: string.isRequired,
  quantity: number,
  isShipToHomeEligible: bool,
  backordered: bool,
  bosssDominant: bool,
  bopisLocation: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool,
      isLimitedQuantity: bool,
      isBackordered: bool
    })
  }),
  bossLocation: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool,
      isLimitedQuantity: bool,
      isBackordered: bool
    })
  }),
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired
};

DefaultBoSSDetails.defaultProps = {
  quantity: 1,
  isShipToHomeEligible: false,
  bopisLocation: null,
  bossLocation: null,
  backordered: false,
  bosssDominant: false

};

export { DefaultBoSSDetails };
