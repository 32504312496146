import React from 'react';
import { string, bool } from 'prop-types';
import { CheckNearByStore } from './CheckNearByStore';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const OffshoreBossUnavailable = ({ itemId, isShipToHomeEligible, stateName }) => {

  return (
    <>
      <div className="u__center">
        <TileHead
          title="Ship to Store"
          icon="pick-up-false"
        />
        <div className="fulfillment-msg warning_text">Not available in {stateName}</div>
      </div>
      <TileFooter>
        <CheckNearByStore
          itemId={itemId}
          isShipToHomeEligible={isShipToHomeEligible}
        >
          Change Store
        </CheckNearByStore>
      </TileFooter>
    </>
  );
};
OffshoreBossUnavailable.propTypes = {
  itemId: string.isRequired,
  stateName: string,
  isShipToHomeEligible: bool
};
OffshoreBossUnavailable.defaultProps = {
  stateName: null,
  isShipToHomeEligible: false
};

export { OffshoreBossUnavailable };
