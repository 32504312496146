import React from 'react';
import { string, bool, func } from 'prop-types';
import { CheckNearByStore } from './CheckNearByStore';
import { CHECK_NEARBY_STORES } from '../../constants';
import './dominantTooltip.scss';

const BossDominantTooltip = ({
  isShipToHomeEligible,
  itemId,
  onClick,
  storeName
}) => (
  <div className="BossSharedToolTip__wrapper">
    <div className="BossSharedToolTip__content">
      <span className="u__clearfix">
        <div className="u__bold" id="need-more-text-message">Need More? We can ship it to your store!</div>
        <div>Just enter the quantity you need, and we will ship it to
          <span className="u__bold"> {storeName} </span> for free pickup.
        </div>
        <br />
        <div>
          <span className="u__bold BossSharedToolTip__align_bottom">Need it sooner? </span>
          <CheckNearByStore
            itemId={itemId}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            <span role="button" tabIndex={0} onClick={onClick}>{ CHECK_NEARBY_STORES }</span>
          </CheckNearByStore>
          <span className="BossSharedToolTip__align_bottom">
            &nbsp;to see if another store has enough items in stock.
            You can also split your order across multiple stores.
          </span>
        </div>

      </span>
    </div>
  </div>
);

BossDominantTooltip.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  onClick: func,
  storeName: string.isRequired
};
BossDominantTooltip.defaultProps = {
  isShipToHomeEligible: false,
  onClick: () => {}
};

export { BossDominantTooltip };
