import React from 'react';
import { string } from 'prop-types';

const TileHead = ({ title, icon }) => {

  return (
    <div className="fulfillment__head">
      <div className={`u__center ${icon}`} />
      <div className="u__bold">{title}</div>
    </div>
  );
};
TileHead.propTypes = {
  title: string,
  icon: string
};
TileHead.defaultProps = {
  title: '',
  icon: ''
};

export { TileHead };
