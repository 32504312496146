import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import {
  params,
  arrayOf as arrayType,
  string as stringType,
  fragment,
  shape,
  customType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import ProductShelfContainer from './ProductShelfContainer';
import { QueryProvider } from '@thd-nucleus/data-sources';

function ProductShelf({ componentId, componentClass, showTooFewProductsNotice }) {

  const { channel } = useContext(ExperienceContext);
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    }
  });

  if (!data || loading || error) {
    return null;
  }

  let title = data?.component?.title;
  let itemIds = data?.component?.productShelfData?.skus;
  let sortOrder = data?.component?.sortOrderOptions;
  let sortBy = data?.component?.sortBy;
  let navParam = data?.component?.productShelfData?.navParam;

  return (
    <>
      <QueryProvider
        cacheKey="product-shelf-cache"
        defaultVariables={{ storeId: '121', zipCode: '30339', skipKPF: true, skipSubscribeAndSave: true }}
      >
        <ProductShelfContainer
          channel={channel}
          showTooFewProductsNotice={showTooFewProductsNotice}
          opts={{
            itemIds: itemIds,
            navParam: navParam,
            sortBy: sortBy,
            sortOrder: sortOrder,
            contentfulTitle: title
          }}
        />
      </QueryProvider>
    </>
  );
}

ProductShelf.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  showTooFewProductsNotice: bool
};

ProductShelf.defaultProps = {
  componentClass: 'ProductShelf',
  showTooFewProductsNotice: false
}

ProductShelf.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['ProductShelf']).isRequired(),
}).shape({
    ProductShelf: fragment().shape({
      id: stringType(),
      componentName: stringType(),
      sortBy: stringType(),
      publishDate: stringType(),
      sortOrderOptions: stringType(),
      title: stringType(),
      productShelfData: shape({
        navParam: stringType(),
        skus: arrayType(stringType())
      })
    })
  })
};

export { ProductShelf };
