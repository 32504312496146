import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  customType, fragment, params, string as stringType, useDataModel
} from '@thd-nucleus/data-sources';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography
} from '@one-thd/sui-atomic-components';

import { RichText } from './RichText';
import './content-accordion.scss';

const ContentAccordion = ({ componentId, componentClass }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-accordion.ready'); }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  let descriptionContent;

  try {
    descriptionContent = JSON.parse(data?.component?.description);
    // eslint-disable-next-line no-empty
  } catch (error) { }

  return (
    <div className="content-accordion" data-component={componentClass}>
      <Accordion>
        <AccordionHeader aria-controls="accordion1-content" id="accordion1-header">
          <div className="sui-flex-auto sui-my-1 sui-block">
            <Typography
              component="div"
              variant="body-lg"
              weight="bold"
              height="tight"
            >
              {data?.component.title}
            </Typography>
            {data?.component.subtitle && (
              <div className="sui-mt-1">
                <Typography variant="body-base" height="tight" color="subtle">
                  {data?.component.subtitle}
                </Typography>
              </div>
            )}
          </div>
        </AccordionHeader>
        <AccordionBody>
          <RichText content={descriptionContent} />
        </AccordionBody>
      </Accordion>
    </div>
  );
};

ContentAccordion.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired
};

ContentAccordion.displayName = 'ContentAccordion';

ContentAccordion.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['ContentAccordion']).isRequired()
  }).shape({
    ContentAccordion: fragment().shape({
      id: stringType(),
      title: stringType(),
      subtitle: stringType(),
      description: stringType()
    })
  })
};

export { ContentAccordion };
