import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { DeliveryOptions } from '@thd-olt-component-react/delivery-options';
import { InventoryWarning } from '../store/InventoryWarning';
import { FULFILLMENT_METHODS } from '../../constants';
import {
  formatInventory,
  formatDate,
  formatWeek,
  showTodayOrTomorrow,
  getParsedFulfillment
} from '../../helper/utils';

const DefaultShippingDetails = (props) => {

  const [showCountdownTime, changeCountdownTimeStatus] = useState(true);
  const removeTimer = () => {
    changeCountdownTimeStatus(false);
  };

  const {
    itemId,
    shippingServiceModel,
    backordered,
    storeId,
    zipCode,
    quantity
  } = props;
  let { fulfillment } = props;

  fulfillment = getParsedFulfillment(fulfillment, shippingServiceModel);
  const deliveryStartDate = fulfillment?.deliveryDates?.startDate;
  const deliveryEndDate = fulfillment?.deliveryDates?.endDate;

  const startDate = formatDate(deliveryStartDate, true);
  const endDate = formatDate(deliveryEndDate, true);
  const sthLocation = (fulfillment?.locations || []).find((location) => location?.type === 'online');
  const inventoryQuantity = sthLocation?.inventory?.quantity;
  const inventoryQuantityValue = inventoryQuantity ? formatInventory(inventoryQuantity) : inventoryQuantity;
  return (
    <>
      {backordered && !startDate && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <div>
            <span>This item is</span>
            <span className="u__bold">&nbsp;backordered,&nbsp;</span>
            <span>but will be restocked soon.&nbsp;</span>
            <span>{'We won\'t charge you until it ships.'}</span>
          </div>
        </div>
      )}
      {backordered && startDate && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <div>
            <span>This product is currently</span>
            <span className="u__bold">&nbsp;backordered.&nbsp;</span>
            <span>{'We won\'t charge you until it ships.'}</span>
          </div>
        </div>
      )}
      {!fulfillment?.dynamicEta?.minutes
        && startDate && (
        <div>
          {startDate === endDate && (
            <div>
              {!!inventoryQuantity && (
                <>
                  <span className="u__bold u__text--success">{inventoryQuantityValue}</span>
                  <span>&nbsp;available for delivery</span>
                </>
              )}
              {!inventoryQuantity && (
                <>
                  <span>Get it by </span>
                  <span className="u__bold">
                    {`${formatWeek(deliveryStartDate, true)}, ${endDate}`}
                  </span>
                </>
              )}
              {zipCode && (
                <>
                  <span>&nbsp;to&nbsp;</span>
                  <span className="u__bold">{zipCode}</span>
                </>
              )}
            </div>
          )}
          {(startDate !== endDate && !backordered) && (
            <div>
              {!!inventoryQuantity && (
                <>
                  <span className="u__bold u__text--success">{inventoryQuantityValue}</span>
                  <span>&nbsp;available for delivery</span>
                </>
              )}
              {!inventoryQuantity && (
                <>
                  <span>Expect it </span>
                  <span className="u__bold">
                    {startDate}
                  </span>
                  {endDate && (
                    <span className="u__bold"> - {endDate}</span>
                  )}
                </>
              )}
              {zipCode && (
                <>
                  <span>&nbsp;to&nbsp;</span>
                  <span className="u__bold">{zipCode}</span>
                </>
              )}
            </div>
          )}
          {(startDate !== endDate && backordered) && (
            <div>
              <span>Expect delivery between </span>
              <span className="u__bold">
                {startDate}
              </span>
              {endDate && (
                <span className="u__bold"> - {endDate}</span>
              )}
              {zipCode && (
                <>
                  <span>&nbsp;to&nbsp;</span>
                  <span className="u__bold">{zipCode}</span>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {fulfillment?.dynamicEta?.minutes && showCountdownTime && !!inventoryQuantity && (
        <div className="countDownTimer-wrapper">
          <div>
            <div className="countDownTimer-deta">
              <span>Order within </span>
              <CountdownTimer
                hours={fulfillment.dynamicEta.hours || '0'}
                minutes={fulfillment.dynamicEta.minutes}
                onRemove={removeTimer}
              />
              <span> to get it by </span>
              <span className="u__bold">
                {`${showTodayOrTomorrow(deliveryStartDate, true)}, ${startDate}`}
              </span>
            </div>
            <div>
              <span className="u__bold u__text--success">{inventoryQuantityValue}</span>
              <span>&nbsp;ready for delivery</span>
              {zipCode && (
                <>
                  <span>&nbsp;to&nbsp;</span>
                  <span className="u__bold">{zipCode}</span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {fulfillment?.dynamicEta?.minutes && showCountdownTime && !inventoryQuantity && (
        <div className="countDownTimer-wrapper">
          <div>
            <span>Order within </span>
            <CountdownTimer
              hours={fulfillment.dynamicEta.hours || '0'}
              minutes={fulfillment.dynamicEta.minutes}
              onRemove={removeTimer}
            />
            <span> to get it by </span>
            <span className="u__bold">
              {`${showTodayOrTomorrow(deliveryStartDate, true)}, ${startDate}`}
            </span>
            {zipCode && (
              <>
                <span>&nbsp;to&nbsp;</span>
                <span className="u__bold">{zipCode}</span>
              </>
            )}
          </div>
        </div>
      )}
      {(!backordered || (backordered && deliveryStartDate)) && zipCode !== '' && (
        <div className="delivery-options__wrapper">
          <DeliveryOptions
            itemId={itemId}
            storeId={storeId}
            zipCode={zipCode}
            quantity={quantity}
          >
            Delivery Options
          </DeliveryOptions>
        </div>
      )}
      {!!sthLocation?.inventory?.quantity && sthLocation?.inventory?.quantity < quantity && (
        <InventoryWarning method={FULFILLMENT_METHODS.STH} location={sthLocation} />
      )}
    </>
  );
};

DefaultShippingDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  backordered: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  fulfillment: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-enable react/forbid-prop-types */
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool,
    })
  }),
  quantity: PropTypes.number,
  zipCode: PropTypes.string,
  storeId: PropTypes.string,
};

DefaultShippingDetails.defaultProps = {
  quantity: 1,
  shippingServiceModel: null,
  backordered: false,
  zipCode: null,
  storeId: null
};

export { DefaultShippingDetails };
