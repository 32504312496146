import React, { useContext } from 'react';
import { func, shape } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { productShape } from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { LimitedStockIcon } from '../../helpers/icon-utils';
import { isMobile } from '../../../components/helper/utils';

export const LimitedAvailability = ({
  product,
  onCheckNearbyStoresClick
}) => {
  const { channel } = useContext(ExperienceContext);
  const { storeName } = useStore();

  if (!storeName) {
    return <div className="store__message" />;
  }

  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      />
    );

  return (
    <div className="store__message">
      <div className="store__icon">{LimitedStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Buy in Store</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__warning">Limited stock</span>
          <span className="store__primary">{' at '}</span>
          {storeNameLink}
        </div>
      </div>
    </div>
  );
};

LimitedAvailability.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func
};

LimitedAvailability.defaultProps = {
  onCheckNearbyStoresClick: () => {},
};
