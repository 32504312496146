import React from 'react';
import {
  bool,
  string,
  shape,
  number
} from 'prop-types';
import { useDomPath } from '@thd-olt-functional/utils';
import { FULFILLMENT_METHODS } from '../../constants';
import { track } from '../../../analytics';

const OnlineInventoryWarning = ({ location }) => {
  const { isInStock, quantity } = location?.inventory;
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available.  Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning">
      <div>
        {isInStock && quantity && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>Only </span>
            <span className="u__bold">{quantity}</span>
            <span> in stock. Please reduce the quantity or change your fulfillment to proceed.</span>
          </>
        )}
      </div>
    </div>
  );
};

OnlineInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired
};

const StoreInventoryWarning = ({ location }) => {
  const isAnchor = location?.isAnchor;
  const { isInStock, quantity } = location?.inventory;
  const prefix = isAnchor ? 'Your store has only' : 'Your nearby store has only';
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available.  Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning">
      <div>
        {isInStock && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>
              {prefix}
            </span>
            <span className="u__bold"> {quantity}</span>
            <span> in stock. </span>
          </>
        )}
        <span>
          Please reduce the quantity, Check nearby pickup stores, or change your fulfillment to proceed.
        </span>
      </div>
    </div>
  );
};

StoreInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired
};

const BODFSInventoryWarning = ({ location }) => {
  const { isInStock, quantity } = location?.inventory;
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available.  Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning">
      <div>
        {isInStock && quantity && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>Only </span>
            <span className="u__bold">{quantity}</span>
            <span> in stock. Please reduce the quantity or change your fulfillment to proceed.</span>
          </>
        )}
      </div>
    </div>
  );
};

BODFSInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired
};

const InventoryWarning = ({ method, location }) => {
  if (method === FULFILLMENT_METHODS.BOPIS) {
    return (<StoreInventoryWarning location={location} />);
  }
  if (method === FULFILLMENT_METHODS.STORE || method === FULFILLMENT_METHODS.STH) {
    return (<OnlineInventoryWarning location={location} />);
  }
  if (method === FULFILLMENT_METHODS.BODFS) {
    return (<BODFSInventoryWarning location={location} />);
  }
  return null;
};

InventoryWarning.propTypes = {
  method: string,
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired
};

InventoryWarning.defaultProps = {
  method: null
};

export { InventoryWarning };
