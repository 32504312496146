import React, { useState } from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { useStore } from '@thd-nucleus/experience-context';
import { InventoryWarning } from './InventoryWarning';
import { CheckNearByStore } from './CheckNearByStore';
import { BossDominantTooltip } from './BossDominantTooltip';
import { FULFILLMENT_METHODS, CHECK_NEARBY_STORES } from '../../constants';
import { formatInventory } from '../../helper/utils';

const DefaultBOPISDetails = ({
  fulfillment,
  itemId,
  covidEnabled,
  isShipToHomeEligible,
  quantity,
  bossFulfillment,
  channel,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  isAppliance
}) => {
  const localStoreLocation = (fulfillment?.locations || []).find((location) => location.isAnchor);
  const isBopisThresholdStore = bopisMinimumThresholdStores?.split(',').indexOf(localStoreLocation?.locationId) !== -1;
  const showMinimumThresholdMessage = (quantity * pricing?.value) < bopisMinimumThreshold;
  const [tooltipInstance, setInstance] = useState(null);
  const onNearByStoreClick = () => {
    if (tooltipInstance?.hide) {
      tooltipInstance.hide();
    }
  };
  const inventory = localStoreLocation?.inventory?.quantity;
  const inventoryQuantity = inventory ? formatInventory(inventory) : inventory;
  const { storeName } = useStore();
  return (
    <>
      {localStoreLocation?.inventory?.isInStock && (
        <div>
          <div>
            <span className="u__text--success u__bold">{inventoryQuantity}</span>
            <span> in stock at </span>
            <span className="u__bold">{localStoreLocation.storeName || storeName}&nbsp;&nbsp;</span>
            {bossFulfillment && (
              <Tooltip
                channel={channel}
                closeButton
                placement="bottom"
                onShow={(instance) => {
                  setInstance(instance);
                }}
                content={(
                  <BossDominantTooltip
                    isShipToHomeEligible={isShipToHomeEligible}
                    itemId={itemId}
                    onClick={onNearByStoreClick}
                    storeName={localStoreLocation.storeName || storeName}
                  />
                )}
                interactive
              >
                <span className="delivery__tooltip-text">Need More?</span>
              </Tooltip>
            )}
          </div>
          {!isAppliance && (
            <div>
              <CheckNearByStore
                itemId={itemId}
                isShipToHomeEligible={isShipToHomeEligible}
              >
                { CHECK_NEARBY_STORES }
              </CheckNearByStore>
            </div>
          )}
          {showMinimumThresholdMessage && isBopisThresholdStore && (
            <div className="alert-inline alert-inline--warning inventory--warning">
              <span>There&apos;s a ${bopisMinimumThreshold} minimum purchase for pickup.</span>
            </div>
          )}
          {covidEnabled && (
            <div className="alert-inline alert-inline--warning inventory--warning">
              <span>This item may not be available for pickup until
                <span className="u__bold"> tomorrow.</span>
              </span>
            </div>
          )}
          {!!localStoreLocation?.inventory?.quantity && localStoreLocation?.inventory?.quantity < quantity && (
            <InventoryWarning method={FULFILLMENT_METHODS.BOPIS} location={localStoreLocation} />
          )}
        </div>
      )}
    </>
  );
};

DefaultBOPISDetails.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  quantity: number,
  covidEnabled: bool,
  channel: string,
  isAppliance: bool,
  bossFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  pricing: shape({
    value: number
  }).isRequired,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string
};

DefaultBOPISDetails.defaultProps = {
  isShipToHomeEligible: false,
  covidEnabled: false,
  quantity: 1,
  bossFulfillment: null,
  channel: 'desktop',
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  isAppliance: false
};

export { DefaultBOPISDetails };
