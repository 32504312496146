/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { TileGroup, Typography } from '@one-thd/sui-atomic-components';
import { useStore } from '@thd-nucleus/experience-context';
import { isRefinementActive } from '../../product-results-helpers';
import { AvailabilityRefinement } from '../Refinements/AvailabilityRefinement';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import { InStorePickup } from '~/@one-thd/sui-icons';
import '../../styles/availability-filters.scss';

const AvailabilityDimension = ({
  dimension,
  drawer,
  isActive,
  onChange,
  multiFacet,
  selectedRefinements,
  onDisplayData,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  enableMultiStore,
  isMobile,
}) => {

  const [collapse, setCollapse] = useState(!!drawer);
  const [multiStoreIds, setMultiStoreIds] = useState(multiStoreSelection ? [multiStoreSelection] : []);
  const { storeName } = useStore({ varnish: false });

  const getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  const {
    dimensionId,
    label,
    refinements
  } = dimension;

  const pickUpTodayRefinement = dimension?.refinements?.find((ref) => ref.label.toUpperCase() === 'PICK UP TODAY');
  const hasPickUpToday = pickUpTodayRefinement !== null || pickUpTodayRefinement !== undefined;
  const isSelected = dimension?.refinements?.find((ref) => ref.label.toUpperCase() === 'PICK UP TODAY')?.selected;
  const showStoreSelector = hasPickUpToday && isSelected && isMobile;

  const multiStoreCallback = ({ stores }) => {
    const idCount = multiStoreIds?.length;
    const checkedMultiStoreIds = stores.filter((store) => store.checked)
      .map((checkedStore) => parseInt(checkedStore.storeId, 10));

    // selected returning null after first store is selcted in dropdown
    // we are adding additional check to see if there are unchecked stores in dropdown menu
    if ((isSelected || checkedMultiStoreIds.length < 5) && enableMultiStore && onMultiStoreSelectionChange) {
      onMultiStoreSelectionChange({ multiStoreIds: checkedMultiStoreIds, onDisplay: onDisplayData?.isStoreDisplay });
    }
    setMultiStoreIds(checkedMultiStoreIds);
  };

  const dimensionClassName = classNames('dimension__availability', {
    'dimension__availability--desktop': !isMobile,
    'dimension__availability--mini': isMobile,
  });

  const wrapperClassName = classNames({
    'dimension--collapsed': collapse
  });

  const groupClassName = classNames('dimension__group sui-flex-nowrap', {
    'dimension__group--mini': isMobile,
    'dimension__group--scroll': !isMobile,
    'dimension__group--drawer-scroll': drawer
  });

  const borderClassName = classNames('dimension__box', {
    'dimension__box--line': drawer && !collapse && !multiFacet,
    'dimension__box--padding-right': multiFacet
  });

  const dimensionLabelClassName = classNames('dimension__label', {
    'dimension--active': isActive && drawer && multiFacet
  });

  const shadowClassName = classNames({ 'dimension__box--shadow': drawer });

  const refinementClassName = classNames('dimension__item', {
    'dimension__item--fullwidth': multiFacet
  });

  return (
    <div className={dimensionClassName}>
      <Row className={shadowClassName}>
        <Row className={dimensionLabelClassName}>
          <Typography
            variant="body-base"
            weight="bold"
            data-group={label}
          >
            {label}
          </Typography>
        </Row>
        <Row className={wrapperClassName}>
          <div
            className={groupClassName}
            key={dimensionId}
            data-instoredisplay="true"
            data-testid="availability-refinements"
          >
            <TileGroup
              onChange={onChange}
            >
              {
                refinements && refinements
                  .map((refinement, refinementIndex) => {
                    const active = isRefinementActive(refinement.refinementKey, selectedRefinements);
                    const id = getRefinementId(refinement, refinementIndex);
                    return (
                      <AvailabilityRefinement
                        dimension={dimension}
                        id={id}
                        key={id}
                        enableMultiStore={enableMultiStore}
                        refinement={refinement}
                        onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                        onChange={onChange}
                        multiStoreSelection={multiStoreSelection}
                        setMultiStoreIds={setMultiStoreIds}
                        onDisplay={onDisplayData.onDisplay}
                        multiFacet={multiFacet}
                        active={active}
                        isMobile={isMobile}
                      />
                    );
                  })
              }
            </TileGroup>
            {showStoreSelector && (
              <div className="refinement__availability--mini refinement__availability--store-selector">
                <Row>
                  <Col className="store-selector__icon" xs="1" fallback="1">
                    <InStorePickup size="small" />
                  </Col>
                  <Col xs="10" fallback="10" className="store-selector__mobile--wrapper">
                    <StoreSelector
                      multiStoreCallback={multiStoreCallback}
                      multiStoreSelection={multiStoreSelection}
                      multiFacet={multiFacet}
                      isFulfillmentRedesignEnabled
                      storeName={storeName}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Row>
      </Row>
    </div>
  );
};

AvailabilityDimension.propTypes = {
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  drawer: PropTypes.bool,
  enableMultiStore: PropTypes.bool,
  isActive: PropTypes.bool,
  multiFacet: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  onMultiStoreSelectionChange: PropTypes.func,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
};

AvailabilityDimension.defaultProps = {
  drawer: false,
  enableMultiStore: PropTypes.bool,
  isActive: false,
  multiFacet: false,
  multiStoreSelection: PropTypes.string,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  onMultiStoreSelectionChange: PropTypes.func,
  selectedRefinements: null,
  isMobile: false,
};

AvailabilityDimension.displayName = 'AvailabilityDimension';

export { AvailabilityDimension };
