import React, { Fragment } from 'react';
import {
  string,
  shape,
  number,
  bool
} from 'prop-types';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const InStockNearByStore = ({
  nearByStoreLocation,
  covidEnabled
}) => (
  <>
    <div className="u__center">
      <TileHead
        title="Store Pickup"
        icon="pick-up-true"
      />
      {nearByStoreLocation?.inventory?.isInStock && (
        <div className="fulfillment__sub-title">
          <span>{covidEnabled ? 'Available' : 'Pickup'}</span>
          <div className="u__bold">
            {!covidEnabled && (
              <span>Today </span>
            )}
            <span>({parseFloat(nearByStoreLocation?.distance).toFixed(1)} mi)</span>
          </div>
        </div>
      )}
    </div>
    <TileFooter className="u__bold">
      <span className="u__text--success">FREE</span>
    </TileFooter>
  </>
);

InStockNearByStore.propTypes = {
  nearByStoreLocation: shape({
    storeName: string,
    distance: number
  }).isRequired,
  covidEnabled: bool,
};

InStockNearByStore.defaultProps = {
  covidEnabled: false
};

export { InStockNearByStore };
