import React from 'react';
import PropTypes from 'prop-types';
import { InStockIcon } from '../../helpers/icon-utils';

export const BOSS = ({
  displayQuantity,
}) => {

  let messagingSuccess = 'Free ';
  let messagingPrimary = 'ship to store';

  if (displayQuantity > 0) {
    messagingSuccess = displayQuantity > 9999 ? '9999+ ' : `${displayQuantity} `;
    messagingPrimary = 'available for free ship to store';
  }

  return (
    <div className="store__message">
      <div className="store__icon">{InStockIcon}</div>
      <div className="store__text-box">
        <div>Pickup</div>
        <div className="store__second-line">
          <span className="store__success">{messagingSuccess}</span>
          <span className="store__primary">{messagingPrimary}</span>
        </div>
      </div>
    </div>
  );
};

BOSS.propTypes = {
  displayQuantity: PropTypes.number
};

BOSS.defaultProps = {
  displayQuantity: 0
};
