import React from 'react';
import { bool } from 'prop-types';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import './fulfillmentPlaceholder.scss';

const FulfillmentPlaceholder = ({ isMajorAppliances }) => {
  const tileArray = [];
  const tileCount = isMajorAppliances ? 2 : 3;
  const placeholderCss = classNames(
    { fulfillment__fullcontent: true },
    { 'fulfillment__placeholder-base': !isMajorAppliances },
    { 'fulfillment__placeholder-major-appliance': isMajorAppliances }
  );

  for (let i = 0; i < tileCount; i += 1) {
    tileArray.push(
      <div className="card card-container card-unselected card-disabled" key={i}>
        <div className="placeholder-container">
          <div className="card-head">
            <Placeholder type="text" height="2rem" />
          </div>
          { new Array(2).fill('').map((value, index) => {
            return <Placeholder type="text" height="1rem" key={index} />;
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="fulfillment__placeholder">
      <div className="fulfillment__placeholder--title">How to Get It</div>
      <br />
      <div className="fulfillment__placeholder-wrapper card-deck">
        {tileArray}
      </div>
      <div className={placeholderCss} />
    </div>
  );
};

FulfillmentPlaceholder.propTypes = {
  isMajorAppliances: bool
};

FulfillmentPlaceholder.defaultProps = {
  isMajorAppliances: false
};

export { FulfillmentPlaceholder };
