import React, { useState, useEffect } from 'react';
import {
  string, shape, arrayOf, func
} from 'prop-types';
import {
  DrawerItem, DrawerHeader, DrawerFooter
} from '@thd-olt-component-react/drawer';
import { Button, Row, Col } from '@thd-olt-component-react/core-ui';
import { FitCompatibility } from '@thd-olt-component-react/fit-compatibility';
import classNames from 'classnames/bind';
import styles from '../../styles/primary-filters-drawer.scss';
import {
  getRefinementsByDimension,
  isRefinementActive,
  sortSelectedDimensions,
  sortRefinementKeys,
  sortAscending,
  findCustomPriceRange,
  getDimensionsForUrl,
  getRefinemntsLabelsForUrl
} from '../../product-results-helpers';
import { DrawerRefinement } from './DrawerRefinement';
import { MultiStateDrawerRefinement } from './MultiStateDrawerRefinement';
import { publish } from '../../publisher';

const PrimaryFilterDrawerDimension = ({
  dimension,
  appliedDimensions,
  onCancel,
  onDimensionChange,
  baseUrl,
  canonicalUrl
}) => {
  const cx = classNames.bind(styles);
  const activeSelectedRefinements = appliedDimensions ? getRefinementsByDimension(appliedDimensions, dimension) : [];
  let [selectedRefinements, setSelectedRefinements] = useState(activeSelectedRefinements);

  const buttonLabel = activeSelectedRefinements?.length > 0 ? 'Update' : 'Apply';

  useEffect(() => {
    setSelectedRefinements(getRefinementsByDimension(appliedDimensions, dimension));
  }, [appliedDimensions]);

  const onRefinementChange = ({ refinement }) => {
    // verify whether or not the refinement is on the list
    let index = selectedRefinements.findIndex((ref) => ref.refinementKey === refinement.refinementKey);
    // coppy current list
    let temp = Array.isArray(selectedRefinements) ? selectedRefinements.slice() : [];
    if (index >= 0) {
      // if already in the list, remove it
      temp.splice(index, 1);
    } else {
      // if not in the list, add it
      temp.push(refinement);
    }
    setSelectedRefinements(temp);
  };

  const handleOnApply = () => {
    // copy current applied dimensions list
    let temp = appliedDimensions.slice();
    // check whether the dimension is in the appliedDimensions list
    const index = appliedDimensions.findIndex((ref) => {
      return ref.label === dimension.label;
    });
    // consolidate current selection and current applied dimensions
    if (index === 0) {
      temp[index].refinements = selectedRefinements;
    } else {
      temp.push({
        label: dimension.label,
        refinements: selectedRefinements
      });
    }
    // sort dimensions as per seo requirement
    const selectedimensions = temp;
    const sortedSelectedimensions = sortSelectedDimensions(selectedimensions);

    // get refinement keys and sort them by id
    let refinementKeys = sortedSelectedimensions
      .map((dim) => dim.refinements.map((ref) => ref.refinementKey)).flat(1);

    let sortedRefinementKeys = sortRefinementKeys(refinementKeys);

    if (selectedimensions && Object.keys(selectedimensions).length > 0) {
      publish('change-filters-refinements', selectedimensions);
    }
    // get dimensions that may go on the url
    let dimensionsIncludedInUrl = getDimensionsForUrl(sortedSelectedimensions);

    // get refinements from the dimensions which are added to the url
    let refinementsIncludedInUrl = dimensionsIncludedInUrl?.map(
      (_dimension) => _dimension.refinements.sort(sortAscending)).flat(1);
    let length = refinementsIncludedInUrl.length;
    let lastRefinement = refinementsIncludedInUrl[length - 1];
    let urlParts = baseUrl?.split('?')[0].split('/');

    // get custom price range
    const priceDimension = selectedimensions.find((_dimension) => _dimension.label === 'Price');
    const [lowerbound, upperbound] = findCustomPriceRange(priceDimension?.refinements);
    const canonicalUrlParts = canonicalUrl.split('?');
    let queryParams = canonicalUrlParts.length > 1 ? canonicalUrlParts[1] : '';
    if (lowerbound || upperbound) {
      let queries = queryParams.split('&')
        .filter((query) => !query.toLowerCase().includes('lowerbound') && !query.toLowerCase().includes('upperbound'));
      queryParams = queries.join('&');
      queryParams += `&lowerbound=${lowerbound}&upperbound=${upperbound}`;
    }
    const nValue = urlParts.find((part) => part.indexOf('N-') !== -1) || '';
    let refinementsIds = nValue;
    if (sortedRefinementKeys.length > 0) {
      refinementsIds += 'Z' + sortedRefinementKeys.join('Z');
    }

    // get refinement labels for url
    const refinamentsLabelsForUrl = getRefinemntsLabelsForUrl(refinementsIncludedInUrl);
    urlParts.splice(urlParts.indexOf(nValue), 0, ...refinamentsLabelsForUrl);

    urlParts.splice(urlParts.indexOf(nValue), 1, refinementsIds);
    const url = queryParams ? urlParts.join('/') + '?' + queryParams : urlParts.join('/');
    lastRefinement = { ...lastRefinement, url };
    onDimensionChange({ refinement: lastRefinement });
  };

  const hasFitCompatibility = [
    'DEPTH (EXCLUDING HANDLES) (IN.)',
    'HEIGHT TO TOP OF DOOR HINGE (IN.)',
    'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
    'INSTALLATION DEPTH',
    'REFRIGERATOR FIT WIDTH',
    'TOTAL CAPACITY (CU. FT.)',
    'CAPACITY (CU. FT.) - REFRIGERATORS',
    'REFRIGERATOR CAPACITY (CU. FT.)',
    'DEPTH (INCLUDING HANDLES) (IN.)'
  ].includes(dimension.label.toUpperCase());

  return (
    <DrawerItem name={dimension.label} key={`primary-filter-item${dimension.label}`}>
      <DrawerHeader title={dimension.label} />
      <div className={cx('primary-filters-drawer__container')}>
        {hasFitCompatibility && (
          <div
            role="button"
            tabIndex={0}
            aria-label="fit-compatibility"
            className="sui-p-1 sui-pb-0 sui-ml-1"
            onClick={onCancel}
          >
            <FitCompatibility
              type="MINI"
              drawerPosition="left"
              imageCacheEnabled
            />
          </div>
        )}
        {dimension.refinements.map((refinement) => {
          if (dimension.label === 'Category') {
            return (
              <DrawerRefinement
                key={`primary-filters-rerfinement-${refinement.refinementKey}`}
                refinement={refinement}
                dimension={dimension}
                onClick={onDimensionChange}
              />
            );
          }
          return (
            <MultiStateDrawerRefinement
              dimension={dimension}
              refinement={refinement}
              key={`primary-filters-rerfinement-${refinement.refinementKey}`}
              onClick={onRefinementChange}
              selected={isRefinementActive(refinement.refinementKey, selectedRefinements)}
            />
          );
        })}
      </div>
      {dimension.label !== 'Category'
          && (
            <DrawerFooter>
              <Row>
                <Col>
                  <Button
                    onClick={handleOnApply}
                    disabled={selectedRefinements.length === 0 && activeSelectedRefinements.length === 0}
                  >
                    {buttonLabel}
                  </Button>
                </Col>
                <Col>
                  <Button outline onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </DrawerFooter>
          )}
    </DrawerItem>
  );
};

PrimaryFilterDrawerDimension.displayName = 'PrimaryFilterDrawerDimension';
PrimaryFilterDrawerDimension.propTypes = {
  dimension: shape({
    label: string
  }).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  onCancel: func.isRequired,
  onDimensionChange: func.isRequired,
};

export { PrimaryFilterDrawerDimension };
