import React from 'react';
import { bool, shape, string } from 'prop-types';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

export const DirectDelivery = ({ enabled, checkAvailabilityModel, isMinimumFreeShipPrice }) => {
  const {
    hasBackordered = false,
    hasOutOfStock = false,
    isUnavailable = false,
    earliestAvailabilityDate
  } = checkAvailabilityModel || {};
  const icon = enabled ? 'direct-delivery' : 'direct-delivery--disabled';
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Home Delivery"
          icon={icon}
        />
        <div className="fulfillment__sub-title">
          {hasBackordered && earliestAvailabilityDate && (
            <>
              <span>{`Backordered ${earliestAvailabilityDate ? 'until' : ''}`}</span>
              <div className="u__bold">{earliestAvailabilityDate}</div>
            </>
          )}
          {earliestAvailabilityDate && !hasBackordered && !hasOutOfStock && !isUnavailable && (
            <>
              <span>Expect it by</span>
              <div className="u__bold">
                {earliestAvailabilityDate}
              </div>
            </>
          )}
          {(hasOutOfStock || isUnavailable || !checkAvailabilityModel) && (
            <span>Check delivery date below</span>
          )}
        </div>
      </div>
      <TileFooter className="u__bold">
        {isMinimumFreeShipPrice
          ? <span className="u__text--success">FREE</span>
          : <span className="u__text--success">Free Over $396</span>}
      </TileFooter>
    </>
  );
};

DirectDelivery.displayName = 'DirectDelivery';

DirectDelivery.propTypes = {
  enabled: bool.isRequired,
  checkAvailabilityModel: shape({
    zipCode: string,
    hasBackordered: bool,
    rawEarliestAvailabilityDate: string
  }),
  isMinimumFreeShipPrice: bool.isRequired
};

DirectDelivery.defaultProps = {
  checkAvailabilityModel: null
};
