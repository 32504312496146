import React, { useContext } from 'react';
import PropTypes, { number, string, func, bool } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { dataModel } from './dataModel';
import { FULFILLMENT_METHODS } from './constants';
import { useMajorApplianceFulfillment } from '../hooks/useMajorApplianceFulfillment';
import { ApplianceError } from './ApplianceError/ApplianceError';
import { Discontinued } from './Discontinued/Discontinued';
import { FulfillmentPlaceholder } from './Placeholder/FulfillmentPlaceholder';
import { StoreUnavailable } from './partials/store/StoreUnavailable';
import './fulfillment.scss';
import { isMobile } from './helper/utils';

const MajorApplianceFulfillment = (props) => {
  const {
    itemId,
    configurableApplianceItemIds,
    storeId,
    quantity,
    onChange,
    covidAlert,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming
  } = props;

  const {
    channel,
  } = useContext(ExperienceContext);
  const { storeId: defaultStoreId, storeName } = useStore();

  const cookieZipCode = typeof window !== 'undefined' && window?.cookieUtils?.readBrowserCookie('DELIVERY_ZIP');

  const [{
    availabilityType,
    fulfillmentModels,
    currentFulfillment,
    someSkusHaveZeroPrice,
    loading
  }, changeFulfillment] = useMajorApplianceFulfillment({
    itemId,
    configurableApplianceItemIds,
    quantity,
    zipCode: cookieZipCode,
    storeId: storeId || defaultStoreId,
    storeName,
    channel,
    onChange,
    covidAlert,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming
  });
  const [directDeliveryTemplate = {}, storeTemplate = {}] = fulfillmentModels;

  if (loading) {
    return (
      <FulfillmentPlaceholder isMajorAppliances />
    );
  }

  if (someSkusHaveZeroPrice) {
    return (
      <StoreUnavailable itemId={itemId} isAppliance />
    );
  }

  if (availabilityType?.discontinued) {
    return (
      <Discontinued />
    );
  }
  if ((!directDeliveryTemplate.enabled && !storeTemplate.enabled)) {
    return (
      <ApplianceError
        directDelivery={directDeliveryTemplate}
      />
    );
  }
  return (
    <>
      <div className="fulfillment__header_title">How to Get It</div>
      <br />
      <div className="fulfillment__content-wrapper card-deck">
        {fulfillmentModels.map((fulfillmentModel, i) => {
          const wrapperClasses = classNames({
            card: true,
            u__legal: isMobile(channel),
            'mobile--margin-right': true,
            'card-container': true,
            'u__text--primary': !fulfillmentModel.enabled,
            'appliance--card-selected': fulfillmentModel.method === currentFulfillment?.method,
            'appliance--card-unselected':
              fulfillmentModel.method !== currentFulfillment?.method && fulfillmentModel.enabled,
            'appliance--card-enabled': fulfillmentModel.enabled,
            'appliance--card-unclickable':
              fulfillmentModel.method !== currentFulfillment?.method && !fulfillmentModel.enabled,
          });
          return (
            // eslint-disable-next-line
            <a
              key={i}
              type="button"
              className={wrapperClasses}
              onClick={() => {
                if ((fulfillmentModel.method === currentFulfillment?.method) || !fulfillmentModel.enabled) return;
                changeFulfillment(fulfillmentModel);
              }}
            >
              {fulfillmentModel.template}
            </a>
          );
        })}
      </div>
      {currentFulfillment?.subTemplate && (
        <div className="fulfillment__fullcontent">
          {currentFulfillment.subTemplate}
          {currentFulfillment.method === FULFILLMENT_METHODS.BOPIS && (
            <div>
              <span className="u__bold">
                Product installation and haul away are not available with appliance store pick up.
              </span>
              <span>
                {` Protection plans and parts needed for
                installation are available for purchase at the store. To pick up your
                appliance, go directly to the customer service desk. When you arrive at the store,
                please ask a store associate for assistance.
                Please ensure you have a vehicle that is able to transport a large appliance.`}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

MajorApplianceFulfillment.displayName = 'MajorApplianceFulfillment';

MajorApplianceFulfillment.propTypes = {
  /** An Item that has product highlights */
  itemId: string.isRequired,
  configurableApplianceItemIds: PropTypes.arrayOf(PropTypes.string),
  covidAlert: bool,
  storeId: string,
  quantity: number,
  onChange: func,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  bopisHolidayTiming: number
};

MajorApplianceFulfillment.defaultProps = {
  configurableApplianceItemIds: null,
  covidAlert: false,
  quantity: 1,
  storeId: null,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  onChange: () => {},
  bopisHolidayTiming: null
};

// ['identifiers', 'info', 'pricing', 'availabilityType', 'fulfillment']
MajorApplianceFulfillment.dataModel = extend(
  CheckAvailability,
  dataModel
);

export { MajorApplianceFulfillment };
