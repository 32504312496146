import React from 'react';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { CheckAvailabilityWrapper } from '../partials/direct-delivery/CheckAvailabilityWrapper';
import { Unavailable } from '../partials/shipping/Unavailable';
import { OutOfStockOnlineOnly } from '../partials/shipping/OutOfStockOnlineOnly';
import { DefaultShipping } from '../partials/shipping/DefaultShipping';
import { DefaultShippingDetails } from '../partials/shipping/DefaultShipping_Details';
import { ExpressDelivery } from '../partials/express/ExpressDelivery';
import { ExpressDeliveryDetails } from '../partials/express/ExpressDeliveryDetails';
import { InStockNearByStore } from '../partials/store/InStockNearByStore';
import { InStockNearByStoreDetails } from '../partials/store/InStockNearByStoreDetails';
import { LimitedStockByStore } from '../partials/store/LimitedStockByStore';
import { DefaultBoSS } from '../partials/store/DefaultBoSS';
import { DefaultBoSSDetails } from '../partials/store/DefaultBoSSDetails';
import { NotAvailable } from '../partials/NotAvailable';
import { BOSSOutOfStock } from '../partials/store/BOSSOutOfStock';
import { BOPISOutOfStock } from '../partials/store/BOPISOutOfStock';
import { DefaultBOPIS } from '../partials/store/DefaultBOPIS';
import { DefaultBOPISDetails } from '../partials/store/DefaultBOPISDetails';
import { StoreOnly } from '../partials/store/StoreOnly';
import { StoreUnavailable } from '../partials/store/StoreUnavailable';
import { OffshoreBossUnavailable } from '../partials/store/OffshoreBossUnavailable';
import { OffshoreSthUnavailable } from '../partials/shipping/OffshoreSthUnavailable';
import { DirectDelivery } from '../partials/direct-delivery/DirectDelivery';
import { FULFILLMENT_METHODS, MAJOR_APPLIANCE } from '../constants';
import {
  isStoreOnlyWithClearance,
  isOutOfStockOnline,
  getNearByStore,
  getLocalStore,
  getOnlineLocation,
  isBOSSBackordered,
  isBOSSOutOfStock,
  isBopisOutOfStock,
  isBOPISUnavailable,
  isStoreOnlyOOS,
  isBOSSDominant,
  isCovidAlertEnabled,
  getExcludedStateName,
  isLocationInExcludedStates,
  hasNoDirectDeliveryAvailable,
  isNoBOPISForAppliance,
  isMajorApplianceProductType
} from './utils';

export const getShippingTemplate = ({
  fulfillment,
  shippingServiceModel,
  type,
  zipCode,
  storeId,
  quantity,
  itemId,
  setShowCheckAvailability
}) => {
  const fulfillmentOptions = fulfillment?.fulfillmentOptions;
  const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const shippingState = shippingServiceModel ? shippingServiceModel?.state : shippingFulfillment?.locations[0]?.state;
  const shippingLocation = getOnlineLocation(shippingFulfillment);

  if (fulfillment?.sthExcludedShipState && !shippingFulfillment) {
    return {
      method: FULFILLMENT_METHODS.STH,
      enabled: false,
      template: <OffshoreSthUnavailable
        itemId={itemId}
        storeId={storeId}
        zipCode={zipCode}
        quantity={quantity}
        stateName={getExcludedStateName(fulfillment?.sthExcludedShipState)}
        setShowCheckAvailability={setShowCheckAvailability}
      />,
    };
  }

  if (!fulfillmentOptions && (type === 'Online' || type === 'Shared')) {
    return {
      method: FULFILLMENT_METHODS.STH,
      enabled: false,
      outOfStock: true,
      showNotify: type === 'Online',
      template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
      subTemplate: <OutOfStockOnlineOnly type={type} />
    };
  }

  if (shippingFulfillment) {
    if (isOutOfStockOnline(shippingFulfillment)) {
      return {
        method: FULFILLMENT_METHODS.STH,
        enabled: false,
        outOfStock: true,
        template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
        subTemplate: <OutOfStockOnlineOnly />
      };
    }

    if (isLocationInExcludedStates(shippingState, fulfillment?.excludedShipStates)) {
      return {
        method: FULFILLMENT_METHODS.STH,
        enabled: false,
        template: <OffshoreSthUnavailable
          stateName={getExcludedStateName(shippingState)}
          setShowCheckAvailability={setShowCheckAvailability}
        />,
      };
    }

    return {
      method: FULFILLMENT_METHODS.STH,
      enabled: true,
      quantityLimitExceed: shippingLocation?.inventory?.quantity > 0
        && shippingLocation?.inventory?.quantity < quantity,
      template: <DefaultShipping
        fulfillment={shippingFulfillment}
        shippingServiceModel={shippingServiceModel}
        backorderedShipDate={fulfillment?.backorderedShipDate}
        backordered={fulfillment?.backordered}
      />,
      subTemplate: <DefaultShippingDetails
        itemId={itemId}
        backorderedShipDate={fulfillment?.backorderedShipDate}
        backordered={fulfillment?.backordered}
        fulfillment={shippingFulfillment}
        shippingServiceModel={shippingServiceModel}
        zipCode={zipCode}
        storeId={storeId}
        quantity={quantity}
      />
    };
  }

  return {
    method: FULFILLMENT_METHODS.STH,
    enabled: false,
    unavailable: fulfillmentOptions ? isStoreOnlyOOS(fulfillment, type) : true,
    template: <NotAvailable type={FULFILLMENT_METHODS.STH} />,
    subTemplate: <Unavailable />
  };
};

export const getStoreTemplate = ({
  fulfillment,
  type,
  productType,
  channel,
  covidAlert,
  quantity,
  itemId,
  storeName,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bopisHolidayTiming
}) => {
  const fulfillmentOptions = fulfillment?.fulfillmentOptions;
  const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  const method = bopisFulfillment ? FULFILLMENT_METHODS.BOPIS : FULFILLMENT_METHODS.STORE;
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const bossLocation = getOnlineLocation(bossFulfillment);
  const covidEnabled = isCovidAlertEnabled(covidAlert, bopisHolidayTiming);
  const isAppliance = isMajorApplianceProductType(productType);
  if (isNoBOPISForAppliance(productType, fulfillment)) {
    return {
      method: FULFILLMENT_METHODS.BOPIS,
      enabled: false,
      template: <NotAvailable
        itemId={itemId}
        type={FULFILLMENT_METHODS.BOPIS}
      />
    };
  }
  if ((fulfillment?.bossExcludedShipState !== null)
    && (fulfillment?.bossExcludedShipStates !== undefined)
    && !bossFulfillment
    && !bopisFulfillment) {
    return {
      method,
      enabled: false,
      template: <OffshoreBossUnavailable
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        stateName={getExcludedStateName(fulfillment?.bossExcludedShipState)}
      />,
    };
  }
  if (((!fulfillmentOptions || !pickupService) && type === 'Shared')
    || (isStoreOnlyWithClearance(fulfillment, type) && !(nearByStoreLocation?.inventory?.isInStock))) {
    return {
      method,
      enabled: false,
      outOfStock: true,
      unavailable: true,
      template: <BOPISOutOfStock
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        isAppliance={isAppliance}
      />,
      subTemplate: <StoreUnavailable
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        isAppliance={isAppliance}
      />
    };
  }
  if (nearByStoreLocation) {
    if (nearByStoreLocation?.inventory?.isInStock) {
      return {
        method,
        enabled: true,
        quantityLimitExceed: nearByStoreLocation?.inventory?.quantity > 0
          ? nearByStoreLocation.inventory.quantity < quantity
          : false,
        template: <InStockNearByStore
          covidEnabled={covidEnabled}
          nearByStoreLocation={nearByStoreLocation}
        />,
        subTemplate: <InStockNearByStoreDetails
          covidEnabled={covidEnabled}
          isAppliance={isAppliance}
          itemId={itemId}
          fulfillment={bopisFulfillment}
          quantity={quantity}
          isShipToHomeEligible={!!shippingFulfillment}
          pricing={pricing}
          bopisMinimumThreshold={bopisMinimumThreshold}
          bopisMinimumThresholdStores={bopisMinimumThresholdStores}
        />
      };
    }
    if (nearByStoreLocation.inventory.isLimitedQuantity) {
      return {
        method,
        enabled: false,
        template: <LimitedStockByStore
          itemId={itemId}
          isShipToHomeEligible={!!shippingFulfillment}
          location={nearByStoreLocation}
          isAppliance={isAppliance}
        />
      };
    }
    if (bossFulfillment && !nearByStoreLocation?.inventory?.isInStock) {
      return {
        method,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} />,
        subTemplate: <DefaultBoSSDetails
          itemId={itemId}
          fulfillment={bossFulfillment}
          isShipToHomeEligible={!!shippingFulfillment}
          bopisLocation={getLocalStore(bopisFulfillment)}
          bossLocation={bossLocation}
        />
      };
    }
  }
  if (bopisFulfillment) {
    const localStoreLocation = getLocalStore(bopisFulfillment);
    // In-store Purchase scenario
    if (!pickupService.fulfillable) {
      return {
        method,
        enabled: false,
        template: <StoreOnly
          bopisFulfillment={bopisFulfillment}
          isShipToHomeEligible={!!shippingFulfillment}
          itemId={itemId}
          isAppliance={isAppliance}
        />
      };
    }
    if (localStoreLocation && localStoreLocation?.inventory?.isLimitedQuantity) {
      if (bossFulfillment) {
        return {
          method,
          enabled: true,
          quantityLimitExceed: bossLocation?.inventory?.quantity > 0
            ? bossLocation?.inventory?.quantity < quantity
            : false,
          template: <DefaultBoSS fulfillment={bossFulfillment} />,
          subTemplate: <DefaultBoSSDetails
            itemId={itemId}
            fulfillment={bossFulfillment}
            isShipToHomeEligible={!!shippingFulfillment}
            bopisLocation={localStoreLocation}
            bossLocation={bossLocation}
          />
        };
      }
      return {
        method,
        enabled: false,
        template: <LimitedStockByStore
          itemId={itemId}
          isShipToHomeEligible={!!shippingFulfillment}
          location={localStoreLocation}
          isAppliance={isAppliance}
        />
      };
    }
    if (isBopisOutOfStock(localStoreLocation, nearByStoreLocation) || isBOPISUnavailable(localStoreLocation)) {
      return {
        method,
        enabled: false,
        unavailable: type === 'Store Only',
        template: <BOPISOutOfStock
          itemId={itemId}
          isShipToHomeEligible={!!shippingFulfillment}
          isAppliance={isAppliance}
        />,
        subTemplate: <StoreUnavailable
          itemId={itemId}
          location={localStoreLocation}
          isShipToHomeEligible={!!shippingFulfillment}
          isAppliance={isAppliance}
        />
      };
    }
    if (isBOSSDominant(bossFulfillment, localStoreLocation, quantity)) {
      return {
        method: FULFILLMENT_METHODS.BOSS,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} />,
        subTemplate: <DefaultBoSSDetails
          itemId={itemId}
          quantity={quantity}
          fulfillment={bossFulfillment}
          bosssDominant
          bopisLocation={localStoreLocation}
          bossLocation={bossLocation}
          isShipToHomeEligible={!!shippingFulfillment}
        />
      };
    }
    return {
      method,
      enabled: true,
      quantityLimitExceed: localStoreLocation?.inventory?.quantity > 0
        ? localStoreLocation.inventory.quantity < quantity
        : false,
      template: <DefaultBOPIS localStoreLocation={localStoreLocation} covidEnabled={covidEnabled} />,
      subTemplate: <DefaultBOPISDetails
        itemId={itemId}
        covidEnabled={covidEnabled}
        fulfillment={bopisFulfillment}
        isShipToHomeEligible={!!shippingFulfillment}
        quantity={quantity}
        channel={channel}
        isAppliance={isAppliance}
        bossFulfillment={bossFulfillment}
        pricing={pricing}
        bopisMinimumThreshold={bopisMinimumThreshold}
        bopisMinimumThresholdStores={bopisMinimumThresholdStores}
      />
    };
  }
  if (bossFulfillment) {
    const localStoreLocation = getLocalStore(bossFulfillment);
    if (isLocationInExcludedStates(localStoreLocation?.state, fulfillment.bossExcludedShipStates)) {
      return {
        method,
        enabled: false,
        template: <OffshoreBossUnavailable
          itemId={itemId}
          isShipToHomeEligible={!!shippingFulfillment}
          stateName={getExcludedStateName(localStoreLocation?.state)}
        />,
      };
    }
    if (isBOSSBackordered(fulfillment)) {
      return {
        method,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS
          fulfillment={bossFulfillment}
          backordered={fulfillment?.backordered}
          backorderedShipDate={fulfillment?.backorderedShipDate}
        />,
        subTemplate: <DefaultBoSSDetails
          itemId={itemId}
          quantity={quantity}
          backorderedShipDate={fulfillment?.backorderedShipDate}
          backordered={fulfillment?.backordered}
          fulfillment={bossFulfillment}
          isShipToHomeEligible={!!shippingFulfillment}
          bopisLocation={localStoreLocation}
          bossLocation={bossLocation}
        />
      };
    }
    if (type === 'Shared' && isBOSSOutOfStock(fulfillment)) {
      return {
        method,
        enabled: false,
        unavailable: true,
        template: <BOSSOutOfStock />,
        subTemplate: <StoreUnavailable
          itemId={itemId}
          location={localStoreLocation}
          isShipToHomeEligible={!!shippingFulfillment}
        />
      };
    }
    if (!isBOSSOutOfStock(fulfillment)) {
      return {
        method,
        enabled: true,
        quantityLimitExceed: bossLocation?.inventory?.quantity > 0
          ? bossLocation?.inventory?.quantity < quantity
          : false,
        template: <DefaultBoSS fulfillment={bossFulfillment} />,
        subTemplate: <DefaultBoSSDetails
          quantity={quantity}
          itemId={itemId}
          fulfillment={bossFulfillment}
          isShipToHomeEligible={!!shippingFulfillment}
          bopisLocation={localStoreLocation}
          bossLocation={bossLocation}
        />
      };
    }
  }

  if (type === 'Online') {
    return {
      method: FULFILLMENT_METHODS.STORE,
      enabled: false,
      template: <NotAvailable type={method} notSold />
    };
  }

  if (type === 'Store Only') {
    return {
      method: FULFILLMENT_METHODS.STORE,
      enabled: false,
      unavailable: (!fulfillmentOptions || !pickupService) ? true
        : (isStoreOnlyWithClearance(fulfillment, type) || isStoreOnlyOOS(fulfillment, type)),
      template: <NotAvailable
        itemId={itemId}
        type={FULFILLMENT_METHODS.BOPIS}
      />,
      subTemplate: <StoreUnavailable
        itemId={itemId}
        isShipToHomeEligible={!!shippingFulfillment}
        isAppliance={isAppliance}
      />
    };
  }

  return {
    method: FULFILLMENT_METHODS.BOPIS,
    enabled: false,
    template: <NotAvailable
      itemId={itemId}
      type={FULFILLMENT_METHODS.BOPIS}
    />
  };
};

export const getExpressDeliveryTemplate = ({
  fulfillment,
  shippingServiceModel,
  channel,
  covidAlert,
  quantity,
  pricing,
  bodfsMinimumThreshold,
  zipCode
}) => {
  const fulfillmentOptions = fulfillment?.fulfillmentOptions;
  const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const expressFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
  const bodfsLocation = (expressFulfillment?.locations || []).find((location) => location?.type === 'store');
  if (expressFulfillment) {
    if (shippingServiceModel && !shippingServiceModel?.isBodfsShipMode) {
      return {
        method: FULFILLMENT_METHODS.BODFS,
        enabled: false,
        template: <NotAvailable type={FULFILLMENT_METHODS.BODFS} covidEnabled={covidAlert} />
      };
    }
    return {
      method: FULFILLMENT_METHODS.BODFS,
      enabled: true,
      quantityLimitExceed: bodfsLocation?.inventory?.quantity > 0
        ? bodfsLocation?.inventory?.quantity < quantity
        : false,
      template: <ExpressDelivery
        fulfillment={expressFulfillment}
        covidEnabled={covidAlert}
        shippingServiceModel={shippingServiceModel}
      />,
      subTemplate: <ExpressDeliveryDetails
        fulfillment={expressFulfillment}
        channel={channel}
        covidEnabled={covidAlert}
        quantity={quantity}
        pricing={pricing}
        bodfsMinimumThreshold={bodfsMinimumThreshold}
        shippingServiceModel={shippingServiceModel}
        zipCode={zipCode}
      />
    };
  }
  return {
    method: FULFILLMENT_METHODS.BODFS,
    enabled: false,
    template: <NotAvailable type={FULFILLMENT_METHODS.BODFS} covidEnabled={covidAlert} />
  };
};

export const getDirectDeliveryTemplate = ({
  itemId,
  configurableApplianceItemIds,
  zipCode,
  productType,
  quantity,
  checkAvailabilityModel,
  isMinimumFreeShipPrice,
  onApplianceCheckAvailability,
  fulfillment
}) => {
  const enabled = !hasNoDirectDeliveryAvailable({ productType, fulfillment, checkAvailabilityModel });
  const outOfStock = checkAvailabilityModel?.hasOutOfStock;
  const unavailable = checkAvailabilityModel?.isUnavailable;
  const template = (
    <DirectDelivery
      enabled={enabled}
      checkAvailabilityModel={checkAvailabilityModel}
      isMinimumFreeShipPrice={isMinimumFreeShipPrice}
    />
  );
  const subTemplate = (
    <CheckAvailabilityWrapper>
      <CheckAvailability
        autoCheck={!!zipCode}
        inputHeaderTitle="Check Earliest Delivery Date"
        itemId={configurableApplianceItemIds ? null : itemId}
        itemIds={configurableApplianceItemIds}
        zipCode={zipCode}
        quantity={quantity}
        isMajorAppliance
        checkAvailabilityModel={checkAvailabilityModel}
        onSubmitForm={onApplianceCheckAvailability}
      />
    </CheckAvailabilityWrapper>
  );

  return {
    method: FULFILLMENT_METHODS.APPLIANCE,
    enabled,
    outOfStock,
    unavailable,
    template,
    subTemplate,
  };
};

export const getFulfillmentModels = ({
  fulfillment,
  shippingServiceModel,
  type,
  covidAlert,
  quantity,
  zipCode,
  storeId,
  itemId,
  storeName,
  channel,
  pricing,
  bodfsMinimumThreshold,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  bopisHolidayTiming,
  setShowCheckAvailability
}) => {

  const shippingTemplate = getShippingTemplate({
    fulfillment,
    shippingServiceModel,
    type,
    zipCode,
    storeId,
    quantity,
    itemId,
    setShowCheckAvailability
  });
  const storeTemplate = getStoreTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    quantity,
    storeName,
    pricing,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming
  });
  const ExpressDeliveryTemplate = getExpressDeliveryTemplate({
    fulfillment,
    shippingServiceModel,
    channel,
    covidAlert,
    quantity,
    pricing,
    bodfsMinimumThreshold,
    zipCode
  });

  return [storeTemplate, shippingTemplate, ExpressDeliveryTemplate];

};

export const getApplianceFulfillmentModels = ({
  fulfillment,
  type,
  productType,
  covidAlert,
  quantity,
  storeName,
  channel,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  itemId,
  configurableApplianceItemIds,
  zipCode,
  checkAvailabilityModel,
  isMinimumFreeShipPrice,
  onApplianceCheckAvailability,
  bopisHolidayTiming
}) => {
  const storeTemplate = getStoreTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    productType,
    quantity,
    storeName,
    pricing,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming
  });
  const directDeliveryTemplate = getDirectDeliveryTemplate({
    itemId,
    configurableApplianceItemIds,
    zipCode,
    quantity,
    productType,
    checkAvailabilityModel,
    isMinimumFreeShipPrice,
    onApplianceCheckAvailability,
    fulfillment
  });
  return [directDeliveryTemplate, storeTemplate];
};
