import React from 'react';

const DualPath = () => {
  return (
    <div className="fulfillment__unavailable">
      <fieldset className="fulfillment__unavailable--fieldset">
        <legend className="fulfillment__unavailable--title">How To Get it</legend>
        <span className="fulfillment__unavailable--content--dual-path">
          You have selected to have this item installed,
          so it isn&#39;t available to add to your cart for pickup or for delivery.
          One of our Pros will reach out to discuss product installation and pricing.
        </span>
      </fieldset>
    </div>
  );
};

DualPath.propTypes = {};

export { DualPath };
