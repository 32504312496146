import React from 'react';
import { string, bool } from 'prop-types';
import { FULFILLMENT_METHODS } from '../constants';
import { CheckNearByStore } from './store/CheckNearByStore';
import { TileHead } from './TileHead';
import { TileFooter } from './TileFooter';

const NotAvailable = (props) => {
  const {
    type,
    covidEnabled,
    notSold,
    itemId
  } = props;

  const getIcon = (fulfillType) => {
    switch (fulfillType) {
    case FULFILLMENT_METHODS.STH:
      return 'delivery-false';
    case FULFILLMENT_METHODS.BODFS:
      return 'express-false';
    case FULFILLMENT_METHODS.BOPIS:
    case FULFILLMENT_METHODS.STORE:
      return 'pick-up-false';
    default:
      return '';
    }
  };

  const getTitle = (fulfillType) => {
    switch (fulfillType) {
    case FULFILLMENT_METHODS.STH:
      return 'Ship to Home';
    case FULFILLMENT_METHODS.BODFS:
      return covidEnabled ? 'Scheduled Delivery' : 'Express Delivery';
    case FULFILLMENT_METHODS.BOPIS:
      return 'Store Pickup';
    case FULFILLMENT_METHODS.STORE:
      return 'Ship to Store';
    default:
      return '';
    }
  };

  return (
    <>
      <div className="u__center">
        <TileHead
          title={getTitle(type)}
          icon={getIcon(type)}
        />
        {notSold && (
          <div>Not sold in stores</div>
        )}
        {!notSold && (
          <div className="fulfillment-msg">Not available for this item</div>
        )}
      </div>
    </>
  );
};
NotAvailable.propTypes = {
  itemId: string,
  type: string,
  covidEnabled: bool,
  notSold: bool
};
NotAvailable.defaultProps = {
  type: null,
  itemId: null,
  covidEnabled: false,
  notSold: false
};

export { NotAvailable };
