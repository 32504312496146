import React from 'react';
import { element } from 'prop-types';

import './CheckAvailabilityWrapper.scss';

export const CheckAvailabilityWrapper = ({ children }) => {

  return (
    <div className="fulfillment-check-availability-wrapper">
      {children}
    </div>
  );
};

CheckAvailabilityWrapper.displayName = 'CheckAvailabilityWrapper';

CheckAvailabilityWrapper.propTypes = {
  children: element.isRequired
};

CheckAvailabilityWrapper.defaultProps = {};