import React from 'react';
import {
  string,
  shape,
  bool
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearByStore';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { CHECK_NEARBY_STORES } from '../../constants';

const BOPISOutOfStock = ({
  itemId,
  location,
  isShipToHomeEligible,
  isAppliance,
}) => {
  const { storeName } = useStore();
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Store Pickup"
          icon="pick-up-false"
        />
        <div className="fulfillment__sub-title">
          <div>Unavailable at</div>
          <div>{location.storeName || storeName }</div>
        </div>
      </div>
      {!isAppliance && (
        <TileFooter>
          <CheckNearByStore
            itemId={itemId}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            { CHECK_NEARBY_STORES }
          </CheckNearByStore>
        </TileFooter>
      )}
    </>
  );
};

BOPISOutOfStock.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  location: shape({
    storeName: string,
    distance: string
  }),
};

BOPISOutOfStock.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false,
  location: {}
};

export { BOPISOutOfStock };
