/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable react/no-array-index-key */
import React, {
  useRef, useCallback, useContext, useEffect, useState, useMemo
} from 'react';
import { string, number, bool } from 'prop-types';
import { BrowseSearchRedirector, DynamicComponent } from '@thd-nucleus/app-render';
import {
  client,
  extend,
  QueryProvider,
  QueryContext,
  hoist,
  useLazyDataModel,
  params,
  bool as boolType,
  shape as shapeType,
  string as stringType,
  number as numberType,
  alias,
  arrayOf
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { AisleBay, AisleBayPodsDataProvider, AisleBayPodsContext } from '@thd-olt-component-react/aisle-bay';
import { BackToTop } from '@thd-olt-component-react/back-to-top';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CategoryCardsMobile } from '@thd-olt-component-react/category-cards';
import { CompareTray } from '@thd-olt-component-react/compare';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { EtchWidgets } from '@thd-olt-component-react/etch-widgets';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { HybridContentfulWidgets, useHybridPLP } from '@thd-olt-component-react/hybrid';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { useHelmet } from '@thd-nucleus/thd-helmet';
import { RectShape, TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { Metadata, BrowseSearchMetadata } from '@thd-olt-component-react/metadata';
import { NoResultsFound } from '@thd-olt-component-react/no-results-found';
import { ProductPodPlaceholder } from '@thd-olt-component-react/product-pod';
import { FlooringDrawer } from '@thd-olt-component-react/flooring-picker';
import { BrandCategories } from '@thd-olt-component-react/emt-generated-content';

import {
  ProductPodGroup,
  CustomProductPodDetails
} from '@thd-olt-component-react/product-pod-group';
import {
  ProductResults,
  ResultsApplied,
  ResultsFilterAndSort,
  ResultsFilterAndSortDrawer,
  ResultsHeader,
  ResultsPagination,
  ResultsWrapped,
  ResultsDimensions,
  useProductResults,
  productDefaultResultsDataModel,
  updateQueryParams,
  parseUrl,
  ProductResultsContext
} from '@thd-olt-component-react/product-results';
import { OrderSample, usePLPSamples } from '@thd-olt-component-react/order-sample';
import { ProductTableView } from '@thd-olt-component-react/product-table-view';
import { RelatedSearch, PopularCategories } from '@thd-olt-component-react/related-search';
import { RentalIntentMobile } from '@thd-olt-component-react/rental-intent';
import { useNavigate, useLocation } from '@thd-olt-component-react/router';
import { SponsoredBanner, SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';
import { TextAnExpert } from '@thd-olt-component-react/text-an-expert';
import {
  DiscoveryZonesWrapper,
  PlpRecsRenderPlaceholder,
  PlpRvRenderPlaceholder,
  BuyItAgain
} from '@thd-olt-component-react/thd-recs-containers';
import { TrackOrder } from '@thd-olt-component-react/track-order';
import { VisualCategoryNavigation } from '@thd-olt-component-react/visual-category-navigation';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import {
  EmtGeneratedContentLoad, EmtGeneratedContent, EmtGeneratedContentModel,
  EmtLinksLoad, EmtLinks, EmtLinksModel,
} from './dynamic-imports';
import {
  getBrowseAndSearchItemIds,
  limitProductsToDisplay,
  impProductPodData,
  impPLPRecs,
  imagePreloader, isRefinedPage
} from './browse-and-search-utils';
import '../../styles/browse-search.scss';
import {
  CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER,
  KEY_PRODUCT_FEATURE_POD_CATEGORIES,
  HYDRATION_STANDARD_SCROLL_BUFFER,
  RECS_REQUEST_KEY, CHECK_CAT_STYLE,
  BRAND_PAGE, HYBRID_LAYOUT
} from '../../constants';
import { CustomProductPodMobile } from './CustomProductPodMobile';

const HoistedProductPodGroup = hoist(
  ProductPodGroup, CustomProductPodMobile, CategoryCardsMobile, CustomProductPodDetails
);
export const BrowseAndSearchMobile = (props) => {
  declareContexts([ProductResultsContext, QueryContext, ExperienceContext, AisleBayPodsContext, CustomerContext]);
  const navigate = useNavigate();
  const thdCustomer = useThdCustomer();
  const {
    clientStore,
    cookie,
    isServer,
    deliveryZip: serverDeliveryZip,
    isConsumerApp,
    dataType,
    customer
  } = useContext(ExperienceContext);
  const { search: querystring } = useLocation();
  const {
    pageType,
    prefetchPip,
    productPodHoverDelay,
    pathname,
    customerType,
    instance
  } = props;
  const path = `${pathname}${querystring}`;
  const checkShowProduct = path.includes(CHECK_CAT_STYLE) || false;
  const isSearch = pageType === 'search';
  const isBrowse = pageType === 'browse';
  const prefetchedSkuSet = useRef(new Set());
  const disableSSR = typeof window !== 'undefined' && window.DISABLE_SSR;
  const varnish = !disableSSR && !isSearch;
  const store = useStore({ varnish, online: false });
  const { storeId, storeZip } = store;
  const pageLoadVariables = useRef(null);
  const isNoResultsFoundRef = useRef(false);
  const SERVER_SIDE_PRODUCT_POD_RENDER_COUNT = 12;
  const SERVER_SIDE_PRODUCT_POD_FETCH_COUNT = isSearch ? 24 : 12;
  const CLIENT_SIDE_PRODUCT_POD_COUNT = 24;
  const searchNavPropsRef = useRef({
    pageSize: disableSSR ? CLIENT_SIDE_PRODUCT_POD_COUNT : SERVER_SIDE_PRODUCT_POD_FETCH_COUNT,
    askForSponsored: isSearch,
    deliveryZip: serverDeliveryZip,
  });
  const theme = useTheme(BrowseAndSearchMobile);
  const { hideSponsoredCarousel } = theme.props;
  let THDCustomer = {};
  if (clientStore) {
    let deliveryZipCode;
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
      deliveryZipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    }
    searchNavPropsRef.current = {
      pageSize: CLIENT_SIDE_PRODUCT_POD_COUNT,
      askForSponsored: true,
      deliveryZip: deliveryZipCode?.zipcode,
    };
    THDCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : {};
  }
  const HEADER_OFFSET = 59;
  const isTableViewEnabled = useConfigService('fs-prop:isTableViewEnabled');
  let isTableView = false;
  if (isTableViewEnabled) {
    isTableView = dataType === 'expanded-product-data';
  }
  const isDiscoveryZonesEnabled = useConfigService('fs-prop:isDiscoveryZonesEnabled');
  const isDiscoveryZonesSearchEnabled = useConfigService('fs-prop:isDiscoveryZonesSearchEnabled');
  const sponsoredCarouselConfig = useConfigService('fs-prop:tnt-carouselType');
  const viewPrimaryFilters = useConfigService('fs-prop:isviewPrimaryFiltersEnabled');
  const isFulfillmentRedesignEnabled = useConfigService('fs-prop:isFulfillmentRedesignEnabled');
  const viewVizNavFilters = useConfigService('fs-prop:isViewVizNavFiltersEnabled');
  const filterBrowseRecs = useConfigService('fs:browseFilteredRecs');
  // Combine these flags into a single condition we can check more easily.
  const isDiscoveryZonesEligible = (isBrowse && isDiscoveryZonesEnabled) || (isSearch && isDiscoveryZonesSearchEnabled);
  const showSponsoredCarouselSlot = (sponsoredCarouselConfig === 'standardDZ'
    || sponsoredCarouselConfig === 'longtailDZ');

  const { isClientResolved, setDefaultVariables } = useContext(QueryContext);

  const onStoreChange = ({ output }) => {
    const { storeId: updatedStoreId } = output || {};
    if (!updatedStoreId) return;

    if (window && window.THD_LOCALIZER_AUTO_INIT && window.THD_LOCALIZER_AUTO_INIT.Localizer) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer
        .localize({ storeId: updatedStoreId })
        .then(() => {
          window.location.reload();
        });
    }
  };

  const makeStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (el) {
      const headerClass = ['Header3-sticky', 'Header3__header--slide'];
      el.classList.add(...headerClass);
    }
  };

  const positionRef = useRef({ lastScrollPosition: 0 });

  // eslint-disable-next-line consistent-return
  const handleScroll = useCallback(() => {
    const header = document.querySelector('.Header3');
    if (!header) return null;
    const { lastScrollPosition } = positionRef.current;
    const scrollPosition = window?.pageYOffset || document?.documentElement?.scrollTop;
    if (scrollPosition > lastScrollPosition && scrollPosition > HEADER_OFFSET) {
      header.classList.add('Header3__header--hide');
    } else {
      header.classList.remove('Header3__header--hide');
    }
    positionRef.current.lastScrollPosition = scrollPosition <= 0 ? 0 : scrollPosition;
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-store', onStoreChange);
    window.LIFE_CYCLE_EVENT_BUS.trigger('header.header-positioning-layout', { position: 'sticky' });
    makeStickyHeader();
  }, []);

  const onDimensionsChange = useCallback(({ refinement }) => {
    if (!viewPrimaryFilters) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.click', refinement);
    } else if (refinement?.dimensionName === 'Get It Fast') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements-visualNav.click', refinement);
    }
    navigate(refinement.url);
  });

  const resultsDimensionsRef = useRef(null);
  const onPageChange = useCallback(({ url }) => {
    navigate(url);

    const { offsetTop } = resultsDimensionsRef?.current?.parentElement?.parentElement;
    window.scrollTo(0, offsetTop || 0);
  });

  const onSortChange = useCallback(({
    sortby, sortorder, name, currentPage, page, updatedPath = null
  }) => {
    const newPath = updateQueryParams(updatedPath || path, [
      {
        paramName: 'Nao',
        paramValue: null
      },
      {
        paramName: 'sortorder',
        paramValue: sortorder
      },
      {
        paramName: 'sortby',
        paramValue: sortby
      }
    ]);
    window.LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.click', {
      name,
      currentPage,
      page
    });
    navigate(newPath);
  });

  const cleanPath = path.replace(/^\/render[^/]*/, '');

  const hideOnNoCategory = () => {
    return path && (path.match('/s/') || /Ntt-/.test(path));
  };

  const isEtchVisNavOnPLP = (data) => {
    try {
      const stringifiedPartialTemplates = data?.searchModel?.partialTemplates;
      const etchVisualNavExists = (stringifiedPartialTemplates?.[0] || '').indexOf('visualNavigation') > -1;
      const hasPLPBanner = data?.searchModel?.metadata?.hasPLPBanner;
      const isPLPPage = data?.searchModel?.metadata?.contentType === 'plppage';
      return hasPLPBanner && etchVisualNavExists && isPLPPage;
    } catch (err) {
      return false;
    }
  };

  const {
    keyword,
    lowerbound,
    Nao = 0,
    sortby,
    nValue = '',
    sortorder,
    upperbound,
    catStyle,
    skipSpellCheck
  } = parseUrl({ path: cleanPath });

  // For hybrid-contentful-plp
  const hybridParams = {
    slug: 'N-' + nValue,
    customerType,
    instance,
    storeId,
    skip: isSearch || isRefinedPage(pathname),
    isShowProducts: checkShowProduct
  };
  const { data: hybridData, contentElements, loading: hybridLoading } = useHybridPLP(hybridParams);
  useHelmet('general-merchandise', { hybridData }, imagePreloader, [hybridData]);
  const layouts = hybridData?.layouts;
  const layoutID = layouts?.id; // for impressions
  const isHybridLayout = layoutID && layouts?.content?.__typename === HYBRID_LAYOUT; // for validation
  const isBrandPage = layouts?.content?.pageType === BRAND_PAGE;

  const loadedStore = useRef(store);
  const showKeyProductFeaturePods = KEY_PRODUCT_FEATURE_POD_CATEGORIES.find((value) => nValue.includes(value));
  const timeoutRef = useRef(null);
  const [productPageChunkLoaded, setProductPageChunkLoaded] = useState(false);
  const additionalSearchParams = {};
  const hasSponsoredSorts = !sortby || ['bestmatch', 'topsellers'].indexOf(sortby) > -1;
  if (searchNavPropsRef.current.askForSponsored && hasSponsoredSorts && cookie?.adobeCookie?.MCMID) {
    additionalSearchParams.sponsored = true;
    additionalSearchParams.mcvisId = cookie.adobeCookie.MCMID;
  }
  if (searchNavPropsRef.current.deliveryZip) {
    additionalSearchParams.deliveryZip = searchNavPropsRef.current.deliveryZip;
  }
  const showProducts = /showproducts/i.test(catStyle);
  if (showProducts) {
    additionalSearchParams.plp = true;
  }
  if (skipSpellCheck) {
    additionalSearchParams.skipSpellCheck = skipSpellCheck;
  }
  // only populated for non-cacheable pages (e.g search)
  if (customer?.svocId) {
    additionalSearchParams.svocID = customer?.svocId;
  }
  if (Object.keys(THDCustomer).length !== 0 && THDCustomer.svocID) {
    additionalSearchParams.svocID = THDCustomer.svocID;
  }
  const opts = {
    additionalSearchParams,
    keyword,
    lowerbound,
    nValue,
    pageSize: searchNavPropsRef.current.pageSize,
    storeId,
    start: Nao,
    sortby,
    sortorder,
    upperbound,
    isConsumerApp
  };
  const response = useProductResults(opts);

  const {
    data, error, loading, variables, responseLoading
  } = response;

  const isBuyitagainEnabled = useConfigService('fs-prop:isBuyitagainEnabled');
  const cleanedPath = (pathname || '').replace(/render-\w+\//, '');
  if (!pageLoadVariables.current) {
    pageLoadVariables.current = variables;
    setDefaultVariables(variables);
  }
  if (data && !loading && !response?.responseLoading) {
    loadedStore.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
    };
  }
  const isCategory = (data?.searchModel?.metadata?.contentType
    && data.searchModel.metadata.contentType === 'categorypage');
  const areAppliedRefinementsAvialable = (data?.searchModel?.appliedDimensions
      && data.searchModel.appliedDimensions.length > 0);

  const products = limitProductsToDisplay({
    products: data?.searchModel?.products,
    setLimit: isServer && isSearch,
    limitCount: SERVER_SIDE_PRODUCT_POD_RENDER_COUNT
  });

  const stringifyItemIds = (hookData) => {
    return hookData?.searchModel?.products?.map(({ itemId: sku }) => sku)
      .join('-');
  };

  /*
    Get Sample Products
  */
  const { loading: samplesLoading } = usePLPSamples({
    products,
    productsFinishedLoading: searchNavPropsRef.current.pageSize === CLIENT_SIDE_PRODUCT_POD_COUNT
   && !response.responseLoading,
    storeId,
    storeZip
  });
  // END: Get Sample Products

  const stringifyAppliedRefinements = (hookData) => {
    const appliedDimensions = hookData?.searchModel?.appliedDimensions || [];
    return appliedDimensions.map(({ refinements = [] }) => {
      return refinements.map((refinement) => refinement.refinementKey).join('-');
    }).join('-');
  };

  const [selectedForCompare, setSelectedForCompare] = useState([]);

  const onSelectedForCompare = (id, selected = false) => {
    if (selected) {
      setSelectedForCompare([...selectedForCompare, id]);
    } else {
      setSelectedForCompare(selectedForCompare.filter((item) => item !== id));
    }
  };

  const onCompare = () => {
    const queryparams = selectedForCompare
      .map((item, idx) => `item${idx + 1}=${item}`)
      .join('&');

    navigate(`/compare?${queryparams}`, { state: { bypassExperienceCheck: true } });
  };

  const hasInStoreFilter = (resultsData) => {
    const { searchModel } = resultsData || {};
    const { appliedDimensions } = searchModel || {};
    const getItFast = (appliedDimensions || [])
      .filter((dimension) => (dimension.label || '').toUpperCase() === 'GET IT FAST');
    if (!getItFast.length) {
      return false;
    }
    const storePickUp = getItFast
      .find((dimension) => dimension.refinements
        .find((refinement) => refinement.label === 'Pick Up Today'));
    return !!storePickUp;
  };

  useEffect(() => {
    const itemIds = window.sessionStorage.getItem('compare');
    window.sessionStorage.removeItem('compare');
    if (itemIds) {
      setSelectedForCompare(JSON.parse(itemIds));
    }
  }, []);

  // hybrid analytics
  const contentfulHybridData = {
    isHybridContentful: isHybridLayout,
    hybridPageID: isHybridLayout ? 'N-' + nValue : ''
  };

  const resolved = isClientResolved({ queryName: 'searchModel' });
  useEffect(() => {
    const analyticsSearchModel = {
      ...data?.searchModel || { searchReport: { ...searchNavPropsRef.current, startIndex: Nao } },
      defaultPageSize: searchNavPropsRef.current.pageSize,
      isTableView,
      contentfulHybridData
    };
    if (isBrowse) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('browse.new-products', analyticsSearchModel);
    } else if (isSearch) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('search.new-products', analyticsSearchModel);
    }
  }, [stringifyItemIds(data), stringifyAppliedRefinements(data)]);

  const resultsColProps = '';

  const prefetchPipRef = useRef({
    prefetchPip: alias('product').params({ itemId: stringType() }).shape({
      itemId: stringType()
    }),
    prefetchPipClient: alias('product').params({ itemId: stringType() }).shape({
      itemId: stringType()
    })
  });
  const dataModelCombo = extend({}, prefetchPipRef.current.prefetchPip, prefetchPipRef.current.prefetchPipClient);
  const [productRequestClient, productResponseClient] = useLazyDataModel('prefetchPipClient', {
    fetchPolicy: 'cache-first',
    dataModel: { prefetchPipClient: dataModelCombo },
    skip: true,
    variables: { storeId, dataSource: 'catalog' }
  });

  const fetchProduct = (itemId) => {
    if (prefetchPip === 'both' || prefetchPip === 'clientOnly') {
      productRequestClient({
        variables: {
          itemId,
          dataSource: 'catalog',
          storeId,
          zipCode: storeZip
        }
      });
    }
    prefetchedSkuSet.current.add(itemId);
  };

  const productPageImport = async () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }
    return import(
      /* webpackChunkName: "product-mobile" */
      '../product/ProductMobileContainer'
    );
  };

  const loadProductPageChunk = async () => {
    if (!productPageChunkLoaded) {
      const productPage = await productPageImport();
      prefetchPipRef.current = productPage.ProductDataModel;
      setProductPageChunkLoaded(true);
    }
  };

  useEffect(() => {
    if (prefetchPip) {
      timeoutRef.current = setTimeout(() => {
        loadProductPageChunk();
      }, 5000);
    }
  }, []);

  const onHoverProductPod = (itemId) => {
    if (prefetchPip) {
      loadProductPageChunk();
    }

    if (productPageChunkLoaded && !prefetchedSkuSet.current.has(itemId)) {
      fetchProduct(itemId);
    }
  };

  const mountedFn = ({ mounted }) => {
    if (!store.isLocalized) return false;
    if (response?.responseLoading) return false;
    return mounted;
  };

  const skipFn = ({ skip, queryName, attributes }) => {
    if (queryName !== 'product') return skip;
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (isResolved) return skip;
      return true;
    }
    return skip;
  };

  const isInStoreFilterON = hasInStoreFilter(data);
  const isEtchVisNavInPLP = isEtchVisNavOnPLP(data);
  const storeSkuIds = resolved ? products?.map((product) => product.identifiers?.storeSkuNumber)
    .filter((sku) => !!sku) : [];
  const isPLPSearchPage = (isSearch || isBrowse);
  isNoResultsFoundRef.current = isSearch
    && data?.searchModel?.searchReport?.totalProducts === 0
    && !!keyword;
  const searchKeyword = data?.searchModel?.searchReport?.keyword;
  const maxNumOfPodsToHydrate = 12;

  const productsReduce = (productPods) => {
    let groupOfPods = 0;

    let reduced = productPods?.reduce((hydrator, product) => {
      if (!hydrator[groupOfPods]) {
        hydrator.push([]);
      }
      if (hydrator[groupOfPods].length < maxNumOfPodsToHydrate) {
        hydrator[groupOfPods].push(product);
        if (hydrator[groupOfPods].length === maxNumOfPodsToHydrate) {
          groupOfPods += 1;
        }
      }
      return hydrator;
    }, []);

    const shouldSetPlaceholders = (isSearch && isServer) || !isClientResolved({ queryName: 'searchModel' });

    if (productPods?.length === SERVER_SIDE_PRODUCT_POD_RENDER_COUNT && shouldSetPlaceholders) {
      const placeholderHydrators = (CLIENT_SIDE_PRODUCT_POD_COUNT - SERVER_SIDE_PRODUCT_POD_RENDER_COUNT)
        / maxNumOfPodsToHydrate;
      for (let i = 0; i < placeholderHydrators; i += 1) {
        reduced.push(Array(maxNumOfPodsToHydrate).fill());
      }
    }

    return reduced;
  };

  const originalProducts = useRef(productsReduce(products));
  const [isHydrated, setIsHydrated] = useState(false);
  const hydrators = isHydrated || !originalProducts.current?.length
    ? productsReduce(products)
    : originalProducts.current;

  setDefaultVariables({
    ...variables
  });

  if (isNoResultsFoundRef.current) {
    return (
      <>
        <TrackOrder keyword={keyword} />
        <NoResultsFound
          keyword={keyword}
          requestKey={RECS_REQUEST_KEY}
          searchData={data}
          searchError={error}
          searchLoading={loading}
          storeId={loadedStore.current?.storeId}
          isCategory={isCategory}
          pageType={pageType}
        />
      </>
    );
  }

  const hasDeliveryFilter = (breadCrumbs, filterLabelList) => {
    return (breadCrumbs || []).filter((breadCrumbDetail) => filterLabelList.includes(breadCrumbDetail?.label));
  };

  const applicableFilters = ['Same-Day Delivery', 'Next-Day Delivery'];
  const hasSameDayFilter = !!(hasDeliveryFilter(data?.searchModel?.taxonomy?.breadCrumbs, applicableFilters).length);

  return (
    <>
      <ErrorBoundary name="browse-search-redirector">
        <BrowseSearchRedirector variables={response?.variables} />
      </ErrorBoundary>
      <Row className="isBound browse-search">
        <ErrorBoundary name="browse-and-search-metadata">
          <Metadata>
            <BrowseSearchMetadata
              data={data}
              loading={loading}
              error={error}
              variables={response?.variables}
              seoGeneratedContentVariables={{ navParam: response?.variables.navParam }}
              pathWithQueryParams={path}
              isTableView={isTableView}
            />
          </Metadata>
        </ErrorBoundary>
        <ErrorBoundary>
          <TrackOrder keyword={keyword} />
        </ErrorBoundary>
        <Row className="u--paddingTop u--paddingBottom">
          <ErrorBoundary name="breadcrumbs">
            <Col flatten>
              <div className={isHybridLayout && 'sui-ml-2'}>
                <Breadcrumbs
                  data={data}
                  loading={loading}
                  error={error}
                  hideOnNoCategory={hideOnNoCategory()}
                  useStencilDesign
                />
              </div>
            </Col>
          </ErrorBoundary>
        </Row>
        <Row>
          <ErrorBoundary name="rental-intent">
            <Col flatten>
              <RentalIntentMobile data={data} loading={loading} error={error} keyword={keyword} />
            </Col>
          </ErrorBoundary>
        </Row>
        <Row>
          <ErrorBoundary name="product-results-group">
            <Col flatten>
              <ProductResults data={data} loading={loading} error={error}>
                <ErrorBoundary name="sponsored-banner-nucleus">
                  <Row>
                    <Col>
                      <div className={isHybridLayout && 'sui-ml-2'}>
                        <SponsoredBanner
                          browserId={thdCustomer?.mcvisID || ''}
                          pageContext={{
                            label: 'browse-search',
                            data,
                            keyword,
                            isCategory,
                            isBrowse,
                            isSearch
                          }}
                          avoidPreload={false}
                        />
                      </div>
                    </Col>
                  </Row>
                </ErrorBoundary>
                <Row>
                  <ErrorBoundary name="results-header">
                    <Col flatten>
                      <div className={isHybridLayout && 'sui-ml-2'}>
                        <ResultsHeader
                          resultsRef={resultsDimensionsRef}
                        />
                      </div>
                    </Col>
                  </ErrorBoundary>
                </Row>
                {!isHybridLayout && !isRefinedPage(pathname) && (
                  <Row className="edgewidgets-row-container">
                    <ErrorBoundary name="etch-widgets">
                      <EtchWidgets
                        data={data}
                        loading={loading}
                        error={error}
                        keyword={keyword}
                        navParam={nValue}
                        showFavoritesCount
                        storeId={loadedStore.current?.storeId}
                        exclude={['plpSeo']}
                        showDpdPod
                      />
                    </ErrorBoundary>
                  </Row>
                )}
                {isHybridLayout && !hybridLoading && isBrowse
                  && (
                    <ErrorBoundary name="contentful-widgets">
                      <HybridContentfulWidgets
                        contentElements={contentElements}
                        loading={hybridLoading}
                        isBrowse={isBrowse}
                      />
                    </ErrorBoundary>
                  )}
                {!isEtchVisNavInPLP && viewVizNavFilters && !hybridLoading && !isBrandPage && (
                  <Row>
                    <ErrorBoundary name="visual-nav">
                      <Col className="visual-nav-container">
                        <div className={isHybridLayout && 'sui-ml-2 sui-mr-2'}>
                          <VisualCategoryNavigation
                            data={data}
                            loading={loading}
                            disableSwipeNavigation
                            slider
                            showArrows={false}
                          />
                        </div>
                      </Col>
                    </ErrorBoundary>
                  </Row>
                )}
                {!isCategory && (
                  <>
                    <Row>
                      {!viewPrimaryFilters && (
                        <ErrorBoundary name="results-filter-and-sort">
                          <Col>
                            <ErrorBoundary name="mb-filter-and-sort">
                              <ResultsFilterAndSort
                                onSortChange={onSortChange}
                                onDimensionsChange={onDimensionsChange}
                                stickyOffset={HEADER_OFFSET}
                                enableMultiStore={!isConsumerApp}
                                disableStickyFilter
                                excludePickUpToday
                                isTableView={isTableView}
                                isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                              />
                            </ErrorBoundary>
                          </Col>
                        </ErrorBoundary>
                      )}
                      {viewPrimaryFilters && (
                        <ErrorBoundary name="results-filter-and-sort-drawer">
                          <Col className="primary-filters-container--no-padding-right">
                            <ErrorBoundary name="mb-filter-and-sort-drawer">
                              <ResultsFilterAndSortDrawer
                                onSortChange={onSortChange}
                                onDimensionsChange={onDimensionsChange}
                                stickyOffset={HEADER_OFFSET}
                                enableMultiStore={!isConsumerApp}
                                disableStickyFilter
                                excludePickUpToday
                                isTableView={isTableView}
                                hideViewAll={!viewPrimaryFilters}
                                isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                              />
                            </ErrorBoundary>
                          </Col>
                        </ErrorBoundary>
                      )}
                    </Row>
                    {resolved && hasInStoreFilter(data) && (
                      <ErrorBoundary name="aisle-bay-most-of-these">
                        <AisleBay products={products} searchKeyword={searchKeyword} />
                      </ErrorBoundary>
                    )}
                  </>
                )}
                <ErrorBoundary name="flooring-picker">
                  <FlooringDrawer products={products} />
                </ErrorBoundary>
                {!isCategory && (
                  <Row className="browse-search__left-outer-padding">
                    <ErrorBoundary
                      name="results-dimensions"
                    >
                      <Col
                        className="browse-search__pod-col-no-padding"
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...resultsColProps}
                      >

                        { isBuyitagainEnabled && (
                          <ErrorBoundary>
                            <Row>
                              <BuyItAgain
                                nValue={nValue}
                                schemaName="buyitagain"
                                anchorId={`N-${nValue}`}
                                loading={responseLoading}
                                data={data}
                                error={error}
                                showLoading
                              />
                            </Row>
                          </ErrorBoundary>
                        )}

                        {!viewPrimaryFilters && (
                          <Row>
                            <ErrorBoundary name="results-applied">
                              <Col flatten>
                                <Col sm="9">
                                  <ResultsApplied
                                    onAppliedChange={onDimensionsChange}
                                    isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                  />
                                </Col>
                              </Col>
                            </ErrorBoundary>
                          </Row>
                        )}
                        {viewPrimaryFilters && (
                          <>
                            {areAppliedRefinementsAvialable && (
                              <Row>
                                <ErrorBoundary name="results-applied">
                                  <Col flatten>
                                    <Col sm="9">
                                      <ResultsApplied
                                        onAppliedChange={onDimensionsChange}
                                        hideResultsCount={viewPrimaryFilters}
                                        isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                      />
                                    </Col>
                                  </Col>
                                </ErrorBoundary>
                              </Row>
                            )}
                            <Row>
                              <ErrorBoundary name="results-applied">
                                <Col flatten>
                                  <Col sm="9">
                                    <ResultsApplied
                                      onAppliedChange={onDimensionsChange}
                                      viewPrimaryFilters={viewPrimaryFilters}
                                      isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                    />
                                  </Col>
                                </Col>
                              </ErrorBoundary>
                            </Row>
                          </>
                        )}
                        { isFulfillmentRedesignEnabled && (
                          <Row>
                            <ResultsDimensions
                              onDimensionsChange={onDimensionsChange}
                              showOnlyGetItFast
                              isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                            />
                          </Row>
                        )}
                        <ErrorBoundary name="wrapped-results">
                          <ResultsWrapped>
                            <div className="grid" ref={resultsDimensionsRef}>
                              {!products?.length && loading && isSearch && (
                                <ProductPodPlaceholder podsCount={SERVER_SIDE_PRODUCT_POD_RENDER_COUNT} />
                              )}
                              {isTableView && (
                                <ProductTableView categoryNavParam={nValue} storeId={storeId} />
                              )}
                              {!!hydrators?.length && !isTableView && (
                                <AisleBayPodsDataProvider
                                  storeSkuIds={storeSkuIds}
                                  hasInStoreFilter={hasInStoreFilter(data)}
                                  isSearchPage={isPLPSearchPage}
                                >
                                  <QueryProvider
                                    dataSource="searchNav"
                                    cacheKey="search-product-pods-plp-mw"
                                    defaultVariables={{
                                      storeId: loadedStore.current?.storeId,
                                      skipKPF: !showKeyProductFeaturePods,
                                      skipSubscribeAndSave: true,
                                      skipInstallServices: false,
                                    }}
                                    mounted={mountedFn}
                                    skip={skipFn}
                                  >
                                    {hydrators?.map((group, hydratorIndex) => {
                                      if (!group?.length) return null;
                                      return (
                                        <Hydrator
                                          afterHydrate={() => { setIsHydrated(true); }}
                                          id={`browse-search-pods-${hydratorIndex + 1}`}
                                          className="grid"
                                          scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                                          patch={!hydratorIndex}
                                          key={`hydrator-${hydratorIndex}`}
                                        >
                                          <HoistedProductPodGroup
                                            DetailsPod={CustomProductPodDetails}
                                            keyword={keyword}
                                          >
                                            {group?.map((product, itemIndex, productArray) => {
                                              const { itemId } = product?.identifiers || {};
                                              if (!itemId) {
                                                return (
                                                  <Col
                                                    className="placeholder-product-pod"
                                                    key={`placeholder-${itemIndex}`}
                                                    lg="3"
                                                    md="3"
                                                    sm="3"
                                                    xs="4"
                                                  >
                                                    <RectShape
                                                      style={{
                                                        width: '100%',
                                                        height: '250px',
                                                        marginBottom: '10px'
                                                      }}
                                                      color="#E0E0E0"
                                                    />
                                                    <TextBlock rows={7} color="#E0E0E0" />
                                                  </Col>
                                                );
                                              }
                                              const fallback = '12';
                                              const podPosition = hydratorIndex * maxNumOfPodsToHydrate + itemIndex;
                                              const showContentCard = (
                                                (hydratorIndex * maxNumOfPodsToHydrate) + itemIndex === 11
                                              );

                                              // display discovery zone after 4th
                                              const showDiscoveryZone1 = (
                                                ((hydratorIndex * maxNumOfPodsToHydrate) + itemIndex === 3)
                                              );

                                              // display discoveryzone2/sponsoredContentCarousel after 8th product pod.
                                              const showDiscoveryZone2 = (
                                                ((hydratorIndex * maxNumOfPodsToHydrate) + itemIndex === 7)
                                              );
                                              return (
                                                <ErrorBoundary
                                                  index={itemIndex}
                                                  key={`${itemId}-${itemIndex}`}
                                                  id={`plp-product-pod__${itemIndex}`}
                                                  name={`Product Pod: ${itemIndex}`}
                                                >
                                                  <Col
                                                    lg="3"
                                                    md="3"
                                                    sm="4"
                                                    xs="6"
                                                    fallback={fallback}
                                                    className="browse-search__pod"
                                                  >
                                                    <ImpressionProvider
                                                      key={itemId}
                                                      data={impProductPodData(layoutID)}
                                                    >
                                                      <CustomProductPodMobile
                                                        itemId={itemId}
                                                        storeId={loadedStore.current?.storeId}
                                                        position={podPosition}
                                                        itemRecsIndex={itemIndex}
                                                        onHover={onHoverProductPod}
                                                        hoverDelay={productPodHoverDelay}
                                                        hasInStoreFilter={isInStoreFilterON}
                                                        samplesLoading={samplesLoading}
                                                        impProductPodData={impProductPodData(layoutID)}
                                                        showProjectQuantity
                                                        hasSameDayFilter={hasSameDayFilter}
                                                      />
                                                    </ImpressionProvider>
                                                  </Col>
                                                  {showContentCard
                                                  && (
                                                    <DynamicComponent pageType={pageType}>
                                                      <Hydrator
                                                        id="category-cards-mobile"
                                                        className="u--paddingLeft u--paddingBottom
                                                        category-cards-mobile"
                                                      >
                                                        <ErrorBoundary>
                                                          <CategoryCardsMobile
                                                            data={data}
                                                            error={error}
                                                            loading={loading}
                                                          />
                                                        </ErrorBoundary>
                                                      </Hydrator>
                                                    </DynamicComponent>
                                                  )}

                                                  {isDiscoveryZonesEligible
                                                  && showDiscoveryZone1
                                                  && (
                                                    <Row>
                                                      <DiscoveryZonesWrapper
                                                        data={data}
                                                        loading={loading}
                                                        nValue={nValue}
                                                        schemaName="discoveryZones"
                                                        storeId={loadedStore.current?.storeId}
                                                        zoneId="1"
                                                        errorBoundary
                                                        hydrator={{
                                                          className: 'sui-bg-primary sui-grid sui-ml-3 sui-mb-4'
                                                          + ' sui-w-full',
                                                          delay: 1500,
                                                          scrollBuffer: 250,
                                                          id: 'discovery-zones-1'
                                                        }}
                                                        dynamic={{
                                                          pageType
                                                        }}
                                                      />
                                                    </Row>
                                                  )}

                                                  {isBrowse
                                                && showDiscoveryZone2
                                                && showSponsoredCarouselSlot
                                                    && (
                                                      <Row>
                                                        <SponsoredCarousel
                                                          pageContext={{
                                                            schema: 'discovery_zone',
                                                            data: { itemId },
                                                            isDiscoveryZone: true
                                                          }}
                                                          plaLocation="sponsoredCarouselBrowsePageDiscoveryZone"
                                                          nValue={'N-' + nValue}
                                                          dynamic={{
                                                            pageType: 'browse-search',
                                                          }}
                                                          hydrator={{
                                                            className: 'grid sui-pb-4 sui-pl-2',
                                                            scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                                                          }}
                                                        />
                                                      </Row>
                                                    )}
                                                  {((isBrowse && isDiscoveryZonesEnabled && !showSponsoredCarouselSlot)
                                                  || (isSearch && isDiscoveryZonesSearchEnabled))
                                                  && showDiscoveryZone2
                                                        && (
                                                          <Row>
                                                            <DiscoveryZonesWrapper
                                                              data={data}
                                                              loading={loading}
                                                              nValue={nValue}
                                                              schemaName="discoveryZones"
                                                              storeId={loadedStore.current?.storeId}
                                                              zoneId="2"
                                                              errorBoundary
                                                              hydrator={{
                                                                className: 'sui-bg-primary sui-grid sui-ml-3 sui-mb-4',
                                                                delay: 1500,
                                                                scrollBuffer: 500,
                                                                id: 'discovery-zones-2',
                                                              }}
                                                              dynamic={{
                                                                pageType
                                                              }}
                                                            />
                                                          </Row>
                                                        )}
                                                </ErrorBoundary>
                                              );
                                            })}
                                          </HoistedProductPodGroup>
                                        </Hydrator>
                                      );
                                    })}
                                  </QueryProvider>
                                </AisleBayPodsDataProvider>
                              )}
                            </div>
                          </ResultsWrapped>
                        </ErrorBoundary>
                        <DynamicComponent pageType={pageType}>
                          <Hydrator
                            id="text-an-expert"
                            scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                          >
                            <div className="sui-mr-[-10px]">
                              <ErrorBoundary name="text-an-expert">
                                <TextAnExpert />
                              </ErrorBoundary>
                            </div>
                          </Hydrator>
                        </DynamicComponent>
                        <DynamicComponent pageType={pageType}>
                          <Hydrator
                            id="compare-tray"
                            scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                          >
                            <div className="sui-w-auto sui-mx-[-10px] sui-py-2">
                              <ErrorBoundary name="compare">
                                <div className="sui-w-full sui-p-2 empty:sui-p-0">
                                  <CompareTray
                                    itemIds={selectedForCompare}
                                    onRemove={onSelectedForCompare}
                                    onCompare={onCompare}
                                  />
                                </div>
                              </ErrorBoundary>
                            </div>
                          </Hydrator>
                        </DynamicComponent>
                        <DynamicComponent pageType={pageType}>
                          {!isTableView && (
                            <Row className="u--paddingTop u--paddingBottom">
                              <ErrorBoundary name="pagination">
                                <Col>
                                  <ResultsPagination
                                    pageSize={searchNavPropsRef.current.pageSize}
                                    onPageChange={onPageChange}
                                    path={path}
                                  />
                                </Col>
                              </ErrorBoundary>
                            </Row>
                          )}
                          <Hydrator
                            id="feedback"
                            className="grid"
                            scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                          />
                        </DynamicComponent>
                      </Col>
                    </ErrorBoundary>
                  </Row>
                )}
              </ProductResults>
            </Col>
          </ErrorBoundary>
        </Row>
        {!hybridLoading && !isBrandPage && (
          <PlpRecsRenderPlaceholder
            requestKey={RECS_REQUEST_KEY}
            isBrowse={isBrowse}
            keyword={keyword}
            itemIds={getBrowseAndSearchItemIds(products)}
            impressionData={impPLPRecs()}
            browseNValue={filterBrowseRecs ? nValue : ''}
          />
        )}
        {!hybridLoading && !isBrandPage && !hideSponsoredCarousel && (
          <ErrorBoundary name="sponsored-carousel-nucleus">
            <SponsoredCarousel
              breadCrumbs={data?.searchModel?.taxonomy?.breadCrumbs}
              categorySourceId={data?.searchModel?.metadata?.categoryID}
              pageContext={{
                label: 'browse-search',
                data: {
                  itemIds: getBrowseAndSearchItemIds(products),
                },
                keyword,
                isCategory,
                isBrowse,
                isSearch
              }}
              nValue={nValue}
              showSponsoredCarousel
              dynamic={{
                pageType
              }}
              hydrator={{
                className: 'grid zone-card',
                scrollBuffer: CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER
              }}
            />
          </ErrorBoundary>
        )}
        {!hybridLoading && (
          <PlpRvRenderPlaceholder
            isCategory={isCategory}
            isSearch={isSearch}
          />
        )}
        {isBrowse && (
          <ErrorBoundary name="brand-categories">
            <BrandCategories
              contentId={hybridParams.slug}
              pageName="plp"
            />
          </ErrorBoundary>
        )}
        {isSearch && (
          <DynamicComponent pageType={pageType}>
            <Hydrator
              id="search-emt-section"
              className="grid sui-my-4 sui-mx-2"
              scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
            >
              <Row className="u--paddingTop u--paddingBottom">
                <ErrorBoundary
                  name="related-search"
                >
                  <Col>
                    <RelatedSearch
                      navParam={nValue}
                      searchData={data}
                      searchError={error}
                      searchLoading={loading}
                      storeId={loadedStore.current?.storeId}
                    />
                  </Col>
                </ErrorBoundary>
              </Row>
            </Hydrator>
          </DynamicComponent>
        )}
        {!isSearch && !hybridLoading && (
          <DynamicComponent pageType={pageType}>
            <Hydrator
              id="browse-emt-section"
              className="grid"
              waitFor={[EmtGeneratedContentLoad, EmtLinksLoad]}
              scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
            >
              <Row name="emt-product-category">
                <ErrorBoundary>
                  <Col>
                    <EmtGeneratedContent type="productCategoryContent" nvalue={nValue} />
                  </Col>
                </ErrorBoundary>
              </Row>
              <Row>
                <ErrorBoundary name="etch-seo">
                  <Col>
                    <EtchWidgets
                      data={data}
                      error={error}
                      include={['plpSeo']}
                      loading={loading}
                      navParam={`N-${nValue}`}
                    />
                  </Col>
                </ErrorBoundary>
              </Row>
              <Row>
                <ErrorBoundary name="emt-qanda">
                  <Col>
                    <EmtGeneratedContent nvalue={nValue} />
                  </Col>
                </ErrorBoundary>
              </Row>
              <Row>
                <ErrorBoundary tag={Row} name="emt-related-categories">
                  <Col>
                    <EmtGeneratedContent type="relatedCategories" nvalue={`N-${nValue}`} nopadding />
                  </Col>
                </ErrorBoundary>
              </Row>
              <Row>
                <ErrorBoundary name="related-search">
                  <Col>
                    <RelatedSearch
                      navParam={nValue}
                      searchData={data}
                      searchError={error}
                      searchLoading={loading}
                      storeId={loadedStore.current?.storeId}
                    />
                  </Col>
                </ErrorBoundary>
              </Row>
              <Row>
                <ErrorBoundary name="emt-mmg">
                  <Col>
                    <EmtLinks path={cleanedPath} />
                  </Col>
                </ErrorBoundary>
              </Row>
            </Hydrator>
          </DynamicComponent>
        )}
        <BackToTop />
      </Row>
    </>
  );
};
BrowseAndSearchMobile.displayName = 'BrowseAndSearchPageMobile';

BrowseAndSearchMobile.themeProps = {
  hideSponsoredCarousel: bool
};

BrowseAndSearchMobile.defaultThemeProps = {
  hideSponsoredCarousel: false
};

BrowseAndSearchMobile.propTypes = {
  pageType: string.isRequired,
  prefetchPip: string,
  productPodHoverDelay: number,
  pathname: string.isRequired,
  customerType: string.isRequired,
  instance: string.isRequired
};

BrowseAndSearchMobile.defaultProps = {
  prefetchPip: null,
  productPodHoverDelay: 500
};

const ProductSearchDataModel = {
  searchModel: params({ keyword: stringType(), navParam: stringType() }).shape({
    products: params({ startIndex: numberType() }).arrayOf(CustomProductPodMobile.dataModel.product)
  })
};

BrowseAndSearchMobile.dataModel = extend({
  searchModel: params().shape({
    metadata: shapeType({
      hasPLPBanner: boolType(),
      categoryID: stringType(),
      analytics: shapeType({
        semanticTokens: stringType(),
        dynamicLCA: stringType()
      })
    }),
    products: params().arrayOf(shapeType({
      identifiers: shapeType({
        storeSkuNumber: client(stringType()),
        specialOrderSku: client(stringType())
      }),
      installServices: params({ storeId: stringType(), zipCode: stringType() }).shape({
        scheduleAMeasure: boolType(),
        gccCarpetDesignAndOrderEligible: boolType()
      }),
      // fix datamodel discrepency due to various product-pod versions
      info: shapeType({
        sponsoredMetadata: shapeType({
          sponsoredId: stringType(),
          trackSource: stringType()
        }),
        sponsoredBeacon: shapeType({
          onClickBeacons: arrayOf(stringType()),
          onViewBeacons: arrayOf(stringType())
        })
      }),
    }))
  })
},
ResultsDimensions,
ResultsFilterAndSort,
ResultsFilterAndSortDrawer,
BrowseSearchRedirector,
BrowseSearchMetadata,
Breadcrumbs,
SponsoredBanner,
EtchWidgets,
VisualCategoryNavigation,
AisleBayPodsDataProvider,
AisleBay,
CategoryCardsMobile,
CompareTray,
DiscoveryZonesWrapper,
EmtGeneratedContentModel,
EmtLinksModel,
FlooringDrawer,
RelatedSearch,
PopularCategories,
RentalIntentMobile,
productDefaultResultsDataModel,
TrackOrder,
PlpRecsRenderPlaceholder,
PlpRvRenderPlaceholder,
ProductTableView,
OrderSample,
HybridContentfulWidgets.dataModel,
BuyItAgain,
BrandCategories,
// This must come last.
ProductSearchDataModel
);
