import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  formatDistance,
  getSellableQuantityNearbyStore,
  getNearbyStoreInStock,
  productShape
} from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { InStockIcon } from '../../helpers/icon-utils';
import { isMobile } from '../../../components/helper/utils';

export const InStockNearby = ({
  product,
  onCheckNearbyStoresClick
}) => {
  const { channel } = useContext(ExperienceContext);
  const nearbyStore = getNearbyStoreInStock(product);

  if (!(product && nearbyStore)) {
    return <div className="store__message" />;
  }

  const { storeName, distance } = nearbyStore;

  if (!(distance && storeName)) {
    return <div className="store__message" />;
  }

  const sellableQuantity = getSellableQuantityNearbyStore(product);
  if (!sellableQuantity) {
    return <div className="store__message" />;
  }

  const formattedQty = sellableQuantity > 999 ? '999+' : sellableQuantity;

  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
        nearbyStoreName={storeName}
      />
    );

  return (
    <div className="store__message">
      <div className="store__icon">{InStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Pickup</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__success">{`${formattedQty} nearby`}</span>
          <span className="store__primary">{' at '}</span>
          {storeNameLink}
          <span className="store__primary">{` | ${formatDistance(distance)} mi away`}</span>
        </div>
      </div>
    </div>
  );
};

InStockNearby.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
};

InStockNearby.defaultProps = {
  onCheckNearbyStoresClick: () => {},
};
