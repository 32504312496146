import React from 'react';
import { string, bool } from 'prop-types';
import { Alert } from '@thd-olt-component-react/core-ui';
import { LIVE_GOODS_IN_SEASON, LIVE_GOODS_OUT_OF_SEASON } from '../constants';
import './liveGoods.scss';

const LiveGoods = ({ storeName, seasonStatusEligible }) => {
  return (
    <div className="fulfillment__unavailable">
      <fieldset className="fulfillment__unavailable--fieldset">
        <legend className="fulfillment__unavailable--title">How To Get it</legend>
        <div className="livegoods__wrapper">
          {seasonStatusEligible && (
            <>
              <Alert type="success" inline>
                <span>{LIVE_GOODS_IN_SEASON}</span>
              </Alert>
              <div className="livegoods__content">
                <span>Stop by&nbsp;</span>
                <span className="u__bold">{storeName}</span>
                <span>&nbsp;to see this variety and more in person</span>
              </div>
            </>
          )}
          {!seasonStatusEligible && (
            <>
              <Alert type="warning" inline>
                <span>{LIVE_GOODS_OUT_OF_SEASON}</span>
              </Alert>
              <div className="livegoods__content">
                <span>Visit&nbsp;</span>
                <span className="u__bold">{storeName}</span>
                <span>&nbsp;next season to find this plant and more.</span>
              </div>
            </>
          )}
        </div>
      </fieldset>
    </div>
  );
};

LiveGoods.propTypes = {
  storeName: string,
  seasonStatusEligible: bool,
};
LiveGoods.defaultProps = {
  storeName: null,
  seasonStatusEligible: false
};

export { LiveGoods };
