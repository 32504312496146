import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { getStoreTemplate } from '../helpers/store-helper';
import { dataModel } from '../../components/dataModel';
import { productShape, isGraphQlDataEqual } from '../helpers/pod-fulfillment-utils';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import './fulfillment-pod-store.scss';

export const FulfillmentPodStore = React.memo(({ product, onCheckNearbyStoresClick }) => {
  const { storeId } = useStore();
  const { customer } = useContext(ExperienceContext);
  const StoreComponent =    getStoreTemplate({product, storeId, customer}); //eslint-disable-line

  if (!StoreComponent) { return EmptyStoreMessage; }

  return (
    <StoreComponent
      product={product}
      onCheckNearbyStoresClick={onCheckNearbyStoresClick}
    />
  );
}, isGraphQlDataEqual);

FulfillmentPodStore.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
};

FulfillmentPodStore.defaultProps = {
  onCheckNearbyStoresClick: () => {},
};

FulfillmentPodStore.dataModel = dataModel;
