import React from 'react';

const Unavailable = () => (
  <div>
    Delivery is <span className="u__text--danger">unavailable</span> for this product
  </div>
);

Unavailable.propTypes = {};

export { Unavailable };
