import React, { useEffect } from 'react';
import { string as stringType, shape as shapeType } from 'prop-types';
import {
  params,
  shape,
  string,
  useDataModel,
  customType,
  fragment,
  arrayOf,
} from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { AnchorPill } from './AnchorPill';
import './anchor-links.scss';

const AnchorLinks = ({ componentId, componentClass, livePreviewData }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('anchor-links.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  let linklist;
  if (!livePreviewData) {
    linklist = data?.component?.linkList || [];
  } else {
    linklist = livePreviewData?.linkListCollection?.items;
  }
  return (
    <section className="sui-flex sui-flex-wrap sui-gap-4">
      <Typography variant="h3" weight="bold" component="span">Jump to Section:</Typography>
      <div
        className="sui-flex sui-gap-4 sui-overflow-auto sui-overflow-y-hidden"
        data-contentful-entry-id={componentId}
        data-contentful-field-id="linkList"
      >
        {linklist.map((link, currentPosition) => (
          <AnchorPill
            key={componentId}
            componentId={componentId}
            componentPosition={currentPosition + 1}
            link={link}
          />
        ))}
      </div>
    </section>
  );
};

AnchorLinks.propTypes = {
  componentId: stringType.isRequired,
  componentClass: stringType.isRequired,
  livePreviewData: shapeType({
    internalName: stringType,
    sys: shapeType({
      id: stringType,
    }),
    linkListCollection: shapeType({
      items: arrayOf(
        shapeType({
          id: stringType,
          label: stringType,
          href: stringType,
        })
      ),
    })
  }),
};

AnchorLinks.defaultProps = {
  livePreviewData: null,
};

AnchorLinks.displayName = 'AnchorLinks';

AnchorLinks.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['AnchorLinks']).isRequired(),
  }).shape({
    AnchorLinks: fragment().shape({
      id: string(),
      linkList: arrayOf(
        shape({
          id: string(),
          label: string(),
          href: string(),
        })
      ),
    }),
  }),
};

export { AnchorLinks };
