import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { track } from '../../analytics';

export const Header = ({
  productType,
  itemId,
  onZipCodeChange,
  quantity,
  zipCode,
  value,
  showCheckAvailability,
  setShowCheckAvailability
}) => {

  const setStateAndProcessResponse = (response) => {
    if (response) onZipCodeChange(response);
    setShowCheckAvailability(false);
  };

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const availabilityButton = (
    <button type="button" className="u__default-link fulfillment__header_link" role="link" onClick={handleOnClick}>
      { showCheckAvailability ? 'Cancel' : 'Change' }
    </button>
  );

  const wrapperClasses = classNames({
    fulfillment__header_check_availability: true,
    fulfillment__border: true
  });

  return (
    <>
      <div className="fulfillment__header">
        <div className="fulfillment__header_title">How to Get It</div>
        <span>
          Delivering to: <span className="fulfillment__header_zipcode">{ zipCode }</span> | { availabilityButton }
        </span>
      </div>
      {showCheckAvailability ? (
        <div className={wrapperClasses} data-testid="checkAvailableWrapper">
          <CheckAvailability
            isMajorAppliance={productType === 'MAJOR_APPLIANCE'}
            inputHeaderTitle="Enter delivery Zip Code"
            itemId={itemId}
            price={parseInt((value * quantity).toFixed(2), 10)}
            zipCode={zipCode}
            onSubmitForm={setStateAndProcessResponse}
            quantity={quantity}
          />
        </div>
      )
        : null}
      <br />
    </>
  );
};

Header.propTypes = {
  productType: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  onZipCodeChange: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  zipCode: PropTypes.string.isRequired,
  value: PropTypes.number,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired
};

Header.defaultProps = {
  productType: null,
  quantity: 1,
  value: 0
};