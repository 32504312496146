import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ProductResultsContext } from './ProductResultsContext';
import { StandardDimension } from './Dimensions/StandardDimension';
import { LinkDimension } from './Dimensions/LinkDimension';
import { RatingStarDimension } from './Dimensions/RatingStarDimension';
import { DimensionsPlaceholder, DimensionsPlaceholderMobile } from './Dimensions/DimensionsPlaceholder';
import { VisualDimension } from './Dimensions/VisualDimension';
import { NumericRangeDimension } from './Dimensions/NumericRangeDimension';
import { ColorSwatchDimension } from './Dimensions/ColorSwatchDimension';
import { AvailabilityDimension } from './Dimensions/AvailabilityDimension';
import {
  sortDimensions, getRefinementUrlWithSort, showMoreFilters, getRefinementIndex
} from '../product-results-helpers';

import '../styles/results-dimensions.scss';

const ResultsDimensions = ({
  onDimensionsChange,
  enableMultiStore,
  onDisplay,
  onDisplayChange,
  shiftCategoryDimension,
  showOnlyGetItFast,
  isFulfillmentRedesignEnabled,
}) => {
  const { data, loading } = useContext(ProductResultsContext);
  const { nearByStores, store } = useContext(ExperienceContext);
  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';

  const { taxonomy, dimensions, searchReport, metadata } = data?.searchModel || {};
  if (!dimensions) {
    return !isMobile
      ? <DimensionsPlaceholder numOf={5} isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled} />
      : <DimensionsPlaceholderMobile numOf={3} />;
  }
  if (dimensions.length === 0) return null;

  const { breadCrumbs = [] } = taxonomy || {};

  const sortedDimensions = sortDimensions({ dimensions, searchReport, metadata, shiftCategoryDimension });

  const onRefinementChange = ({ refinement, dimension, onDisplay: displayValue }) => {
    document.body.classList.add('results-dimensions--fade');
    setTimeout(() => {
      if (onDimensionsChange) {
        const refinementUrl = getRefinementUrlWithSort({ dimension, metadata, refinement, searchReport });
        const { label: dimensionName } = dimension;
        const updatedRefinement = {
          ...refinement,
          url: refinementUrl,
          redirectUrl: dimensionName.toUpperCase() === 'CATEGORY' ? refinementUrl : null,
          dimensionName,
          refinementIndex: getRefinementIndex(refinement, dimension)
        };
        onDimensionsChange({ refinement: updatedRefinement, onDisplay: displayValue });
      }
      document.body.classList.remove('results-dimensions--fade');
    }, 0);
  };

  const dimensionsClass = classNames('results-dimensions', {
    'results-dimensions--fade': loading,
    'results-dimensions--mini': isMobile,
  });

  const onMultiStoreSelectionChange = ({ multiStoreIds }) => {
    const storeSelection = [...multiStoreIds].map((selectedStore) => selectedStore?.toString());
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('NEARBY_STORES.update', { nearByStores: storeSelection });
  };

  const multiStoreSelection = nearByStores?.length ? [store?.storeId, ...nearByStores] : [];

  const onDisplayData = {
    onDisplay,
    onDisplayChange,
    isStoreDisplay: data?.searchModel?.metadata?.isStoreDisplay || null,
    itemCount: data?.searchModel?.metadata?.productCount?.inStore
  };

  return (
    <div className={dimensionsClass} data-component="ResultsDimensions">
      {sortedDimensions.map((dimension) => {
        const {
          dimensionId, label, collapse, isVisualDimension, isNumericFilter, isColorSwatch,
        } = dimension;
        const hasFilter = ['BRAND', 'COLOR FAMILY', 'PATTERN TYPE', 'THEME', 'FEATURED KEYWORDS',
          'CUSTOMER SEARCHED KEYWORDS', 'POPULAR KEYWORDS'].indexOf(label.toUpperCase()) > -1;
        const hasFitCompatibility = [
          'DEPTH (EXCLUDING HANDLES) (IN.)',
          'HEIGHT TO TOP OF DOOR HINGE (IN.)',
          'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
          'INSTALLATION DEPTH',
          'REFRIGERATOR FIT WIDTH',
          'TOTAL CAPACITY (CU. FT.)',
          'CAPACITY (CU. FT.) - REFRIGERATORS',
          'REFRIGERATOR CAPACITY (CU. FT.)',
          'DEPTH (INCLUDING HANDLES) (IN.)'
        ].includes(label.toUpperCase());

        if ((showOnlyGetItFast && label.toUpperCase() === 'GET IT FAST') || label.toUpperCase() === 'GET IT FAST') {
          if (isFulfillmentRedesignEnabled) {
            return (
              <AvailabilityDimension
                collapsed={collapse}
                key={dimensionId}
                dimension={dimension}
                onChange={onRefinementChange}
                enableMultiStore={enableMultiStore}
                onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                multiStoreSelection={multiStoreSelection}
                onDisplayData={onDisplayData}
                isMobile={isMobile}
              />
            );
          }
          return (
            <StandardDimension
              collapsed={collapse}
              key={dimensionId}
              dimension={dimension}
              fitCompatibility={hasFitCompatibility}
              onChange={onRefinementChange}
              enableMultiStore={enableMultiStore}
              onMultiStoreSelectionChange={onMultiStoreSelectionChange}
              multiStoreSelection={multiStoreSelection}
              onDisplayData={onDisplayData}
            />
          );
        }

        if (showOnlyGetItFast) {
          return null;
        }

        if (label.toUpperCase() === 'CATEGORY') {
          return (
            <LinkDimension
              collapsed={collapse}
              key={dimensionId}
              dimension={dimension}
              breadCrumbs={breadCrumbs}
              onChange={onRefinementChange}
            />
          );
        }
        if (label.toUpperCase() === 'REVIEW RATING') {
          return (
            <RatingStarDimension
              collapsed={collapse}
              key={dimensionId}
              dimension={dimension}
              onChange={onRefinementChange}
            />
          );
        }

        if (isVisualDimension) {
          return (
            <VisualDimension
              key={dimensionId}
              collapsed={collapse}
              onChange={onRefinementChange}
              dimension={dimension}
            />
          );
        }

        if (isNumericFilter) {
          return (
            <NumericRangeDimension
              collapsed={collapse}
              dimension={dimension}
              enableMultiStore={enableMultiStore}
              filter
              key={dimensionId}
              metadata={metadata}
              multiStoreSelection={multiStoreSelection}
              onChange={onRefinementChange}
              onDisplayData={onDisplayData}
              onMultiStoreSelectionChange={onMultiStoreSelectionChange}
            />
          );
        }

        if (isColorSwatch) {
          return (
            <ColorSwatchDimension
              collapsed={collapse}
              dimension={dimension}
              key={dimensionId}
              onChange={onRefinementChange}
            />
          );
        }

        if (hasFilter) {
          return (
            <StandardDimension
              collapsed={collapse}
              filter
              key={dimensionId}
              fitCompatibility={hasFitCompatibility}
              dimension={dimension}
              onChange={onRefinementChange}
              enableMultiStore={enableMultiStore}
              onMultiStoreSelectionChange={onMultiStoreSelectionChange}
              multiStoreSelection={multiStoreSelection}
              onDisplayData={onDisplayData}
            />
          );
        }
        return (
          <StandardDimension
            collapsed={collapse}
            priceRange={label.toUpperCase() === 'PRICE'}
            key={dimensionId}
            fitCompatibility={hasFitCompatibility}
            dimension={dimension}
            onChange={onRefinementChange}
            enableMultiStore={enableMultiStore}
            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
            multiStoreSelection={multiStoreSelection}
            onDisplayData={onDisplayData}
            isNumericFilter={isNumericFilter}
            metadata={metadata}
          />
        );

      })}
    </div>
  );
};

ResultsDimensions.propTypes = {
  onDimensionsChange: PropTypes.func.isRequired,
  enableMultiStore: PropTypes.bool,
  onDisplay: PropTypes.string,
  onDisplayChange: PropTypes.func,
  shiftCategoryDimension: PropTypes.bool,
  showOnlyGetItFast: PropTypes.bool,
  isFulfillmentRedesignEnabled: PropTypes.bool,
};

ResultsDimensions.defaultProps = {
  enableMultiStore: false,
  onDisplay: 'false',
  onDisplayChange: null,
  shiftCategoryDimension: false,
  showOnlyGetItFast: false,
  isFulfillmentRedesignEnabled: false,
};

ResultsDimensions.displayName = 'Dimensions';

ResultsDimensions.dataModel = extend(StandardDimension);

export { ResultsDimensions };
