import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearByStore';
import { CHECK_NEARBY_STORES } from '../../constants';

const StoreUnavailable = ({
  itemId,
  location,
  isShipToHomeEligible,
  isAppliance
}) => {
  const { storeName } = useStore();
  return (
    <>
      <div>
        <span>This item is </span>
        <span className="u__text--danger">unavailable</span>
        <span>{` at ${location.storeName || storeName}`}</span>
      </div>
      {!isAppliance && (
        <div className="fulfillment__sub-title">
          <CheckNearByStore
            itemId={itemId}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            { CHECK_NEARBY_STORES }
          </CheckNearByStore>
        </div>
      )}
    </>
  );
};

StoreUnavailable.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  location: shape({
    storeName: string,
  })
};

StoreUnavailable.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false,
  location: {}
};

export { StoreUnavailable };
