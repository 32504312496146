import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { QuantityShipping } from './QuantityShipping';
import { AvailableIcon, UnavailableIcon, InformationIcon } from '../../helpers/icon-utils';
import { getTooltipContent } from '../../helpers/tooltip';
import '../../helpers/tooltip.scss';
import { isMobile } from '../../../components/helper/utils';

export const DefaultShipping = ({
  shippingMessageStyle,
  iconType,
  shippingMessage,
  displayQuantity,
  shippingThreshold,
  excludedState
}) => {
  const { channel } = useContext(ExperienceContext);
  const iconSelector = iconType === 'close' ? UnavailableIcon : AvailableIcon;
  const tooltipMsg = 'Looks like we can\'t deliver this product to your area. '
  + 'Try changing your delivery ZIP to an address in a different state.';

  const shippingCss = classNames(shippingMessageStyle + ' shipping__second-line');

  const excludedStateMwLinkCss = (excludedState && isMobile(channel)) ? ' shipping__mw-link' : '';
  const storeLinkCss = classNames('store__link u__default-link store__store-name' + excludedStateMwLinkCss);

  const excludedStateTemplate = () => {
    return (
      <div className={shippingCss}>{'Not available in '}
        <Tooltip
          channel={channel}
          closeButton
          placement="top"
          content={getTooltipContent(tooltipMsg, channel)}
          interactive
          distance={16}
          skidding={-36}
          zIndex={3000000000}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={storeLinkCss}>
            {excludedState}
            {InformationIcon}
          </a>
        </Tooltip>
      </div>
    );
  };

  const deliveryTemplate = () => {
    return displayQuantity > 0
      ? (
        <QuantityShipping
          displayQuantity={displayQuantity}
          shippingMessageStyle={shippingMessageStyle}
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
        />
      ) : <div className={shippingCss}>{shippingMessage}</div>;
  };

  return (
    <div className="shipping__message">
      <div className="shipping__icon">{iconSelector}</div>
      <div className="shipping__text-box">
        <div className="shipping__dark">Delivery</div>
        {excludedState ? excludedStateTemplate() : deliveryTemplate()}
      </div>
    </div>
  );
};

DefaultShipping.propTypes = {
  shippingMessageStyle: PropTypes.string,
  shippingMessage: PropTypes.string,
  displayQuantity: PropTypes.number,
  iconType: PropTypes.string,
  shippingThreshold: PropTypes.number,
  excludedState: PropTypes.string
};

DefaultShipping.defaultProps = {
  shippingMessageStyle: 'shipping__primary',
  iconType: 'close',
  shippingMessage: 'Unavailable',
  displayQuantity: 0,
  shippingThreshold: null,
  excludedState: null
};
