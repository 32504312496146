import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { ExpressDeliveryHowItWorks } from './ExpressDeliveryHowItWorks';
import { getParsedFulfillment, formatInventory } from '../../helper/utils';
import { FULFILLMENT_METHODS } from '../../constants';
import { InventoryWarning } from '../store/InventoryWarning';

const ExpressDeliveryZipCode = ({ zipCode }) => {
  return (
    <>
      <span>to&nbsp;</span>
      <span className="u__bold">{zipCode}</span>
    </>
  );
};

ExpressDeliveryZipCode.propTypes = {
  zipCode: PropTypes.string
};

ExpressDeliveryZipCode.defaultProps = {
  zipCode: null
};

const ExpressDeliveryDetails = (props) => {

  const {
    channel,
    covidEnabled,
    quantity,
    pricing,
    bodfsMinimumThreshold,
    shippingServiceModel,
    zipCode
  } = props;

  let { fulfillment } = props;
  const showMinimumThresholdMessage = (quantity * pricing?.value < bodfsMinimumThreshold);
  fulfillment = getParsedFulfillment(fulfillment, shippingServiceModel);
  const bodfsLocation = (fulfillment?.locations || []).find((location) => location?.type === 'store');
  const inventoryQuantity = bodfsLocation?.inventory?.quantity;
  const inventoryQuantityValue = inventoryQuantity ? formatInventory(inventoryQuantity) : inventoryQuantity;
  return (
    <>
      {covidEnabled && !inventoryQuantity && (
        <>
          <span>Schedule delivery </span>
          {zipCode && (
            <ExpressDeliveryZipCode
              zipCode={zipCode}
            />
          )}
          <span> at checkout.</span>
        </>
      )}
      {covidEnabled && !!inventoryQuantity && (
        <>
          <span className="u__text--success u__bold">{inventoryQuantityValue}</span>
          <span> available for delivery </span>
          {zipCode && (
            <ExpressDeliveryZipCode
              zipCode={zipCode}
            />
          )}
          <span>. Schedule at checkout.</span>
        </>
      )}
      {!covidEnabled && fulfillment.deliveryTimeline && !inventoryQuantity && (
        <span>
          <span>Get it scheduled for delivery </span>
          {zipCode && (
            <ExpressDeliveryZipCode
              zipCode={zipCode}
            />
          )}
          <span> as soon as</span>
          <span className="delivery__timeline u__bold">
            {` ${fulfillment.deliveryTimeline.toLowerCase()}`}
          </span>
        </span>
      )}
      {!covidEnabled && fulfillment.deliveryTimeline && !!inventoryQuantity && (
        <span>
          <span className="u__text--success u__bold">{inventoryQuantityValue}</span>
          <span> ready for delivery </span>
          {zipCode && (
            <ExpressDeliveryZipCode
              zipCode={zipCode}
            />
          )}
          <span>. Schedule at checkout.</span>
        </span>
      )}
      {!covidEnabled && !fulfillment.deliveryTimeline && !inventoryQuantity && (
        <span>Schedule delivery at your convenience.</span>
      )}
      {!covidEnabled && !fulfillment.deliveryTimeline && !!inventoryQuantity && (
        <>
          <span className="u__text--success u__bold">{inventoryQuantityValue}</span>
          <span> available for delivery </span>
          {zipCode && (
            <ExpressDeliveryZipCode
              zipCode={zipCode}
            />
          )}
          <span>. Schedule at checkout.</span>
        </>
      )}
      <div>
        <Tooltip
          channel={channel}
          closeButton
          placement="bottom"
          content={<ExpressDeliveryHowItWorks />}
          interactive
        >
          <span className="delivery__tooltip-text">How It Works</span>
        </Tooltip>
      </div>
      {showMinimumThresholdMessage && (
        <div className="alert-inline alert-inline--warning inventory--warning">
          <span>There&apos;s a ${bodfsMinimumThreshold} minimum purchase for Scheduled Delivery.</span>
        </div>
      )}
      {!!bodfsLocation?.inventory?.quantity && bodfsLocation?.inventory?.quantity < quantity && (
        <InventoryWarning method={FULFILLMENT_METHODS.BODFS} location={bodfsLocation} />
      )}
    </>
  );
};

ExpressDeliveryDetails.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  fulfillment: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-enable react/forbid-prop-types */
  channel: PropTypes.string,
  covidEnabled: PropTypes.bool,
  quantity: PropTypes.number,
  pricing: PropTypes.shape({
    value: PropTypes.number
  }).isRequired,
  bodfsMinimumThreshold: PropTypes.number,
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    isBodfsShipMode: PropTypes.bool,
    isDeliveryTimelineTodayOrTomorrow: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool,
    })
  }),
  zipCode: PropTypes.string,
};
ExpressDeliveryDetails.defaultProps = {
  channel: 'desktop',
  covidEnabled: false,
  quantity: 1,
  bodfsMinimumThreshold: null,
  shippingServiceModel: null,
  zipCode: null,
};

export { ExpressDeliveryDetails };
