import React from 'react';

export const NotLocalized = () => {

  const openLocalizerPopup = (event) => {
    event.preventDefault();
    const myStoreButton = document.querySelector('#myStore .MyStore__button')
      // mw
      || document.querySelector('.MyStore__button.MyStore--inline__button');

    if (myStoreButton) {
      myStoreButton.click();
      if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="store__message">
      {/* eslint-disable-next-line */}
      <a
        onClick={openLocalizerPopup}
        href="#"
        className="u__default-link"
      >
        Set your store
      </a>
      <span className="store__dark">to see local availability</span>
    </div>
  );
};
