import React from 'react';
import { BOSS } from '../partials/store/BOSS';
import { InStock } from '../partials/store/InStock';
import { InStockNearby } from '../partials/store/InStockNearby';
import { LimitedAvailability } from '../partials/store/LimitedAvailability';
import { LimitedAvailabilityNearby } from '../partials/store/LimitedAvailabilityNearby';
import { NotLocalized } from '../partials/store/NotLocalized';
import { NoStoreOption } from '../partials/store/NoStoreOption';
import { LiveGoodsPod } from '../partials/store/LiveGoodsPod';
import { BopisOffMessage } from '../partials/store/BopisOffMessage';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import { dataModel } from '../../components/dataModel';
import {
  getLiveGoodsStock,
  getLiveGoodsStoreName
} from '../../components/helper/utils';
import { FULFILLMENT_METHODS, GCC_COUNTER_TOPS } from '../../components/constants';
import {
  isBOPIS,
  isBOSS,
  isBrowseOnly,
  isBuyInStore,
  isDiscontinued,
  isLimitedStock,
  isInStockYourStore,
  isInStockNearby,
  isLimitedStockNearby,
  isOutOfStockYourStore,
  isAppliance,
  isOutOfStockBoss,
  isOutOfStockOnline,
  getDisplayableInventory,
  getExcludedStateBoss
} from './pod-fulfillment-utils';

export const getStoreTemplate = ({ product, storeId, customer }) => {
  const isGCCCounter = product?.info?.globalCustomConfigurator?.customExperience === GCC_COUNTER_TOPS;
  const liveGoodsStock = getLiveGoodsStock(product?.fulfillment);
  if (!product || isGCCCounter) return () => (<EmptyStoreMessage />);

  if (liveGoodsStock === 0) {
    return () => (
      <LiveGoodsPod
        storeName={getLiveGoodsStoreName(product)}
        seasonStatusEligible={product.fulfillment?.seasonStatusEligible}
      />
    );
  } if (liveGoodsStock > 0) {
    return () => (<InStock product={product} />);
  }

  if (storeId) {
    /* -----Browse Only----- */
    if (isBrowseOnly(product)) {
      /* Browse only, Non purchasable online and store must be purchased in store */
      // @TODO: What is getStoreStatus 400? Test in stage with 304384882.
      // store status 400 means INACTIVE
      // if (getStoreStatus(sku) === 400) {
      //   return NoStoreOption;
      // }
      if (isBOPIS(product)) {
        return () => (<BopisOffMessage product={product} />);
      }
      return () => (<LimitedAvailability product={product} />);
    }

    /* -----BOPIS----- */
    if (isBOPIS(product)) {
      if (isBuyInStore(product) && !isOutOfStockYourStore(product)) {
        return () => (<BopisOffMessage product={product} />);
      }
      if (isLimitedStock(product)) {
        if (isInStockNearby(product)) { return () => (<InStockNearby product={product} />); }
        return () => (<LimitedAvailability product={product} />);
      }
      if (isLimitedStockNearby(product)) { return () => (<LimitedAvailabilityNearby product={product} />); }
      if (isInStockYourStore(product)) { return () => (<InStock product={product} />); }
      if (isInStockNearby(product)) { return () => (<InStockNearby product={product} />); }
    }

    /* -----BOSS----- */
    if (isBOSS(product)) {
      const excludedState = getExcludedStateBoss(product);
      if (excludedState) {
        return () => (
          <NoStoreOption
            storeMessage="Not available in "
            excludedState={excludedState}
            enableOverlayLink
            product={product}
          />
        );
      }
      if (isOutOfStockBoss(product)) {
        return () => (
          <NoStoreOption
            storeMessage="Unavailable"
            enableOverlayLink={false}
            product={product}
          />
        );
      }
      return () => (
        <BOSS
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BOSS)}
        />
      );
    }

    /* -----No BOSS or BOPIS----- */
    // @TODO: What is in stock?
    // if (isInStock(product) || hasZeroStock(product) || isLimitedStock(product)) {
    //   /* Not BOPIS but has qtyForSale */
    //   return <LimitedAvailability localizedStore={localizedStore} channel={channel} />;
    // }
    if (isAppliance(product) || isDiscontinued(product)) {
      return () => (<EmptyStoreMessage />);
    }
    if (isOutOfStockYourStore(product)) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable at "
          enableOverlayLink
          product={product}
        />
      );
    }
    if (isOutOfStockOnline(product)) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable"
          enableOverlayLink={false}
          product={product}
        />
      );
    }
    if (product?.availabilityType?.type === 'Shared') {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable at "
          enableOverlayLink
          product={product}
        />
      );
    }
    /* Not Sold in Stnpmores */
    return () => (
      <NoStoreOption
        storeMessage="Not sold in stores"
        enableOverlayLink={false}
        product={product}
      />
    );
  }
  return () => (<NotLocalized />);
};

getStoreTemplate.dataModel = dataModel;
