import React from 'react';
import PropTypes from 'prop-types';
import { ShippingThreshold } from './ShippingThreshold';
import { formatDate, getParsedFulfillment, showTodayOrTomorrow } from '../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const DefaultShipping = (props) => {
  const {
    shippingServiceModel,
    backordered
  } = props;
  let { fulfillment } = props;
  fulfillment = getParsedFulfillment(fulfillment, shippingServiceModel);
  const deliveryStartDate = fulfillment?.deliveryDates?.startDate;
  const deliveryEndDate = fulfillment?.deliveryDates?.endDate;

  const startDate = formatDate(deliveryStartDate);
  const endDate = formatDate(deliveryEndDate);
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Ship to Home"
          icon="delivery-true"
        />
        <div className="fulfillment__content-nonselected">
          {backordered && (
            <div className="fulfillment__sub-title">
              <div>Backordered</div>
              {startDate && startDate === endDate && (
                <div className="u__bold">
                  {`${showTodayOrTomorrow(deliveryEndDate)}, ${endDate}`}
                </div>
              )}
              {startDate && startDate !== endDate && (
                <div>
                  <span className="u__bold">
                    {startDate}
                  </span>
                  {endDate && (
                    <span className="u__bold"> - {endDate}</span>
                  )}
                </div>
              )}
            </div>
          )}
          {!backordered && startDate && (
            <div className="fulfillment__sub-title">
              {startDate === endDate && (
                <>
                  <div>Get it by</div>
                  <div className="u__bold">
                    {`${showTodayOrTomorrow(deliveryEndDate)}, ${endDate}`}
                  </div>
                </>
              )}
              {startDate !== endDate && (
                <>
                  <div>Expect it </div>
                  <div>
                    <span className="u__bold">
                      {startDate}
                    </span>
                    {endDate && (
                      <span className="u__bold"> - {endDate}</span>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <TileFooter className="u__bold">
        <ShippingThreshold fulfillment={fulfillment} />
      </TileFooter>
    </>
  );
};

DefaultShipping.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  fulfillment: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-enable react/forbid-prop-types */
  backordered: PropTypes.bool,
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool,
    })
  })
};

DefaultShipping.defaultProps = {
  shippingServiceModel: null,
  backordered: false,
};

export { DefaultShipping };
