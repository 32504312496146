import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import {
  Card,
  CardMedia,
} from '@one-thd/sui-atomic-components';
import { useImpression, ImpressionProvider } from '@thd-olt-component-react/impression';
import {
  params, shape, arrayOf, string as stringType, useDataModel, customType, fragment
} from '@thd-nucleus/data-sources';
import { RichText } from './RichText';
import './newspaper-module.scss';

const NewspaperModule = ({
  componentId, componentClass, lazyLoad
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('newspaper-module.ready'); }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'NewspaperModule',
      component: 'NewspaperModule',
      position: 1,
      type: 'content',
    },
  });

  let {
    description,
    link,
    altText,
    damImageAlignmentBehavior,
    damImageWidthBehavior,
    descriptionAlignment,
  } = data?.component || {};

  let richText;

  const isCentered = damImageAlignmentBehavior === 'Centered';

  try {
    richText = JSON.parse(description);
    // eslint-disable-next-line no-empty
  } catch (error) {}

  const isVertical = descriptionAlignment === 'Below image' || descriptionAlignment === 'Above image' || !richText;
  const imageFirst = descriptionAlignment === 'Below image' || descriptionAlignment === 'To the right of the image';

  let imageWidthClasses;
  let textWidthClasses;
  let textPaddingClasses;

  switch (damImageWidthBehavior) {
  case 'Skinny':
    imageWidthClasses = `sui-col-span-12 ${isVertical
      ? 'sm:sui-col-span-8 md:sui-col-span-6' : 'sm:sui-col-span-6 md:sui-col-span-4'}`;
    textWidthClasses = `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 md:sui-col-span-8'}`;
    break;
  case 'Wide':
    imageWidthClasses = `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 lg:sui-col-span-8'}`;
    textWidthClasses = `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 lg:sui-col-span-4'}`;
    break;
  default:
    imageWidthClasses = `sui-col-span-12 ${isVertical ? 'sm:sui-col-span-8' : 'sm:sui-col-span-6'}`;
    textWidthClasses = `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6'}`;
  }

  switch (descriptionAlignment) {
  case 'Below image':
    textPaddingClasses = 'sui-pt-4 md:sui-pt-6 xl:sui-pt-8';
    break;
  case 'Above image':
    textPaddingClasses = 'sui-pb-4 md:sui-pb-6 xl:sui-pb-8';
    break;
  case 'To the right of the image':
    textPaddingClasses = 'sui-pt-4 sm:sui-pt-0 sm:sui-pl-4 md:sui-pl-6 xl:sui-pl-8';
    break;
  case 'To the left of the image':
    textPaddingClasses = 'sui-pb-4 sm:sui-pb-0 sm:sui-pr-4 md:sui-pr-6 xl:sui-pr-8';
    break;
  default:
    textPaddingClasses = '';
  }

  const getImageUrl = (breakpoint) => {
    const imageData = data?.component?.[`${breakpoint}Image`];
    const downloadedImage = imageData?.damDownloadedContent?.url;
    const selectedImage = imageData?.damContentSelector?.assetData?.[0]?.selectedImageUrl;
    let url = selectedImage || downloadedImage;
    if (!url) {
      return null;
    }
    return url;
  };

  let desktopImage = getImageUrl('desktop');
  let tabletImage = getImageUrl('tablet');
  let mobileImage = getImageUrl('mobile');

  // fallback logic:
  // - desktop image is always required
  // - if there is desktop image, tablet image, and mobile image, each breakpoint has unique image
  // - if there is desktop image and tablet image, mobile will use tablet image
  // - if there is desktop image and mobile image, tablet will use desktop image
  // - if therer is only desktop image, all 3 breakpoints will use desktop

  let desktopDisplay;
  let tabletDisplay;
  let mobileDisplay;

  if (tabletImage && mobileImage) {
    desktopDisplay = 'sui-hidden xl:sui-flex xl:sui-grid';
    tabletDisplay = 'sui-hidden md:sui-flex md:sui-grid xl:sui-hidden';
    mobileDisplay = 'sui-flex sui-grid md:sui-hidden';
  } else if (tabletImage) {
    desktopDisplay = 'sui-hidden xl:sui-flex xl:sui-grid';
    tabletDisplay = 'sui-flex sui-grid xl:sui-hidden';
    mobileDisplay = null;
  } else if (mobileImage) {
    desktopDisplay = 'sui-hidden md:sui-flex md:sui-grid';
    tabletDisplay = null;
    mobileDisplay = 'sui-flex sui-grid md:sui-hidden';
  } else {
    desktopDisplay = 'sui-flex sui-grid';
    tabletDisplay = null;
    mobileDisplay = null;
  }

  let leftSpaceClassesDesk = 'sui-col-start-0';
  let leftSpaceClassesTab = 'sui-col-start-0';
  let leftSpaceClassesMob = 'sui-col-start-0';

  if (isVertical && (damImageWidthBehavior === 'Skinny')) {
    if ((mobileDisplay !== null) && (tabletDisplay !== null)) {
      // desktop, tablet and mobile
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 xl:sui-col-start-4' : 'sui-col-start-0';
      leftSpaceClassesTab = isCentered ? 'sui-col-start-0 md:sui-col-start-4 xl:sui-col-start-0' : 'sui-col-start-0';
      leftSpaceClassesMob = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-0' : 'sui-col-start-0';
    } else if (tabletDisplay !== null) {
      // desktop and tablet, mobile breakpoint uses tablet image
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 xl:sui-col-start-4' : 'sui-col-start-0';
      leftSpaceClassesTab = isCentered
        ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-4 xl:sui-col-start-0' : 'sui-col-start-0';
    } else if (mobileDisplay !== null) {
      // desktop and mobile, tablet breakpoint uses desktop image
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 md:sui-col-start-4' : 'sui-col-start-0';
      leftSpaceClassesMob = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-0' : 'sui-col-start-0';
    } else {
      // desktop only
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-4' : 'sui-col-start-0';
    }
  } else if (isVertical && (damImageWidthBehavior === 'Normal')) {
    if ((mobileDisplay !== null) && (tabletDisplay !== null)) {
      // desktop, tablet and mobile
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 xl:sui-col-start-3' : 'sui-col-start-0';
      leftSpaceClassesTab = isCentered ? 'sui-col-start-0 md:sui-col-start-3 xl:sui-col-start-0' : 'sui-col-start-0';
      leftSpaceClassesMob = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-0' : 'sui-col-start-0';
    } else if (tabletDisplay !== null) {
      // desktop and tablet, mobile breakpoint uses tablet image
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 xl:sui-col-start-3' : 'sui-col-start-0';
      leftSpaceClassesTab = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 xl:sui-col-start-0' : 'sui-col-start-0';
    } else if (mobileDisplay !== null) {
      // desktop and mobile, tablet breakpoint uses desktop image
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 md:sui-col-start-3' : 'sui-col-start-0';
      leftSpaceClassesMob = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-0' : 'sui-col-start-0';
    } else {
      // desktop only
      leftSpaceClassesDesk = isCentered ? 'sui-col-start-0 sm:sui-col-start-3 md:sui-col-start-3' : 'sui-col-start-0';
    }
  }

  return (
    <ImpressionProvider
      data={{
        id: componentId,
        name: 'NewspaperModule',
        component: 'NewspaperModule',
        type: 'content'
      }}
    >
      <div
        className="sui-grid sui-grid-cols-12 newspaper-module"
        data-component="NewspaperModule"
        ref={ref}
        /* eslint-disable-next-line react/no-unknown-property */
        clickid={clickID}
      >
        {richText && !imageFirst
          && (
            <div data-testid="text-padding-test" className={`${textWidthClasses} ${textPaddingClasses}`}>
              <RichText content={richText} />
            </div>
          )}
        <div
          data-testid="image-width-test"
          /* eslint-disable-next-line max-len */
          className={`sui-grid ${leftSpaceClassesDesk} ${imageWidthClasses} ${desktopDisplay}`}
        >
          <Card
            PaperProps={{ variant: 'shadow', shadow: 'none' }}
            disablePadding
            href={link}
          >
            <CardMedia
              src={desktopImage}
              alt={altText}
              loading={lazyLoad ? 'lazy' : ''}
            />
          </Card>
        </div>
        {tabletDisplay
          && (
            <div className={`sui-grid ${leftSpaceClassesTab} ${imageWidthClasses} ${tabletDisplay}`}>
              <Card
                PaperProps={{ variant: 'shadow', shadow: 'none' }}
                disablePadding
                href={link}
              >
                <CardMedia
                  src={tabletImage}
                  alt={altText}
                  loading={lazyLoad ? 'lazy' : ''}
                />
              </Card>
            </div>
          )}
        {mobileDisplay
          && (
            <div className={`sui-grid ${leftSpaceClassesMob} ${imageWidthClasses} ${mobileDisplay}`}>
              <Card
                PaperProps={{ variant: 'shadow', shadow: 'none' }}
                disablePadding
                href={link}
              >
                <CardMedia
                  src={mobileImage}
                  alt={altText}
                  loading={lazyLoad ? 'lazy' : ''}
                />
              </Card>
            </div>
          )}
        {richText && imageFirst
          && (
            <div data-testid="text-padding-test" className={`${textWidthClasses} ${textPaddingClasses}`}>
              <RichText content={richText} />
            </div>
          )}
      </div>
    </ImpressionProvider>
  );
};

NewspaperModule.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  lazyLoad: bool
};

NewspaperModule.defaultProps = {
  lazyLoad: false
};

NewspaperModule.displayName = 'NewspaperModule';

NewspaperModule.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Newspaper']).isRequired(),
  }).shape({
    Newspaper: fragment().shape({
      id: stringType(),
      altText: stringType(),
      link: stringType(),
      description: stringType(),
      damImageAlignmentBehavior: stringType(),
      damImageWidthBehavior: stringType(),
      descriptionAlignment: stringType(),
      desktopImage: shape({
        damDownloadedContent: shape({
          url: stringType()
        }),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageUrl: stringType(),
            })
          ),
        }),
      }),
      tabletImage: shape({
        damDownloadedContent: shape({
          url: stringType()
        }),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageUrl: stringType(),
            })
          ),
        }),
      }),
      mobileImage: shape({
        damDownloadedContent: shape({
          url: stringType()
        }),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageUrl: stringType(),
            })
          ),
        }),
      }),
    })
  })
};

export { NewspaperModule };
