import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const FulfillmentPod = (props) => {
  const {
    children,
    className
  } = props;

  return (
    <div className={classNames('grid', className)}>
      {children}
    </div>
  );
};

FulfillmentPod.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

FulfillmentPod.defaultProps = {
  className: null,
  children: null
};
