import React, { useEffect } from 'react';
import { string, shape, bool } from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { useDataModel } from '@thd-nucleus/data-sources';
import styles from '../styles/rental-intent.module.scss';
import { RentalIntentNoResults } from './RentalIntentNoResults/RentalIntentNoResults';
import { RentalIntentPodMobile } from './RentalIntentPod/RentalIntentPodMobile';
import { dataModel } from '../dataModel';

const RentalIntentMobile = (props) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('rental-intent.ready'); }, []);

  const {
    keyword
  } = props;

  // eslint-disable-next-line react/destructuring-assignment
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined';

  const skipSearch = isControlledData || !keyword;

  const searchResponse = useDataModel('searchModel', {
    variables: {
      keyword
    },
    skip: skipSearch
  });

  let data;

  // eslint-disable-next-line react/destructuring-assignment
  if (typeof props.data !== 'undefined') {
    ({ data } = props);
  } else {
    ({ data } = searchResponse);
  }

  const { intents, universalSearchArray, productTypes } = data?.searchModel?.orangeGraph || {};
  const { correctedKeyword } = data?.searchModel?.searchReport || '';

  const title = correctedKeyword || keyword;

  if (!intents?.includes('rental')) return null;

  if (!universalSearchArray || universalSearchArray.length === 0) return null;

  const { pods } = universalSearchArray[0];
  const rentalPods = pods
    .filter((pod) => pod?.recordType?.toUpperCase()?.includes('RENTAL'));

  if (rentalPods.length === 0) return <RentalIntentNoResults productTypes={productTypes} />;

  return (
    <Row className={classNames(styles['rental-intent'])}>
      <Col fallback="12" nopadding>
        <div className={classNames(styles['rental-intent__header'])}>
          <div className={classNames(styles['rental-intent__header-title'])}>
            <span className={classNames(styles['rental-intent__header-text'])}>{title}</span>
          </div>
        </div>
        <div className={classNames(styles['rental-intent-pods'])}>
          {rentalPods.map((pod, index) => (
            <RentalIntentPodMobile
              key={`${'rental-intent-pod-' + index}`}
              index={index}
              title={pod.title}
              description={pod.description}
              link={pod.link}
              imageUrl={pod.imageUrl}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

RentalIntentMobile.displayName = 'RentalIntentMobile';

RentalIntentMobile.propTypes = {
  keyword: string,
  data: shape({}),
  loading: bool,
};

RentalIntentMobile.defaultProps = {
  keyword: '',
  data: undefined,
  loading: undefined
};

RentalIntentMobile.dataModel = dataModel;

export { RentalIntentMobile };
