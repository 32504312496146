import React from 'react';
import PropTypes from 'prop-types';
import { RectShape, TextRow } from '@thd-olt-component-react/loading-placeholder';
import { Col, Row } from '@thd-olt-component-react/grid';
import '../../styles/dimension.scss';

const DimensionPlaceholder = () => {
  return (
    <div className="dimension">
      <Row>
        <RectShape style={{ width: '55px', height: '20px', margin: '5px' }} color="#E0E0E0" />
        {['70', '70', '70'].map((col, index) => {
          return (
            <Row key={`filter-fast-${index}`} style={{ display: 'flex', width: '100%' }}>
              <RectShape style={{ width: '23px', height: '23px', margin: '5px' }} color="#E0E0E0" />
              <TextRow style={{ width: `${col}%` }} color="#E0E0E0" />
            </Row>
          );
        })}
      </Row>
    </div>
  );
};

const DimensionsPlaceholder = ({ numOf, isFulfillmentRedesignEnabled }) => {
  return (
    <div className="results-dimensions" data-component="DimensionsPlaceholder">
      {isFulfillmentRedesignEnabled
          && (
            <div className="results-dimensions__placeholder--desktop-wrapper">
              <RectShape style={{ width: '70px', height: '20px', margin: '5px' }} color="#E0E0E0" />
              {[...Array(3)].map((n, i) => {
                return <RectShape style={{ width: '200px', height: '116px', margin: '5px' }} color="#E0E0E0" />;
              })}
            </div>
          )}
      {[...Array(numOf)].map((n, i) => <DimensionPlaceholder key={i} />)}
    </div>
  );
};

const DimensionsPlaceholderMobile = ({ numOf }) => {
  return (
    <Col className="results-dimensions__placeholder--wrapper">
      <Row>
        <RectShape style={{ width: '70px', height: '20px', margin: '5px' }} color="#E0E0E0" />
      </Row>
      <Row>
        <div className="results-dimensions__placeholder--mini" data-component="DimensionsPlaceholderMobile">
          {[...Array(numOf)].map((n, i) => {
            return <RectShape style={{ width: '115px', height: '100px', margin: '5px' }} color="#E0E0E0" />;
          })}
        </div>
      </Row>
    </Col>
  );
};

DimensionsPlaceholder.propTypes = {
  isFulfillmentRedesignEnabled: PropTypes.bool,
  numOf: PropTypes.number
};

DimensionsPlaceholder.defaultProps = {
  isFulfillmentRedesignEnabled: false,
  numOf: 3
};

DimensionsPlaceholderMobile.propTypes = {
  numOf: PropTypes.number
};

DimensionsPlaceholderMobile.defaultProps = {
  numOf: 3
};

DimensionsPlaceholder.displayName = 'DimensionsPlaceholder';
DimensionsPlaceholderMobile.displayName = 'DimensionsPlaceholderMobile';

export { DimensionsPlaceholder, DimensionsPlaceholderMobile };