import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import {
  getSellableQuantityYourStore,
  productShape
} from '../../helpers/pod-fulfillment-utils';
import { InStockIcon } from '../../helpers/icon-utils';
import { isMobile } from '../../../components/helper/utils';

export const InStock = ({
  product,
  onCheckNearbyStoresClick
}) => {
  const { channel } = useContext(ExperienceContext);
  const { storeName } = useStore();

  if (!(product && storeName)) {
    return <div className="store__message" />;
  }

  const sellableQuantity = getSellableQuantityYourStore(product);
  if (!sellableQuantity) {
    return <div className="store__message" />;
  }

  const formattedQty = sellableQuantity > 999 ? '999+' : sellableQuantity;
  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : <CheckNearByStore product={product} onCheckNearbyStoresClick={onCheckNearbyStoresClick} />;

  return (
    <div className="store__message">
      <div className="store__icon">{InStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Pickup</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__success">{`${formattedQty} in stock`}</span>
          <span className="store__primary">{' at '}</span>
          {storeNameLink}
        </div>
      </div>
    </div>
  );
};

InStock.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
};

InStock.defaultProps = {
  onCheckNearbyStoresClick: () => {},
};
