import React from 'react';
import { TileHead } from '../TileHead';

const BOSSOutOfStock = () => {
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Ship to Store"
          icon="pick-up-true"
        />
        <div className="fulfillment__sub-title">
          Out of stock
        </div>
      </div>
    </>
  );
};

BOSSOutOfStock.propTypes = {
};

BOSSOutOfStock.defaultProps = {
};

export { BOSSOutOfStock };
