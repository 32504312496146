import React from 'react';
import classNames from 'classnames/bind';
import {
  oneOfType, arrayOf, node,
  bool, string, number
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { useIsSsr } from '../../hooks/useIsSsr';
import styles from './cap-card-section.scss';

const cx = classNames.bind(styles);

const CapabilityCardSection = ({
  anchorId,
  children,
  isCarousel,
  isAutoplay,
  title,
  slidesXs,
  slidesSm,
  slidesMd,
  slidesLg,
  slidesXl,
  spaceBetweenXs,
  spaceBetweenSm,
  spaceBetweenMd,
  spaceBetweenLg,
  spaceBetweenXl,
}) => {

  const isSsr = useIsSsr();

  const screenWidth = isSsr ? null : window.innerWidth;
  const isMobile = screenWidth <= '767';

  const contents = [];
  if (isCarousel || (isMobile && children?.length >= 4)) {
    for (let i = 0; i < children?.length; i += 1) {
      contents.push(
        <div
          key={`item-${i}`}
          className={cx('content-section__carousel', 'sui-w-full', 'sui-bg-primary', 'sui-flex', 'sui-flex-col')}
        >
          {children[i]}
        </div>
      );
    }
  }

  // TODO: The below line is a temporary workaround for PromoVisualNavigation
  const isPromoVisNav = children[0]?.props?.componentClass === 'PromoVisualNavigation';
  const sectionTitle = (
    <>
      {title && !isPromoVisNav && (
        <div className={cx('sui-pb-4')}>
          <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>{title}</Typography>
        </div>
      )}
    </>
  );

  if (contents.length > 0) {
    return (
      <section id={anchorId}>
        {sectionTitle}
        <Carousel
          autoplay={isAutoplay}
          peek={false}
          slidesPerGroup={slidesXs}
          slidesPerView={slidesXs}
          spaceBetween={spaceBetweenXs}
          breakpoints={{
            sm: {
              slidesPerView: slidesSm || 1,
              slidesPerGroup: slidesSm || 1,
              spaceBetween: spaceBetweenSm,
            },
            md: {
              slidesPerView: slidesMd || 1,
              slidesPerGroup: slidesMd || 1,
              spaceBetween: spaceBetweenMd,
            },
            lg: {
              slidesPerView: slidesLg || 1,
              slidesPerGroup: slidesLg || 1,
              spaceBetween: spaceBetweenLg,
            },
            xl: {
              slidesPerView: slidesXl || 1,
              slidesPerGroup: slidesXl || 1,
              spaceBetween: spaceBetweenXl,
            },
          }}
        >
          {contents}
        </Carousel>
      </section>
    );
  }

  const contentCols = () => {
    if (children?.length >= 4 && !isMobile) {
      return 'content-section__large-container';
    }
    return 'content-section__container';
  };

  return (
    <section id={anchorId}>
      {sectionTitle}
      <div className={cx('sui-grid', 'sui-gap-4', `${contentCols()}`)}>
        {children}
      </div>
    </section>
  );
};

CapabilityCardSection.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  anchorId: string,
  isCarousel: bool,
  spaceBetweenXs: number,
  spaceBetweenSm: number,
  spaceBetweenMd: number,
  spaceBetweenLg: number,
  spaceBetweenXl: number,
  isAutoplay: bool,
  title: string,
  slidesXs: number,
  slidesSm: number,
  slidesMd: number,
  slidesLg: number,
  slidesXl: number,
};

CapabilityCardSection.defaultProps = {
  children: null,
  isCarousel: false,
  anchorId: null,
  spaceBetweenXs: 0,
  spaceBetweenSm: 16,
  spaceBetweenMd: 16,
  spaceBetweenLg: 16,
  spaceBetweenXl: 16,
  isAutoplay: false,
  title: null,
  slidesXs: 1,
  slidesSm: null,
  slidesMd: null,
  slidesLg: null,
  slidesXl: null
};

export { CapabilityCardSection };
