/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Rating } from '@thd-olt-component-react/rating';

const RatingStarDimension = (props) => {
  const {
    collapsed,
    dimension,
    onChange
  } = props;

  const {
    label,
    refinements
  } = dimension;

  const selected = (refinements || []).find((ref) => ref.selected) || {};
  const selectedRating = selected.label ? selected.label.substr(0, 1) : '';
  const selectedValue = Number(selectedRating) || null;

  const [hoverValue, setHoverValue] = useState('Please choose a rating');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const wrapperClassName = classNames({
    'dimension--collapsed': isCollapsed
  });

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  const handleCollapse = (event) => {
    event.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  const handleRatingClick = (value) => {
    const refinement = refinements.filter((ref) => (ref.label.substr(0, 1) === `${value}`))[0];
    if (refinement) onChange({ dimension, refinement });
  };

  const handleRatingHover = (hoveredValue) => {
    if (hoveredValue) {
      const hoverVal = hoveredValue === 5
        ? '5 stars'
        : `${hoveredValue} stars and up`;

      setHoverValue(hoverVal);
    } else {
      setHoverValue('Please choose a rating');
    }
  };

  return (
    <div className="dimension">
      <Row>
        <Col fallback="9">
          <a href="#" onClick={handleCollapse}>
            <h2 className="dimension__label" data-group={label}>{label}</h2>
          </a>
        </Col>
        <Col fallback="3" className="dimension__caret--align">
          <a href="#" onClick={handleCollapse}>
            <img
              className={isCollapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
              alt="caret-icon"
              src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
              height="15"
              width="9"
            />
          </a>
        </Col>
      </Row>
      <Row className={wrapperClassName}>
        <Row className="dimension__item dimension__rating">
          <Rating
            interactive
            orange
            className="dimension__filter__rating"
            onClick={handleRatingClick}
            onHover={handleRatingHover}
            value={selectedValue}
          />
        </Row>
        <Row className="dimension__item dimension__rating">
          { refinements && refinements.map((refinement, refinementIndex) => (
            <a
              key={'rating_review_' + refinementIndex}
              className={classNames('refinement__hidden')}
              href={refinement.url}
            >
              {refinement.label}
            </a>
          ))}
          <p>{hoverValue}</p>
        </Row>
      </Row>
    </div>
  );
};

RatingStarDimension.propTypes = {
  collapsed: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

RatingStarDimension.defaultProps = {
  collapsed: false
};

RatingStarDimension.displayName = 'RatingStarDimension';

export { RatingStarDimension };
