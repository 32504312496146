import React from 'react';
import {
  string,
  shape,
  arrayOf,
  number,
  bool
} from 'prop-types';
import { InventoryWarning } from './InventoryWarning';
import { CheckNearByStore } from './CheckNearByStore';
import { FULFILLMENT_METHODS, CHECK_NEARBY_STORES } from '../../constants';

const InStockNearByStoreDetails = ({
  itemId,
  fulfillment,
  quantity,
  isAppliance,
  covidEnabled,
  isShipToHomeEligible,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores
}) => {
  const nearByStoreLocation = (fulfillment.locations || []).find((location) => !location.isAnchor);
  const localStoreLocation = (fulfillment.locations || []).find((location) => location.isAnchor);
  const isBopisThresholdStore = bopisMinimumThresholdStores?.split(',').indexOf(nearByStoreLocation?.locationId) !== -1;
  const showMinimumThresholdMessage = (quantity * pricing?.value) < bopisMinimumThreshold;
  return (
    <>
      {nearByStoreLocation?.inventory?.isInStock && (
        <div>
          <div className="warning_text">Not in stock at {localStoreLocation.storeName}</div>
          <div className="">
            <span className="u__text--success u__bold">{nearByStoreLocation?.inventory?.quantity}</span>
            <span> in stock at </span>
            <span className="u__bold">{nearByStoreLocation.storeName}</span>
            <span> ({parseFloat(nearByStoreLocation.distance).toFixed(1)} mi away)</span>
          </div>
          {!isAppliance && (
            <div>
              <CheckNearByStore
                itemId={itemId}
                isShipToHomeEligible={isShipToHomeEligible}
              >
                { CHECK_NEARBY_STORES }
              </CheckNearByStore>
            </div>
          )}
          <>
            {showMinimumThresholdMessage && isBopisThresholdStore && (
              <div className="alert-inline alert-inline--warning inventory--warning">
                <span>There&apos;s a ${bopisMinimumThreshold} minimum purchase for pickup.</span>
              </div>
            )}
            {covidEnabled && (
              <div className="alert-inline alert-inline--warning inventory--warning">
                <span>This item may not be available for pickup until
                  <span className="u__bold"> tomorrow.</span>
                </span>
              </div>
            )}
          </>
          {!!nearByStoreLocation?.inventory?.quantity && nearByStoreLocation?.inventory?.quantity < quantity && (
            <InventoryWarning method={FULFILLMENT_METHODS.BOPIS} location={nearByStoreLocation} />
          )}
        </div>
      )}
    </>
  );
};

InStockNearByStoreDetails.propTypes = {
  itemId: string.isRequired,
  quantity: number,
  isShipToHomeEligible: bool,
  covidEnabled: bool,
  isAppliance: bool,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  pricing: shape({
    value: number
  }).isRequired,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string
};

InStockNearByStoreDetails.defaultProps = {
  quantity: 1,
  isShipToHomeEligible: false,
  isAppliance: false,
  covidEnabled: false,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null
};

export { InStockNearByStoreDetails };
