import React from 'react';
import { DefaultShipping } from '../partials/shipping/DefaultShipping';
import { GCC_COUNTER_TOPS, FULFILLMENT_METHODS } from '../../components/constants';
import { getLiveGoodsStock } from '../../components/helper/utils';
import {
  getShippingThreshold,
  isAppliance,
  isBackorderedWithDate,
  isBackordered,
  isBODFS,
  isDiscontinued,
  hasFreeShipping,
  isOutOfStockOnline,
  isSTH,
  shouldShowShippingThreshold,
  meetsApplianceDeliveryThreshold,
  applianceDeliveryThreshold,
  getDisplayableInventory,
  getExcludedStateSth,
  isBodfsWithQuantityAvailable,
  isBodfsWithQuantityUnknown,
  isAvailableAppliance,
  isSthOutOfStock
} from './pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';

export const getShippingTemplate = ({ product, customer }) => {
  const isGCCCounter = product?.info?.globalCustomConfigurator?.customExperience === GCC_COUNTER_TOPS;
  const getThresholdShipping = getShippingThreshold(product);

  if (!product || isGCCCounter) return () => (<EmptyStoreMessage />);

  /* -----LiveGoods----- */
  if (getLiveGoodsStock(product?.fulfillment) === 0) {
    return () => (<EmptyStoreMessage />);
  }

  if (isDiscontinued(product)) {
    return () => (<DefaultShipping shippingMessage="No longer available" />);
  }

  /* -----Appliance----- */
  if (isAppliance(product)) {

    if (isAvailableAppliance(product)) {
      if (meetsApplianceDeliveryThreshold(product)) {
        return () => (
          <DefaultShipping
            iconType="success"
            shippingMessageStyle="shipping__success"
            shippingMessage="Free"
          />
        );
      }
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={`Free for appliance orders $${applianceDeliveryThreshold()}+`}
        />
      );
    }
    return () => (<DefaultShipping shippingMessage="Unavailable" />);
  }

  /* -----STH Only----- */
  if (isSTH(product) && !isBODFS(product)) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (<DefaultShipping excludedState={excludedState} />);
    }
    if (isBackorderedWithDate(product) || isBackordered(product)) {
      return () => (<DefaultShipping iconType="success" shippingMessage="Standard" />);
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        />
      );
    }

    if (isSthOutOfStock(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" />);
    }

    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
      />
    );
  }

  /* -----BODFS only----- */
  if (isBODFS(product) && !isSTH(product)) {
    // TODO: temporarily bypass same/next-day messaging for covid response
    /*
    if (isSameDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Same-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    if (isNextDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Next-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    */
    if (isBodfsWithQuantityUnknown(product) || !isBodfsWithQuantityAvailable(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" />);
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Scheduled"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
      />
    );
  }

  /* -----STH and BODFS----- */
  if (isBODFS(product) && isSTH(product)) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Scheduled"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
        />
      );
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        />
      );
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
      />
    );
  }

  /* -----not STH and not BODFS means no delivery to customer directly----- */
  if (!isBODFS() && !isSTH()) {
    if (isDiscontinued(product)) { return () => (<EmptyStoreMessage />); }
    if (isOutOfStockOnline(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" />);
    }
    return () => (<DefaultShipping shippingMessage="Unavailable" />);
  }

  return () => (<EmptyStoreMessage />);
};

getShippingTemplate.dataModel = dataModel;
