import React from 'react';
import { string } from 'prop-types';

const OutOfStockOnlineOnly = ({ type }) => (
  <div>
    <span className="u__text--danger fulfillment__oos">
      {type === 'Online' ? 'This item is currently out of stock' : 'Out of stock online'}
    </span>
  </div>
);

OutOfStockOnlineOnly.propTypes = {
  type: string
};
OutOfStockOnlineOnly.defaultProps = {
  type: 'Online'
};

export { OutOfStockOnlineOnly };
