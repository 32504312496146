import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { number, string, func, bool } from 'prop-types';
import { shipping } from '@thd-olt-component-react/delivery-options';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { dataModel } from './dataModel';
import { Header } from './Header/Header';
import { DualPath } from './DualPath/DualPath';
import { NotifyMe } from './NotifyMe/NotifyMe';
import { LiveGoods } from './LiveGoods/LiveGoods';
import { useFulfillment } from '../hooks/useFulfillment';
import { Discontinued } from './Discontinued/Discontinued';
import { FulfillmentPlaceholder } from './Placeholder/FulfillmentPlaceholder';

import {
  getLiveGoodsStock,
  setDeliveryZip,
  getDeliveryZip,
  isNonMajorAppliance, isMobile
} from './helper/utils';

import './fulfillment.scss';

const Fulfillment = (props) => {
  const {
    itemId,
    storeId,
    deliveryZip,
    quantity,
    covidAlert,
    dualPath,
    onChange,
    onDeliveryZipCodeChange,
    bodfsMinimumThreshold,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming
  } = props;

  const {
    channel,
  } = useContext(ExperienceContext);
  const { storeId: defaultStoreId, storeName, storeZip } = useStore();
  const [zipCode, setZipCode] = useState(deliveryZip || getDeliveryZip() || storeZip);
  const [shippingModel, setShippingModel] = useState(null);
  const [shippingServiceModel, setShippingServiceModel] = useState(null);
  const [isDeliveryOptionChanged, changeDeliveryOption] = useState(false);

  const [selectedFulfillment, setFulfillmentSelection] = useState('');
  const [showCheckAvailability, setShowCheckAvailability] = useState(false);

  const updateZipCode = (newZipCode) => {
    if (newZipCode) {
      setDeliveryZip(newZipCode);
      setZipCode(newZipCode);
    }
  };

  const onChangeOfZipCode = (data) => {
    const updatedModel = shipping(data?.shipping);
    updateZipCode(data?.shipping?.zipCode);
    setShippingModel(updatedModel);
  };

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.DELIVERY_ZIP', ({ output }) => {
      if (output?.deliveryZip) {
        setZipCode(output?.deliveryZip);
      }
    });
  }, []);

  useEffect(() => {
    if (deliveryZip && deliveryZip !== zipCode) {
      setZipCode(deliveryZip);
    }
  }, [deliveryZip]);

  useEffect(() => {
    if (shippingModel) {
      changeDeliveryOption(true);
      if (shippingModel?.shipModes?.length > 0) {
        setShippingServiceModel(shippingModel);
      }
      if (onDeliveryZipCodeChange) {
        onDeliveryZipCodeChange(shippingModel);
      }
    }
  }, [onDeliveryZipCodeChange, shippingModel, zipCode]);

  const [{
    type,
    fulfillment,
    info,
    identifiers,
    value,
    availabilityType,
    fulfillmentModels,
    currentFulfillment,
    loading
  }, changeFulfillment] = useFulfillment({
    itemId,
    covidAlert,
    quantity,
    zipCode,
    storeId: storeId || defaultStoreId,
    storeName,
    channel,
    shippingServiceModel,
    changeDeliveryOption,
    isDeliveryOptionChanged,
    onChange,
    bodfsMinimumThreshold,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming,
    selectedFulfillment,
    setFulfillmentSelection,
    setShowCheckAvailability
  });

  const [storeObj = {}, shippingObj = {}] = fulfillmentModels;

  if (loading) {
    return (
      <FulfillmentPlaceholder channel={channel} />
    );
  }

  if (availabilityType?.discontinued) {
    return (
      <Discontinued />
    );
  }
  if (getLiveGoodsStock(fulfillment) === 0) {
    return (
      <LiveGoods
        storeName={storeName}
        seasonStatusEligible={fulfillment?.seasonStatusEligible}
      />
    );
  }

  if (dualPath) {
    return (
      <DualPath />
    );
  }
  if ((storeObj.unavailable && shippingObj.unavailable) || (shippingObj.outOfStock && type === 'Online')
    || (storeObj.outOfStock && type === 'Shared' && shippingObj.outOfStock)) {
    return (
      <NotifyMe
        itemId={itemId}
        store={storeObj}
        shipping={shippingObj}
      />
    );
  }

  return (
    <>
      <Header
        productType={identifiers ? identifiers.productType : ''}
        itemId={itemId}
        zipCode={zipCode}
        value={value}
        onZipCodeChange={onChangeOfZipCode}
        quantity={parseInt(quantity, 10)}
        showCheckAvailability={showCheckAvailability}
        setShowCheckAvailability={setShowCheckAvailability}
      />
      <div className="fulfillment__content-wrapper card-deck">
        {fulfillmentModels.map((fulfillmentModel, i) => {
          const wrapperClasses = classNames({
            card: true,
            u__legal: isMobile(channel),
            'card-container': true,
            'u__text--primary': !fulfillmentModel.enabled,
            'card-selected': fulfillmentModel.method === currentFulfillment?.method && fulfillmentModel.enabled,
            'card-unselected': fulfillmentModel.method !== currentFulfillment?.method && fulfillmentModel.enabled,
            'card-enabled': fulfillmentModel.enabled,
            'card-unclickable': !fulfillmentModel.enabled,
          });
          return (
            // eslint-disable-next-line
            <a
              key={i}
              type="button"
              className={wrapperClasses}
              onClick={() => {
                if ((fulfillmentModel.method === currentFulfillment?.method) || !fulfillmentModel.enabled) return;
                changeFulfillment(fulfillmentModel);
              }}
            >
              {fulfillmentModel.template}
            </a>
          );
        })}
      </div>

      <div className="fulfillment__fullcontent">
        {currentFulfillment?.subTemplate && !currentFulfillment?.unavailable && (
          <>
            {currentFulfillment.subTemplate}
            {isNonMajorAppliance(identifiers.productType, info)
              && (
                <div className="alert-inline alert-inline--warning inventory--warning">
                  <div>
                    <span>This item does not qualify for Major Appliance Delivery and Haul Away or
                      Installation Services.
                    </span>
                  </div>
                </div>
              )}
          </>
        )}
      </div>

    </>
  );
};

Fulfillment.displayName = 'Fulfillment';

Fulfillment.propTypes = {
  /** An Item that has product highlights */
  itemId: string.isRequired,
  storeId: string,
  deliveryZip: string,
  covidAlert: bool,
  quantity: number,
  onChange: func,
  dualPath: bool,
  onDeliveryZipCodeChange: func,
  bodfsMinimumThreshold: number,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  bopisHolidayTiming: number
};

Fulfillment.defaultProps = {
  quantity: 1,
  storeId: null,
  deliveryZip: '',
  covidAlert: false,
  onChange: () => {},
  onDeliveryZipCodeChange: () => {},
  dualPath: false,
  bodfsMinimumThreshold: null,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  bopisHolidayTiming: null
};

// ['identifiers', 'info', 'pricing', 'availabilityType', 'fulfillment']
Fulfillment.dataModel = extend(
  CheckAvailability,
  dataModel
);

export { Fulfillment };
