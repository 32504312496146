import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { shape } from 'prop-types';
import { getShippingTemplate } from '../helpers/shipping-helper';
import { productShape, isGraphQlDataEqual } from '../helpers/pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';
import './fulfillment-pod-shipping.scss';

export const FulfillmentPodShipping = React.memo(({ product }) => {
  const { customer } = useContext(ExperienceContext);
  const ShippingComponent = getShippingTemplate({ product, customer });

  if (!ShippingComponent) { return EmptyShippingMessage; }

  return (<ShippingComponent product={product} />);
}, isGraphQlDataEqual);

FulfillmentPodShipping.propTypes = {
  product: shape({ productShape }).isRequired,
};

FulfillmentPodShipping.dataModel = dataModel;
