import React from 'react';
import {
  shape,
  bool,
  number
} from 'prop-types';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const DefaultBOPIS = ({ localStoreLocation, covidEnabled }) => {
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Store Pickup"
          icon="pick-up-true"
        />
        {localStoreLocation?.inventory?.isInStock && (
          <div className="fulfillment__sub-title">
            <span>{covidEnabled ? 'Available' : 'Pickup'}</span>
            {!covidEnabled && (
              <div className="u__bold">Today</div>
            )}
          </div>
        )}
      </div>
      <TileFooter className="u__bold">
        <span className="u__text--success">FREE</span>
      </TileFooter>
    </>
  );
};

DefaultBOPIS.propTypes = {
  covidEnabled: bool,
  localStoreLocation: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool,
      isLimitedQuantity: bool,
      isBackordered: bool
    })
  }).isRequired
};

DefaultBOPIS.defaultProps = {
  covidEnabled: false
};

export { DefaultBOPIS };
