import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import {
  Tile, TileContent, Typography, TileMedia, TileText
} from '@one-thd/sui-atomic-components';
import { ConfirmedFilled, Delivery, DeliveryExpress, InStorePickup } from '@one-thd/sui-icons';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import '../../styles/availability-filters.scss';

const AvailabilityRefinement = ({
  dimension,
  onChange,
  refinement,
  enableMultiStore,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  onDisplay,
  multiFacet,
  active,
  setMultiStoreIds,
  multiStoreIds,
  isMobile,
}) => {

  const { selected } = refinement;

  const isSelected = (!multiFacet && !!selected) || (multiFacet && active);
  const { storeName, isLocalized } = useStore({ varnish: false });

  const showStoreSelector = refinement.label.toUpperCase() === 'PICK UP TODAY'
    && isSelected
    && !isMobile;

  const handleOnChange = (event) => {
    if (onChange) {
      onChange({
        refinement, dimension, onDisplay
      });
    }
  };

  const multiStoreCallback = ({ stores }) => {
    const idCount = multiStoreIds?.length;
    const checkedMultiStoreIds = stores.filter((store) => store.checked)
      .map((checkedStore) => parseInt(checkedStore.storeId, 10));

    // selected returning null after first store is selcted in dropdown
    // we are adding additional check to see if there are unchecked stores in dropdown menu
    if ((selected || checkedMultiStoreIds.length < 5) && enableMultiStore && onMultiStoreSelectionChange) {
      onMultiStoreSelectionChange({ multiStoreIds: checkedMultiStoreIds, onDisplay });
    }
    setMultiStoreIds(checkedMultiStoreIds);
  };

  const generateRefinementLabel = (isRefinementDisabled) => {
    const displayLabel = refinement?.label?.toUpperCase() === 'PICK UP TODAY'
      ? 'In Stock at Store Today'
      : refinement?.label;

    return (
      <Typography
        variant="body-base"
        color={isRefinementDisabled ? 'inactive' : 'primary'}
        align={isMobile ? 'center' : 'left'}
      >
        {displayLabel}
      </Typography>
    );
  };

  const generateRefinementSubLabel = (isUnavailable) => {
    let subLabel = '';

    if (isUnavailable) {
      return (
        <Typography variant="body-base" color="subtle">
          Order online or purchase in store
        </Typography>
      );
    }

    switch (refinement?.refinementKey) {
    case '1z175a5':
      subLabel = 'Order online or purchase in store';
      break;
    case 'bwo6i':
      subLabel = 'Standard free shipping';
      break;
    case '1z175cq':
      subLabel = 'Scheduled delivery as soon as tomorrow';
      break;
    default:
      return null;
    }

    return (<Typography variant="body-base" color="subtle">{subLabel}</Typography>);
  };
  const generateIcon = (isUnavailable) => {

    if (isUnavailable) {
      return <InStorePickup />;
    }

    switch (refinement?.refinementKey) {
    case '1z175a5':
      return <InStorePickup />;
    case 'bwo6i':
      return <Delivery />;
    case '1z175cq':
      return <DeliveryExpress />;
    default:
      return null;
    }
  };

  const isUnavailable = parseInt(refinement.recordCount, 10) === 0;

  const refinementClassName = classNames('refinement__availability', {
    'refinement__availability--desktop': !isMobile,
    'refinement__availability--mini': isMobile,
    'refinement__availability--selected': isSelected,
    'refinement__availability--disabled': isUnavailable,
    'refinement__availability--default-border': !isUnavailable && !isSelected,
    'sui-tile-dashed-border': isUnavailable,
  });

  const labelClassName = classNames('refinement__availability--label', {
    'refinement__availability--bold': (isUnavailable && !isMobile) || !isMobile,
  });

  const subLabelClassName = classNames('refinement__availability--subLabel');

  return (
    <>
      <Tile
        className={refinementClassName}
        value={refinement?.label}
        aria-label={refinement?.label}
        onChange={handleOnChange}
        orientation="vertical"
        unavailable={isUnavailable}
        disabled={isUnavailable}
      >
        <TileContent
          orientation="vertical"
          alignItems={isMobile ? 'center' : 'start'}
          disableGutters
        >
          {isSelected && (
            <div className="refinement__availability--selected-icon">
              <ConfirmedFilled size="small" />
            </div>
          )}
          <div className="refinement__availability--icon">
            {generateIcon(isUnavailable)}
          </div>
          <div className={labelClassName}>
            <TileText>
              {generateRefinementLabel()}
            </TileText>
          </div>
          {!isMobile && (
            <div className={subLabelClassName}>
              <TileText>
                {generateRefinementSubLabel(isUnavailable)}
              </TileText>
            </div>
          )}
        </TileContent>
      </Tile>

      {showStoreSelector && (
        <div className="refinement__availability--store-selector">
          <StoreSelector
            multiStoreCallback={multiStoreCallback}
            multiStoreSelection={multiStoreSelection}
            multiFacet={multiFacet}
            isFulfillmentRedesignEnabled
            storeName={storeName}
          />
        </div>
      )}

    </>
  );
};

AvailabilityRefinement.propTypes = {
  dimension: PropTypes.shape({}),
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onMultiStoreSelectionChange: PropTypes.func,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    recordCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
  }),
  onDisplay: PropTypes.string,
  onChange: PropTypes.func,
  setMultiStoreIds: PropTypes.func,
  multiStoreIds: PropTypes.shape([]),
  multiFacet: PropTypes.bool,
  active: PropTypes.bool,
  isMobile: PropTypes.bool,
};

AvailabilityRefinement.defaultProps = {
  dimension: null,
  enableMultiStore: false,
  multiStoreSelection: null,
  onMultiStoreSelectionChange: null,
  refinement: null,
  onDisplay: 'false',
  onChange: null,
  setMultiStoreIds: null,
  multiStoreIds: [],
  multiFacet: false,
  active: false,
  isMobile: false,
};

AvailabilityRefinement.displayName = 'AvailabilityRefinement';

AvailabilityRefinement.dataModel = extend(StoreSelector);

export { AvailabilityRefinement };
