import React from 'react';
import { node, string } from 'prop-types';

const TileFooter = ({ children, className }) => {

  return (
    <div className={`u__center card-messaging-bottom ${className}`}>
      {children}
    </div>
  );
};
TileFooter.propTypes = {
  children: node,
  className: string
};
TileFooter.defaultProps = {
  children: null,
  className: ''
};

export { TileFooter };
