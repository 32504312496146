import React from 'react';
import {
  string,
  shape,
  arrayOf,
  bool,
  number
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearByStore';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { CHECK_NEARBY_STORES } from '../../constants';

const StoreOnly = ({
  bopisFulfillment,
  itemId,
  isShipToHomeEligible,
  isAppliance
}) => {
  const { storeName } = useStore();
  const localStoreLocation = (bopisFulfillment?.locations || []).find((location) => location.isAnchor);
  const quantity = localStoreLocation?.inventory?.quantity;
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Buy in Store"
          icon="pick-up-false"
        />
        <div>
          {quantity > 0 && (
            <>
              <span className="u__text--success">
                <span className="u__bold">{quantity}</span>
                <span>&nbsp;in stock</span>
              </span>
              <span> {` at ${localStoreLocation.storeName || storeName}`}</span>
            </>
          )}
          {quantity === 0 && (
            <span>Visit your store to check availability</span>
          )}
        </div>
      </div>
      {localStoreLocation?.isBuyInStoreCheckNearBy && !isAppliance && (
        <TileFooter>
          <CheckNearByStore
            itemId={itemId}
            isShipToHomeEligible={isShipToHomeEligible}
          >
            { CHECK_NEARBY_STORES }
          </CheckNearByStore>
        </TileFooter>
      )}
    </>
  );
};
StoreOnly.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  isAppliance: bool,
  bopisFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  })
};
StoreOnly.defaultProps = {
  isShipToHomeEligible: false,
  isAppliance: false,
  bopisFulfillment: null
};

export { StoreOnly };
