import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { formatDate } from '../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const DefaultBoSS = ({ fulfillment, backordered, backorderedShipDate }) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');
  const formattedStartDate = formatDate(fulfillment?.deliveryDates?.startDate);
  const formattedEndDate = formatDate(fulfillment?.deliveryDates?.endDate);
  return (
    <>
      <div className="u__center">
        <TileHead
          title="Ship to Store"
          icon="pick-up-true"
        />
        <div className="fulfillment__sub-title">
          {backordered && (
            <>
              <div>Backordered</div>
              {formattedStartDate && (
                <div>
                  <span className="u__bold">{formattedStartDate}</span>
                  {formattedEndDate && (
                    <span className="u__bold">{` - ${formattedEndDate}`}</span>
                  )}
                </div>
              )}
            </>
          )}
          {!backordered && formattedStartDate && (
            <>
              <div>Pickup</div>
              <div>
                <span className="u__bold">{formattedStartDate}</span>
                {formattedEndDate && (
                  <span className="u__bold">{` - ${formattedEndDate}`}</span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <TileFooter className="u__bold">
        <span className="u__text--success">FREE</span>
      </TileFooter>
    </>
  );
};

DefaultBoSS.propTypes = {
  backordered: bool,
  backorderedShipDate: string,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired
};

DefaultBoSS.defaultProps = {
  backordered: false,
  backorderedShipDate: null
};

export { DefaultBoSS };
