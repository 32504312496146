import React, { useContext } from 'react';
import {
  bool as boolType,
  any,
  string as stringType,
  func as funcType
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  extend,
  string,
  QueryProvider,
  params,
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { CarouselHeader } from '../core/CarouselHeader';
import '../../styles/thd-recs-containers.scss';
import { DiscoveryZonesPod } from './DiscoveryZonesPod';

// the QueryProvider on line 54 doesnt get access to ProductPod because data?.products is empty at first
// this is what hoist() was originally intended for but the problem with hoist() is that it mutates
// components and runs into issues when they are in multiple places
// eslint-disable-next-line react/prop-types
const Wrapper = ({ children }) => children;
Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string()
  })
}, DiscoveryZonesPod);
const DiscoveryZoneCarousel = (props) => {

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';

  const {
    data,
    title,
    zoneId,
    hideTitle,
    loading,
    scheme,
    showLoading,
    storeId,
    subtitle,
    membershipInformation,
    zipCode,
    hideCarouselArrows,
    analyticsAnchorProductSku,
    brandTitleMaxLine,
    mountedFn,
  } = props;

  const defaultVariables = {
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null
  };

  const { products } = data || {};

  const txtPlaceholderLines = 3;
  const itemsCount = 6;
  let breakpoints = ({
    sm: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 8
    },
    md: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 16
    },
    lg: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 16
    },
    xl: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 16
    }
  });

  if (showLoading && loading) {
    return (
      <LoadingPlaceholder
        txtPlaceholderLines={txtPlaceholderLines}
        hideTitle={hideTitle}
        itemsCount={itemsCount}
      />
    );
  }

  return (
    <>
      {products?.length > 0 && (
        <>
          <CarouselHeader title={title} subtitle={subtitle} />
          <div className="sui-grid sui-gap-4 sui-grid-cols-1">
            <div>
              <QueryProvider
                dataSource={scheme}
                cacheKey={`${scheme}-carousel`}
                defaultVariables={defaultVariables}
                mountedFn={mountedFn}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  disableMargin
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                >
                  {products.map((product, idx) => {
                    const { itemId } = product;
                    const { preferredPriceFlag } = product.pricing || {};

                    return (
                      <Wrapper key={idx}>
                        <DiscoveryZonesPod
                          itemId={itemId}
                          key={itemId}
                          position={idx}
                          storeId={storeId}
                          scheme={scheme}
                          anchorProduct={analyticsAnchorProductSku}
                          parent={`discovery-zones-${title.toLowerCase().replace(/\s/g, '')}`}
                          preferredPriceFlag={preferredPriceFlag}
                          brandTitleMaxLine={brandTitleMaxLine}
                          zoneName={title}
                          zonePosition={zoneId}
                          podResults={products?.length}
                          hideRatings={title === 'New Items to Explore'}
                        />
                      </Wrapper>
                    );
                  }
                  )}
                </Carousel>
              </QueryProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

DiscoveryZoneCarousel.dataModel = extend(Wrapper, DiscoveryZonesPod);
DiscoveryZoneCarousel.displayName = 'DiscoveryZoneCarousel';
DiscoveryZoneCarousel.propTypes = {
  ...DiscoveryZonesPod.propTypes,
  title: stringType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  loading: boolType,
  hideTitle: boolType,
  scheme: stringType,
  showLoading: boolType,
  storeId: stringType.isRequired,
  subtitle: stringType,
  hideCarouselArrows: boolType,
  analyticsAnchorProductSku: stringType,
  mountedFn: funcType,
};

DiscoveryZoneCarousel.defaultProps = {
  hideTitle: false,
  scheme: 'drecs',
  showLoading: true,
  loading: false,
  data: null,
  subtitle: null,
  hideCarouselArrows: false,
  analyticsAnchorProductSku: '',
  mountedFn: null,
};

export { DiscoveryZoneCarousel };
