import React from 'react';
import { LayoutGroup, LayoutManager } from '@thd-olt-component-react/layout-manager';
import {
  shape,
  string,
  bool
} from 'prop-types';
import { TrackOutOfStock } from '@thd-olt-component-react/email-signup';

const NotifyMe = (props) => {
  const {
    itemId,
    store,
    shipping
  } = props;
  const noStockAvailable = !shipping.enabled && !store.enabled && shipping.outOfStock;
  return (
    <div className="fulfillment__unavailable">
      <fieldset className="fulfillment__unavailable--fieldset">
        <legend className="fulfillment__unavailable--title">How To Get it</legend>
        {store.unavailable && shipping.unavailable && (
          <div className="fulfillment__unavailable--content">
            <div>
              {store.subTemplate}
            </div>
            <div className="fulfillment_unavailable--shipping">
              {shipping.subTemplate}
            </div>
          </div>
        )}
        {(shipping.outOfStock || noStockAvailable) && (
          <div className="fulfillment__unavailable--content">
            {store.unavailable && (
              <div>
                {store.subTemplate}
              </div>
            )}
            <div className="fulfillment_unavailable--shipping">
              {shipping.subTemplate}
              {(shipping?.showNotify || noStockAvailable) && (
                <LayoutManager name="track-out-of-stock">
                  <LayoutGroup name="alerts">
                    <TrackOutOfStock itemId={itemId} />
                  </LayoutGroup>
                </LayoutManager>
              )}
            </div>
          </div>
        )}
      </fieldset>
    </div>
  );
};

NotifyMe.propTypes = {
  itemId: string,
  store: shape({
    method: string,
    unavailable: bool
  }),
  shipping: shape({
    method: string,
    unavailable: bool
  }),
};
NotifyMe.defaultProps = {
  itemId: null,
  store: null,
  shipping: null,
};

export { NotifyMe };
