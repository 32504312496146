import { useEffect, useState, useContext, useCallback } from 'react';
import { getBannerQueryParams, getQueryParams } from '../utils/requestUrlHelpers';
import { getFromBannerApi } from '../utils/axios';
import { ExperienceContext } from '~/@thd-nucleus/experience-context';

/**
 *Make api request to sponsored banner api
 * if (requestConfig.callBanner == true)
 * */
const useFetch = (requestConfig) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [apiRequestTimestamp, setApiRequestTimestamp] = useState(null);
  const [apiResponseTimestamp, setApiResponseTimestamp] = useState(null);
  const experienceContext = useContext(ExperienceContext);
  const hosts = experienceContext.hosts;
  const bannerHost = hosts.apionline;

  const fetchData = async () => {
    let response = {};
    try {
      let queryParams;
      if (requestConfig.schema === 'cat_sponsored' || requestConfig.schema === 'browse_sponsored' || requestConfig.schema === 'search_sponsored') {
        queryParams = getQueryParams(requestConfig);
      } else if (requestConfig.schema === 'hp_sponsored' || requestConfig.schema === 'hp_sponsored_auth' || requestConfig.schema === 'localad_sponsored') {
        queryParams = getBannerQueryParams(requestConfig);
      }
      return await getFromBannerApi(bannerHost, { params: queryParams, withCredentials: true });
    } catch (err) {

      if (err.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        setError(err.message);
        setStatus(response.status);
      }
      return err;
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    let dataResolved;
    if (requestConfig.schema === 'browse_sponsored' || requestConfig.schema === 'search_sponsored') {
      if (requestConfig.callBannerApi && experienceContext?.mounted && requestConfig?.browserId && requestConfig?.pageContext?.data) {
        dataResolved = true;
      }
    } else if (requestConfig.callBannerApi && experienceContext?.mounted && requestConfig?.browserId) {
      dataResolved = true;
    }
    if (dataResolved) {
      setApiRequestTimestamp(new Date().getTime());
      fetchData().then((response) => {
        setApiResponseTimestamp(new Date().getTime());
        setData(response.data);
        setStatus(response.status);
      }
      );
    }

    return () => abortController.abort();
  }, [requestConfig?.browserId, experienceContext?.mounted]);
  return {
    data, status, error, apiResponseTimestamp, apiRequestTimestamp
  };
};

export default useFetch;
