import React from 'react';
import PropTypes from 'prop-types';
import { getParsedFulfillment } from '../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';

const ExpressDelivery = (props) => {
  const { covidEnabled, shippingServiceModel } = props;
  let { fulfillment } = props;
  fulfillment = getParsedFulfillment(fulfillment, shippingServiceModel);
  const deliveryCharge = parseFloat(fulfillment?.deliveryCharge);
  return (
    <>
      <div className="u__center">
        <TileHead
          title={covidEnabled ? 'Scheduled Delivery' : 'Express Delivery'}
          icon="express-true"
        />
        <div className="fulfillment__sub-title">
          {covidEnabled && fulfillment.deliveryTimeline && (
            <>
              <div>As soon as</div>
              <div className="delivery__timeline u__bold">
                {fulfillment.deliveryTimeline.toLowerCase()}
              </div>
            </>
          )}
          {!covidEnabled && fulfillment.deliveryTimeline && (
            <>
              <div>Get it</div>
              <div className="delivery__timeline u__bold">
                {fulfillment.deliveryTimeline.toLowerCase()}
              </div>
            </>
          )}
          {!covidEnabled && !fulfillment.deliveryTimeline && (
            <div>Schedule delivery</div>
          )}
        </div>
      </div>
      <TileFooter>
        {!!deliveryCharge && (
          <>
            {deliveryCharge < 79 && <span>Starting at </span>}
            <span className="u__bold">${deliveryCharge.toFixed(2)}</span>
          </>
        )}
        {!deliveryCharge && (
          <span className="u__bold u__text--success">FREE</span>
        )}
      </TileFooter>
    </>
  );
};

ExpressDelivery.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  fulfillment: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-enable react/forbid-prop-types */
  covidEnabled: PropTypes.bool,
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    isBodfsShipMode: PropTypes.bool,
    isDeliveryTimelineTodayOrTomorrow: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool,
    })
  })
};
ExpressDelivery.defaultProps = {
  covidEnabled: false,
  shippingServiceModel: null
};

export { ExpressDelivery };
