import { useState, useEffect, useCallback } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { getFulfillmentModels } from '../components/helper/FulfillmentHelper';
import { isQuantityLimitExceed, getCurrentFulfillment } from '../components/helper/utils';
import { FOREIGN_LIFE_CYCLE_EVENTS } from '../components/constants';

export const useFulfillment = ({
  itemId,
  covidAlert,
  storeId,
  storeName,
  quantity,
  zipCode,
  channel,
  fulfillment: newFulfillment,
  shippingModel: shippingServiceModel,
  changeDeliveryOption,
  isDeliveryOptionChanged,
  onChange,
  bodfsMinimumThreshold,
  bopisHolidayTiming,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  selectedFulfillment,
  setFulfillmentSelection,
  setShowCheckAvailability
}) => {
  // there is some default state to consider for initial rendering
  const [productData, setProduct] = useState(null);
  const productOptions = {
    variables: {
      itemId,
      storeId,
      zipCode
    },
    ssr: false,
    skip: shippingServiceModel
  };
  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptions);

  const {
    fulfillment = {} || newFulfillment,
    availabilityType = {},
    identifiers = {},
    info = {},
    pricing = {}
  } = productData?.product || {};
  const type = availabilityType?.type;
  const { value } = pricing || {};

  const fulfillmentModels = getFulfillmentModels({
    fulfillment,
    shippingServiceModel,
    type,
    covidAlert,
    quantity,
    zipCode,
    storeId,
    itemId,
    storeName,
    channel,
    pricing,
    isDeliveryOptionChanged,
    bodfsMinimumThreshold,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming,
    setShowCheckAvailability
  });

  const currentFulfillment = getCurrentFulfillment({ selectedFulfillment, fulfillmentModels });

  const response = {
    type,
    fulfillment,
    identifiers,
    info,
    value,
    availabilityType,
    fulfillmentModels,
    currentFulfillment,
    loading,
    error
  };

  const changeFulfillment = useCallback((fulfillmentModel) => {
    setFulfillmentSelection(fulfillmentModel.method);
    changeDeliveryOption(false);
  }, []);

  const changeToCorrespondingFulfillment = useCallback(({ output = {} }) => {
    const targetFulfillmentType = output?.nextFulfillment;
    const targetFulfillmentModel = (fulfillmentModels || []).find((model) => (model?.method === targetFulfillmentType));
    const isSwitchApplicable = output.currentFulfillment !== targetFulfillmentType && targetFulfillmentModel?.enabled;

    if (isSwitchApplicable) {
      changeFulfillment(targetFulfillmentModel);
    }
  }, [fulfillmentModels, changeFulfillment]);

  const initializeListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.on(
      FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.attachLaborChange,
      changeToCorrespondingFulfillment
    );
  }, [changeToCorrespondingFulfillment]);

  const clearListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.off(FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.attachLaborChange);
  }, []);

  useEffect(() => {
    if (data) {
      setProduct(data);
    }
  }, [data]);
  // Initialize life cycle events
  useEffect(() => {
    initializeListeners();
    return clearListeners;
  }, [initializeListeners, clearListeners]);

  useEffect(() => {
    // Selection of default fulfillment
    // default === bopis
    // if no bopis from data, and have boss, === boss
    // if no bopis or boss  === ship to home
    // if no boss, bopis, ship to home === express
    const [storeObj = {}, shippingObj = {}, expressObj = {}] = fulfillmentModels;
    if (selectedFulfillment === ''
    || (isDeliveryOptionChanged && currentFulfillment?.method === selectedFulfillment
      && !currentFulfillment?.enabled)) {
      if (storeObj?.enabled && !(info?.hasSubscription && shippingObj?.enabled)) {
        setFulfillmentSelection(storeObj.method);
      } else if (shippingObj?.enabled) {
        setFulfillmentSelection(shippingObj.method);
      } else if (expressObj?.enabled) {
        setFulfillmentSelection(expressObj.method);
      }
    }
  }, [productData, shippingServiceModel]);

  useEffect(() => {
    if (onChange && productData) {
      const [storeObj = {}, shippingObj = {}, expressObj = {}] = fulfillmentModels;
      const method = currentFulfillment?.method;
      const isShowATC = !availabilityType?.discontinued
        && (shippingObj.enabled || storeObj.enabled || expressObj.enabled);
      const disableATC = !shippingObj.enabled && !expressObj.enabled && storeObj.quantityLimitExceed;
      const isOutOfStockOnline = shippingObj.outOfStock ? shippingObj.outOfStock : false;
      const quantityLimitExceed = isQuantityLimitExceed({ method, storeObj, shippingObj, expressObj });
      const availableFulfillments = fulfillmentModels
        .filter((model) => model.enabled === true)
        .map((model) => (model.method));

      onChange({
        method,
        isShowATC,
        disableATC,
        isOutOfStockOnline,
        quantityLimitExceed,
        availableFulfillments
      });
    }
  }, [productData, itemId, quantity, selectedFulfillment]);

  return [response, changeFulfillment];
};
