import React from 'react';
import classNames from 'classnames/bind';
import {
  string, arrayOf, shape, bool, func, oneOfType, number
} from 'prop-types';
import styles from '../../styles/primary-filters-drawer.scss';

const MultiStateDrawerRefinement = ({
  refinement, dimension, onClick, selected
}) => {
  const cx = classNames.bind(styles);
  const handleOnClick = () => {
    onClick({ refinement, dimension });
  };

  /**
   * unselected
   * selected
   */
  const refinementClassNames = cx('multi-select-refinement', {
    'multi-select-refinement--selected': selected,
  });
  return (
    <button
      type="button"
      className={refinementClassNames}
      onClick={handleOnClick}
    >
      {refinement.label}
    </button>
  );
};

MultiStateDrawerRefinement.displayName = 'DrawerRefinement';
MultiStateDrawerRefinement.propTypes = {
  dimension: shape({
    dimensionId: string,
    label: string,
    refinements: arrayOf(
      shape({})
    ),
  }).isRequired,
  refinement: shape({
    label: string,
    recordCount: oneOfType([string, number]),
    refinementKey: string,
    selected: bool,
    url: string
  }).isRequired,
  onClick: func.isRequired,
  selected: bool,
};

MultiStateDrawerRefinement.defaultProps = {
  selected: false,
};

export { MultiStateDrawerRefinement };