import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { arrayOf, string } from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { ACTION, NO_RENTAL, NO_RENTAL_URL, RENTAL_INTENT_POD_CHECK_AVAILABILITY } from '../../util/constants';
import { publish } from '../../analytics';
import styles from '../../styles/rental-intent-no-results.module.scss';

const RentalIntentNoResults = ({ productTypes }) => {

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const onClick = ({
    podPosition,
    podInteractionNumber
  }) => {

    publish(RENTAL_INTENT_POD_CHECK_AVAILABILITY, {
      podType: NO_RENTAL,
      podAction: ACTION,
      podInteractionNumber,
      podPosition
    });
  };

  const noResultsClasses = classNames(styles['rental-intent__no-results'], {
    [styles['rental-intent__no-results--mobile']]: isMobile
  });
  const noResultsCopyClasses = classNames(styles['rental-intent__no-results__copy'], {
    [styles['rental-intent__no-results__copy--mobile']]: isMobile
  });

  return (
    <div className={noResultsClasses}>
      <div className={classNames(styles['rental-intent__no-results__inner'])}>
        <Col nopadding={isMobile} fallback={!isMobile ? 9 : 12} className={noResultsCopyClasses}>
          We don&apos;t currently rent {productTypes.map((productType) => productType)}.
        </Col>
        <Col nopadding={isMobile} fallback={!isMobile ? 3 : 12}>
          <a
            className="bttn-outline bttn-outline--primary"
            href={NO_RENTAL_URL}
            onClick={() => {
              onClick({
                podPosition: 'r1',
                podInteractionNumber: '1',
                url: NO_RENTAL_URL
              });
            }}
          >
            <span className="bttn__content">View other rentals</span>
          </a>
        </Col>
      </div>
    </div>
  );
};

RentalIntentNoResults.displayName = 'RentalIntentNoResults';

RentalIntentNoResults.propTypes = {
  productTypes: arrayOf(string).isRequired
};

export { RentalIntentNoResults };
