/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { bool, instanceOf, number, shape, string } from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { QueryContext, QueryProvider, extend } from '@thd-nucleus/data-sources';
import { useDpdSearchModel } from '../hooks/useDpdSearchModel';
import ProductShelfCarousel from './ProductShelfCarousel';
import classNames from 'classnames/bind';
import styles from './product-shelf-container.scss';
import { Typography, Link } from '@one-thd/sui-atomic-components';

const cx = classNames.bind(styles);

/**
 * @group Components
 * Displays Product-Shelf component
 */

const ProductShelfContainer = (props) => {

  const  { channel, linkType, minDpdCount, showTooFewProductsNotice } = props;
  const [startIndex, setStartIndex] = useState(0);
  const { isClientResolved } = useContext(QueryContext);
  const pageSize = 24;
  const {
    skus,
    shopAllLink,
    categoryLabel,
    storeId,
    storeZip,
    totalProducts = 0,
    loading
  } = useDpdSearchModel({
    ...props,
    pageSize,
    storefilter: 'ALL',
    startIndex
  });

  const isMobile = channel === 'mobile';
  const isSSR = typeof window === 'undefined';
  const showProductShelf = totalProducts > minDpdCount && !isSSR;

  let showArrows = isMobile ? totalProducts > 1 : totalProducts > 5;
  // TODO Need to revisit onSliderChange config, it's functionality is not decided yet 
  const carouselConfig = {
    breakpoints: {
      sm: {
        slidesPerView: 3.2,
        slidesPerGroup: 3
      },
      md: {
        slidesPerView: 4.2,
        slidesPerGroup: 4
      },
      lg: {
        slidesPerView: 4.2,
        slidesPerGroup: 4
      },
      xl: {
        slidesPerView: 5.2,
        slidesPerGroup: 5
      }
    },
    slidesPerView: 2.2,
    slidesPerGroup: 2,
    peek: false
  };
  const skipFn = ({ skip, attributes, queryName }) => {
    if (queryName !== 'product') return skip;
    const isResolved = isClientResolved({ queryName: 'dpdSearchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }

    return skip;
  };

  const resolvedProductSearch = isClientResolved({ queryName: 'dpdSearchModel' });
  const hideProductShelf = !showProductShelf && resolvedProductSearch && !loading;

  return (
      <div>
        <div
          className={cx('product-shelf sui-w-full sui-min-h-[523px] sui-text-left', {'product-shelf__hide sui-hidden': hideProductShelf})}
          data-component="ProductShelf"
        >
          {!showProductShelf &&
            (
              <>
                <div className="product-shelf__placeholder-label sui-pb-[5px]">
                  <LoadingPlaceholder 
                    type="rect" 
                    ready={false} 
                    showLoadingAnimation 
                    color="#E0E0E0" 
                    style={{ height: "40px", width: "100%" }}
                  >
                    Label Placeholder
                  </LoadingPlaceholder> 
                </div>
                <div>
                  <div className="product-shelf__carousel--wrapper sui-p-0 sui-overflow-y-auto sui-flex-[1] no-arrows-displayed">
                    <ProductShelfCarousel carouselConfig={carouselConfig} showPlaceholder />
                  </div>
                </div>
              </>
            )
          }
          {showProductShelf &&
            (
              <>
                <div className="product-shelf__label sui-flex-wrap sui-flex sui-items-center sui-justify-between sui-mb-4">
                  <Typography variant="h2" height="snug" uppercase weight="display">{categoryLabel}</Typography>
                  {shopAllLink !== '' && (
                    <Link
                    href={shopAllLink}
                    >
                      Shop All
                    </Link>
                  )}
                </div>
                <div className="product-shelf__dynamic-aisle sui-flex sui-bg-transparent sui-rounded-[3px] sui-bg-no-repeat sui-bg-cover sui-bg-center sm:sui-pl-[10px] max-[640px]:sui-flex-col">
                  <div className={cx('product-shelf__carousel--wrapper sui-p-0 sui-overflow-y-auto sui-flex-[1] max-[640px]:sui-m-[7px]', {'no-arrows-displayed': !showArrows})}>
                    <QueryProvider
                      dataSource="searchNav"
                      cacheKey="dpdSearchNav"
                      defaultVariables={{ storeId, zipCode: storeZip, skipKPF: true, skipSubscribeAndSave: true }}
                      skip={skipFn}
                    >
                      <ProductShelfCarousel
                        channel={channel}
                        skus={skus}
                        storeId={storeId}
                        storeZip={storeZip}
                        linkType={linkType}
                        carouselConfig={carouselConfig}
                      />
                    </QueryProvider>
                  </div>
                </div>
              </>
            )
          }
        </div>
        {showTooFewProductsNotice && hideProductShelf && 
          (
            <>
              <div className={cx('product-shelf sui-w-full sui-text-left sui-text-danger')}
                data-component="ProductShelf">
                  <p>Not enough valid products ({totalProducts} &lt; {minDpdCount}) to display Product Shelf module</p>
              </div>
            </>
          )
        }
      </div>
  );
};

ProductShelfContainer.displayName = 'ProductShelfContainer';

ProductShelfContainer.dataModel = extend(ProductShelfCarousel);

ProductShelfContainer.propTypes = {
  channel: string,
  host: string,
  // TODO: is this a thing?
  linkType: string,
  minDpdCount: number,
  opts: shape({
    contentfulTitle: string,
    navParam: string,
    itemIds: instanceOf(Array),
    sortBy: string,
    sortOrder: string,
    channel: string,
    //TODO: FLATTEN OBJECT
    Data: shape({
      contentfulTitle: string,
      navParam: string,
      itemIds: instanceOf(Array),
      sortBy: string,
      sortOrder: string,
      channel: string,
    }),
  }),
  showTooFewProductsNotice: bool,
  storeId: string
};

ProductShelfContainer.defaultProps = {
  channel: 'desktop',
  host: 'https://www.homedepot.com',
  linkType: '_self',
  minDpdCount: 4,
  opts: {
    Data: {
      channel: 'desktop'
    }
  },
  showTooFewProductsNotice: false,
  storeId: undefined
};

export default ProductShelfContainer;
