/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QueryProvider, QueryContext, extend } from '@thd-nucleus/data-sources';
import { Carousel } from '@thd-olt-component-react/carousel';
import { Col } from '@thd-olt-component-react/grid';
import './DPD.scss';
import { CustomDesktopProductPod } from '../../../etch2/components/DPD/CustomDesktopProductPod';
import { CustomMobileProductPod } from '../../../etch2/components/DPD/CustomMobileProductPod';
import { dataModel as dpdSearchModel } from '../../../../hooks/dpdDataModel';
import { useDPD } from '../../../../hooks/useDPD';
import Helpers from './DPDHelpers';

function getWidth(defaultWidth = 0) {
  if (typeof document === 'undefined') return defaultWidth;

  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

/**
 * @group Components
 * Displays DPD component
 */

const DynamicProductDisplay = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { isClientResolved } = useContext(QueryContext);
  const [startIndex, setStartIndex] = useState(0);
  const pageSize = 7; // channel === 'mobile' ? 3 : 6;
  const {
    skus = [],
    categoryLabel,
    shopAllLink,
    storeId,
    storeZip,
    totalProducts = 0,
  } = useDPD({
    ...props,
    pageSize,
    startIndex
  });

  const getLabel = useCallback(({ sortByName }) => {
    const displayLabel = Helpers.getDisplayLabel({ sortByName });
    const savingRegex = new RegExp('[A-Za-z]* Savings');
    let inLabel = '';
    if (displayLabel && categoryLabel) {
      inLabel = ' in ';
    }
    const curatedTitle = savingRegex.test(categoryLabel)
      ? categoryLabel
      : (
        <span>
          <span className="u__bold">{displayLabel}</span>{inLabel}{categoryLabel}
        </span>
      );
    return curatedTitle;
  }, [categoryLabel]);

  const {
    disableSwipeNavigation,
    env,
    favorites,
    linkType,
    minDpdCount,
    onFavoriteChange,
    dpdNewTab,
    opts,
    parentNavParam,
    showArrows,
    showFavoritesCount,
    showDpdPod
  } = props;
  const sortBy = 'topsellers';
  const itemWidthPixelsMobile = 300;
  const isMobile = channel === 'mobile';
  const dpdProps = {
    itemClass: isMobile ? 'mobile-fixed-item' : 'fixed-item',
    multiItem: false,
    showArrows: isMobile ? false : showArrows,
    disableSwipeNavigation: isMobile ? true : disableSwipeNavigation,
    fixedItem: true,
    itemWidthMobile: '80%',
    itemWidthPixelsMobile,
    totalItems: totalProducts,
    onNextClick: (itemspershift, nextPosition) => {
      if (nextPosition < totalProducts) {
        if (nextPosition >= (skus.length - 2) && skus.length < totalProducts) {
          setStartIndex(skus.length + 1);
        }
      }
    },
    onScroll: (data) => {
      const { clientWidth, scrollWidth, scrollLeft } = data;
      if (clientWidth && scrollLeft && scrollWidth && skus.length < totalProducts) {
        const availableLengthToScroll = scrollWidth - scrollLeft - clientWidth;
        if (availableLengthToScroll < clientWidth) {
          setStartIndex(skus.length + 1);
        }
      }
    }
  };
  if (totalProducts < minDpdCount) {
    return null;
  }

  const skipFn = ({ skip, attributes, queryName }) => {

    if (queryName !== 'product') return skip;
    const isResolved = isClientResolved({ queryName: 'dpdSearchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }

    return skip;
  };
  const width = isMobile ? getWidth(375) - 30 : null;

  return (
    <QueryProvider
      dataSource="searchNav"
      cacheKey="dpdSearchNav"
      defaultVariables={{ storeId, zipCode: storeZip, skipKPF: true, skipSubscribeAndSave: true }}
      skip={skipFn}
    >
      <Col className="dpd">
        {shopAllLink !== '' && (
          <div className="dpd__label">
            {getLabel({
              sortByName: sortBy,
            })}
            <a className="dpd__shopall--link" href={shopAllLink}>
              Shop All
            </a>
          </div>
        )}
        {skus.length > 0 && (
          <div className="dpd__carousel--wrapper" style={{ width }}>
            <Carousel {...dpdProps}>
              {skus.map((sku, index) => {
                return (
                  <div className="product-pod-v7" key={sku.itemId}>
                    {isMobile
                      ? (
                        <CustomMobileProductPod
                          index={index}
                          itemId={sku.itemId}
                          showFavoritesCount={showFavoritesCount}
                          storeId={storeId}
                        />
                      )
                      : (
                        <CustomDesktopProductPod
                          index={index}
                          itemId={sku.itemId}
                          showFavoritesCount={showFavoritesCount}
                          storeId={storeId}
                        />
                      )}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </Col>
    </QueryProvider>
  );
};

DynamicProductDisplay.displayName = 'DynamicProductDisplay';

DynamicProductDisplay.dataModel = extend(
  dpdSearchModel,
  CustomDesktopProductPod,
  CustomMobileProductPod
);

DynamicProductDisplay.propTypes = {
  channel: PropTypes.oneOf(['desktop', 'mobile']),
  deliveryZip: PropTypes.string,
  disableSwipeNavigation: PropTypes.bool,
  dpdIndex: PropTypes.number,
  dpdNewTab: PropTypes.bool,
  dpdResponse: PropTypes.shape({
    did: PropTypes.string
  }),
  env: PropTypes.oneOf([
    'dev',
    'stage',
    'prod-beta',
    'prod'
  ]),
  favorites: PropTypes.arrayOf(PropTypes.number),
  host: PropTypes.string,
  linkType: PropTypes.string,
  minDpdCount: PropTypes.number,
  onDPDChange: PropTypes.func,
  onFavoriteChange: PropTypes.func,
  opts: PropTypes.shape({
    options: PropTypes.shape({
      sortBy: PropTypes.string
    }),
    type: PropTypes.string,
    isDynamicContent: PropTypes.bool
  }).isRequired,
  parentNavParam: PropTypes.string,
  removeOOSSkus: PropTypes.bool,
  showArrows: PropTypes.bool,
  storeId: PropTypes.string,
  storeZip: PropTypes.string,
  showFavoritesCount: PropTypes.bool,
  showDpdPod: PropTypes.bool
};

DynamicProductDisplay.defaultProps = {
  channel: 'desktop',
  deliveryZip: null,
  disableSwipeNavigation: false,
  dpdNewTab: false,
  dpdResponse: null,
  dpdIndex: 1,
  env: 'prod',
  favorites: [],
  host: 'https://www.homedepot.com',
  linkType: '_self',
  minDpdCount: 4,
  onDPDChange: null,
  onFavoriteChange: null,
  parentNavParam: '',
  removeOOSSkus: false,
  showArrows: true,
  storeId: undefined,
  storeZip: undefined,
  showFavoritesCount: false,
  showDpdPod: false
};

export default DynamicProductDisplay;
