import React, {
  useEffect, useState, useMemo, useContext, Suspense
} from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Location, ArrowForward } from '@one-thd/sui-icons';
import {
  useDataModel,
  params,
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { StoreLayoutModal } from './StoreLayoutModal';
import { ready } from '../helper';

export const AisleBayFindMost = ({ itemId, store, products, searchKeyword }) => {
  const { storeId, storeName } = store || {};
  const storeSkuIds = (products || []).map((product) => product.identifiers?.storeSkuNumber)
    .filter((sku) => !!sku);
  const { isConsumerApp } = useContext(ExperienceContext);
  const [storeModalOpen, setStoreModalOpen] = useState(false);

  const aisleBayResponse = useDataModel('aislebay', {
    skip: !storeSkuIds.length || !storeId || !storeName,
    ssr: false,
    variables: {
      storeId,
      storeSkuIds,
    }
  });

  useEffect(() => {
    ready('aisle-bay-find-most');
  }, []);

  const { aislebay } = aisleBayResponse.data || {};
  const { storeSkus = [] } = aislebay || {};

  const mostCommonAisle = useMemo(() => {
    let tempMostCommonAisle = { count: 0, aisle: '' };
    const counts = {};
    storeSkus.forEach((sku) => {
      const { aisleBayInfo } = sku;
      const { aisle, bay } = aisleBayInfo || {};
      if (!(aisle && bay)) {
        return;
      }
      counts[aisle] = counts[aisle] ? counts[aisle] + 1 : 1;
      tempMostCommonAisle = counts[aisle] > tempMostCommonAisle.count
        ? { ...aisleBayInfo, count: counts[aisle] }
        : tempMostCommonAisle;
    });

    return tempMostCommonAisle;
  }, [storeSkus]);

  if (!storeSkuIds.length || (aislebay && !storeSkus.length)) {
    return null;
  }

  const shouldBeHidden = !storeName || !mostCommonAisle.aisle;
  const commonAisle = mostCommonAisle.aisle;
  const friendlyAisleDescription = storeSkus?.[0]?.aisleBayInfo?.invLocDescFriendly;

  const onStoreLayoutClick = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('NATIVE_APP.STORE_MAP_PLP', {
      storeId,
      storeName,
      commonAisle,
      searchKeyword,
      friendlyAisleDescription
    });
    if (!isConsumerApp) setStoreModalOpen(true);
  };

  return (
    <>
      <button
        type="button"
        className={`sui-flex sui-flex-row sui-text-left sui-w-full sui-items-center 
        ${shouldBeHidden ? 'invisible' : 'visible'}`}
        onClick={onStoreLayoutClick}
        data-component="AisleBayFindMost"
      >
        <Location size="regular" color="medium" />
        <div className="sui-flex sui-flex-col sui-text-sm sui-text-info sui-ml-2">
          <span>Find most of these in&nbsp;
            <span className="sui-font-bold">
              Aisle {mostCommonAisle.aisle} at {storeName} Store
            </span>
          </span>
          <span className="sui-text-xs sui-font-light sui-text-left">Tap to see on store map</span>
        </div>
        <div className="sui-ml-auto sui-text-brand">
          <ArrowForward size="regular" color="current" />
        </div>
      </button>
      <StoreLayoutModal
        open={storeModalOpen}
        onClose={() => setStoreModalOpen(false)}
        storeId={storeId}
        storeName={storeName}
        aisle={mostCommonAisle.aisle}
        bay={mostCommonAisle.bay}
      />
    </>
  );

};

AisleBayFindMost.displayName = 'AisleBayFindMost';

AisleBayFindMost.propTypes = {
  itemId: string,
  searchKeyword: string,
  products: arrayOf(shape({})).isRequired,
  store: shape({
    storeId: string,
    storeName: string,
  }).isRequired
};

AisleBayFindMost.dataModel = {
  aislebay: params({
    storeId: stringType().isRequired(),
    storeSkuIds: arrayType(stringType().isRequired())
  }).shape({
    storeSkus: arrayType(shapeType({
      storeNumber: stringType(),
      storeSkuId: stringType(),
      aisleBayInfo: shapeType({
        aisle: stringType(),
        bay: stringType(),
        invLocDesc: stringType()
      })
    }))
  }),
};

AisleBayFindMost.defaultProps = {
  itemId: null,
  searchKeyword: null
};
